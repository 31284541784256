import { Component, EventEmitter, Input, Output } from '@angular/core';

import { combineLatest, map, of } from 'rxjs';
import { filter } from 'rxjs/operators';

import { AddBankComponent } from '../add-bank/add-bank.component';
import {
	AchBankAccountsService,
	IAchBankAccount
} from 'src/app/core/services/ach-bank-accounts/ach-bank-accounts.service';
import { RoutingPathsEnum, RoutingService } from 'src/app/core/services/routing/routing.service';
import { LoanApplicationService } from 'src/app/core/services/loan-application/loan-application.service';

@Component({
	selector: 'op-add-bank-ext',
	templateUrl: './add-bank-ext.component.html',
	styleUrls: ['./add-bank-ext.component.scss']
})
export class AddBankExtComponent extends AddBankComponent {
	@Input()
	readonly = false;

	@Output()
	addAnotherBankAccount = new EventEmitter<boolean>();

	ngOnInit(): void {
		const bankSub = combineLatest([
			this.bankAccountService.bankAccounts$,
			this.loanAppService.isGCPOrReturnApplicant() ? this.bankAccountService.returningBankAccounts$ : of([])
		])
			.pipe(
				map(([rsp, returningRsp]) => rsp?.concat(returningRsp) || []),
				filter((r) => Boolean(r))
			)
			.subscribe({
				next: (rsp) => {
					this.showAccountList = Boolean(rsp.length);
					if (!this.areAllAccountsVerified(rsp)) {
						this.selectBank = true;
					}
				}
			});
		this.subscription.add(bankSub);
	}

	constructor(
		protected bankAccountService: AchBankAccountsService,
		private routingService: RoutingService,
		private loanAppService: LoanApplicationService
	) {
		super(bankAccountService);
	}

	onBankSelected($event: IAchBankAccount): void {
		this.routingService.route(RoutingPathsEnum.documentSubmit);
	}

	onAddAnotherBankAccount($event: boolean): void {
		this.addAnotherBankAccount.emit($event);
	}
}
