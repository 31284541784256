import { Injectable } from '@angular/core';

import { StorageService } from './storage.service';

/**
 * Session storage service
 */
@Injectable()
export class SessionStorageService extends StorageService {
	constructor() {
		super(sessionStorage);
	}
}

export const STORAGE_KEYS = {
	ADDRESS_COMP: 'addressComp',
	PARTNER_REFERRAL: 'partnerReferral'
};
