<div class="bank-verification" *transloco="let t">
	<op-masthead [title]="t('BANK_VERIFICATION.fundingMethod')">
		<p>{{ t('BANK_VERIFICATION.description') }}</p>
	</op-masthead>
	<op-content-container class="content">
		<op-funding-method-accordion
			*ngIf="!hidePlaid"
			[id]="'plaid-connect'"
			[paymentMethodTitle]="t('BANK_VERIFICATION.CONNECTED_BANK_ACCOUNT.title')"
			[paymentMethodInfo]="t('BANK_VERIFICATION.CONNECTED_BANK_ACCOUNT.info')"
			[paymentMethodDuration]="t('BANK_VERIFICATION.CONNECTED_BANK_ACCOUNT.duration')"
			paymentMethodIcon="op-bank"
			[hideHeader]="!plaid?.accountList?.length"
			[expanded]="selectedFundingMethod === fundingMethodEnum.plaid || !plaid?.accountList?.length"
			[selected]="selectedFundingMethod === fundingMethodEnum.plaid && isPlaidValid"
			(opened)="setSelectedPaymentMethod(fundingMethodEnum.plaid)"
		>
			<op-plaid-bank-transaction
				#plaidLink
				(click)="selectedFundingMethod = fundingMethodEnum.plaid"
				(next)="onNext($event)"
				(skip)="skipPlaid($event)"
				[sectionType]="'bank'"
				[bankOnly]="true"
				[showSkip]="false"
				(accountSelected)="accountSelected($event)"
				accountListDisplayMode="select"
			></op-plaid-bank-transaction>
		</op-funding-method-accordion>

		<op-funding-method-accordion
			[id]="'add-bank-manually'"
			[paymentMethodTitle]="t('BANK_VERIFICATION.NEW_BANK_ACCOUNT.title')"
			[paymentMethodInfo]="t('BANK_VERIFICATION.NEW_BANK_ACCOUNT.info')"
			[paymentMethodDuration]="t('BANK_VERIFICATION.NEW_BANK_ACCOUNT.duration')"
			paymentMethodIcon="op-bank"
			[expanded]="selectedFundingMethod === fundingMethodEnum.newAccount"
			[selected]="selectedFundingMethod === fundingMethodEnum.newAccount && isManualBankValid"
			(opened)="setSelectedPaymentMethod(fundingMethodEnum.newAccount)"
		>
			<op-manually-add-bank
				id="manuallyAddBank"
				content
				[showContinue]="false"
				[showTermsOnNextLine]="false"
				[showAccountNameBannerOnTop]="true"
				(notSaved)="notSaved($event)"
				(saved)="bankDetailsSaved($event)"
			>
			</op-manually-add-bank>
		</op-funding-method-accordion>

		<op-funding-method-accordion
			[id]="'check'"
			[paymentMethodTitle]="t('BANK_VERIFICATION.CHECK.title')"
			[paymentMethodInfo]="t('BANK_VERIFICATION.CHECK.info')"
			[paymentMethodDuration]="t('BANK_VERIFICATION.CHECK.duration')"
			paymentMethodIcon="op-payByCheck"
			[canExpand]="false"
			[expanded]="selectedFundingMethod === fundingMethodEnum.check"
			[selected]="selectedFundingMethod === fundingMethodEnum.check"
			(opened)="setSelectedPaymentMethod(fundingMethodEnum.check)"
		>
		</op-funding-method-accordion>

		<button
			mat-flat-button
			mat-primary
			class="op-full-width"
			[disabled]="!isFormValid"
			color="primary"
			id="continue"
			(click)="continue()"
		>
			{{ t('GLOBAL.continue') }}
		</button>
		<op-website-terms class="op-mt-10"></op-website-terms>
	</op-content-container>
</div>
