import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FileUploadService, FileUploadType, IFileUpload } from 'src/app/core/services/file-upload/file-upload.service';
import { FileStatusEnum, IMetadata } from 'src/app/core/services/mobile-api';
import { opRequired } from 'src/app/shared/decorators/required.decorator';

import AutoVerificationUtils, { frontBackList } from '../auto-verification-utils-original';

export enum DocumentStatusEventEnum {
	addMore = 'addMore',
	uploadMore = 'uploadMore'
}

export interface IDocumentStatusEvent {
	event: DocumentStatusEventEnum;
	documentClassification?: IMetadata;
}

interface IDocumentStatus {
	key: string;
	count: number;
	text: string;
	attention: boolean;
}

interface IClassificationCount {
	[key: string]: {
		count: number;
		attention: boolean;
	};
}

@Component({
	selector: 'op-document-status-original',
	templateUrl: './document-status-original.component.html',
	styleUrls: ['./document-status-original.component.scss']
})
export class DocumentStatusOriginalComponent implements OnInit, OnDestroy {
	@Input()
	@opRequired()
	id: string;

	@Input()
	categoryType: FileUploadType;

	private _files: IFileUpload[];
	@Input()
	get categoryFiles(): IFileUpload[] {
		return this._files;
	}
	set categoryFiles(value: IFileUpload[]) {
		this._files = value;
		this.categoryFilesStatus = this.updateCategoryFilesStatus(value);
	}

	private _documentClassificationList: IMetadata[];
	get documentClassificationList(): IMetadata[] {
		return this._documentClassificationList;
	}

	@Input()
	set documentClassificationList(value: IMetadata[]) {
		this._documentClassificationList = value;
		this.updateCategoryFilesStatusText(value);
	}

	@Output()
	done = new EventEmitter<IDocumentStatusEvent>();

	categoryFilesStatus: IDocumentStatus[];

	constructor(private fileUploadService: FileUploadService) {}

	ngOnInit(): void {}

	ngOnDestroy(): void {}

	private countClassificationFiles(files: IFileUpload[]): IClassificationCount {
		return files.reduce((acc, item: IFileUpload) => {
			const isReview = item.ocrInfo?.documentStatus === FileStatusEnum.review;
			acc[item.classification] = {
				count: (acc[item.classification]?.count || 0) + 1,
				attention: acc[item.classification]?.attention || isReview
			};
			return acc;
		}, {});
	}

	private structureClassificationCount(classificationCount: IClassificationCount): IDocumentStatus[] {
		return Object.keys(classificationCount).reduce((acc, item) => {
			acc.push({
				key: item,
				count: classificationCount[item].count,
				text: this.documentClassificationList?.find((doc) => doc.key == item).text,
				attention: classificationCount[item].attention
			});
			return acc;
		}, []);
	}

	/**
	 * front back items will only ever show 2 items
	 *
	 * @private
	 * @param {IDocumentStatus[]} status
	 * @return {*}  {IDocumentStatus[]}
	 * @memberof DocumentStatusComponent
	 */
	private fixFrontBackItems(status: IDocumentStatus[]): IDocumentStatus[] {
		if (this.categoryType === FileUploadType.identification) {
			return status.map((item) => {
				if (frontBackList.includes(String(item.key))) {
					item.count = 2;
				}
				return item;
			});
		}
		return status;
	}

	private fixIncomeBankStatementAttention(files: IFileUpload[], status: IDocumentStatus[]): IDocumentStatus[] {
		if (this.categoryType === FileUploadType.income) {
			const incomeBankStatementFiles = files.filter(
				(file) => file.type === FileUploadType.income && file?.classification === 'BANK_STATEMENT'
			);
			const isIncomeBankStatementCriteriaMet =
				AutoVerificationUtils.isIncomeBankStatementCriteriaMet(incomeBankStatementFiles);
			return status.map((item) => {
				if (item.key === 'BANK_STATEMENT') {
					item.attention = !isIncomeBankStatementCriteriaMet || item.attention;
				}
				return item;
			});
		}
		return status;
	}

	private updateCategoryFilesStatus(files: IFileUpload[]): IDocumentStatus[] {
		const classificationCount = this.countClassificationFiles(files);
		let status = this.structureClassificationCount(classificationCount);
		status = this.fixIncomeBankStatementAttention(files, status);
		return this.fixFrontBackItems(status);
	}

	private updateCategoryFilesStatusText(classificationList: IMetadata[]): void {
		if (this.categoryFilesStatus) {
			this.categoryFilesStatus = this.categoryFilesStatus?.map((item) => {
				return {
					...item,
					text: classificationList?.find((doc) => doc.key == item.key)?.text
				};
			});
		}
	}

	onUploadMore(item: IDocumentStatus): void {
		const { count, ...documentClassification } = item;
		this.done.emit({ event: DocumentStatusEventEnum.uploadMore, documentClassification });
	}

	onAddMore(): void {
		this.done.emit({ event: DocumentStatusEventEnum.addMore });
	}
}
