import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { isEmpty } from 'lodash';

import { combineLatest, mergeMap, Observable, of, Subscription } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { IAchBankAccount } from 'src/app/core/services/ach-bank-accounts/ach-bank-accounts.service';

import { DialogService } from 'src/app/core/services/dialog/dialog.service';
import { DisbursementUtils } from 'src/app/core/services/loan-application/disbursement/disbursement-utils';
import { ILoanTerms } from 'src/app/core/services/loan-application/loan-application.model';
import { LoanApplicationService } from 'src/app/core/services/loan-application/loan-application.service';
import { paymentFrequencyEnum } from 'src/app/core/services/loan-application/product-offer/product/product.model';
import { MetadataEnum } from 'src/app/core/services/metadata/metadata.model';
import { MetadataService } from 'src/app/core/services/metadata/metadata.service';
import { IMetadata, ConfigApiService, IResult, IRachBankAccount } from 'src/app/core/services/mobile-api';
import { IAchBankAccountResult } from 'src/app/core/services/mobile-api/mobile-api.model';
import { MobileApiService } from 'src/app/core/services/mobile-api/mobile-api.service';
import { RoutingPathsEnum, RoutingService } from 'src/app/core/services/routing/routing.service';
import { TagDataService } from 'src/app/core/services/tag-data/tag-data.service';
import {
	IMessageDialogData,
	MessageDialogComponent
} from 'src/app/shared/components/dialogs/message-dialog/message-dialog.component';

import { ProductSubStatusesEnum } from 'src/app/core/services/loan-application/product-offer/product/product.model';

@Component({
	selector: 'op-esign-auto-pay',
	templateUrl: './esign-auto-pay.component.html',
	styleUrls: ['./esign-auto-pay.component.scss']
})
export class EsignAutoPayComponent implements OnInit, OnDestroy {
	PushToDebitCheckAutoPay: any;
	loaded: boolean;

	constructor(
		private mobileService: MobileApiService,
		private metadataService: MetadataService,
		private loanAppService: LoanApplicationService,
		private routingService: RoutingService,
		private dialogService: DialogService,
		private translocoService: TranslocoService,
		private tagDataService: TagDataService,
		private configApiService: ConfigApiService
	) {}

	esignUser: any;
	isMetaRchEnabled = false;

	weekday: IMetadata[];
	isMonthly: boolean;
	isSemiMonthly: boolean;
	isBiWeekly: boolean;
	language: string;
	bankAccountList: IAchBankAccountResult[];
	rachEnrollmentEligible: boolean = false;

	private subscription = new Subscription();

	ngOnInit(): void {
		this.isMetaRchEnabled = this.loanAppService.isRachWithoutAchOptInEligible();
		const weekday$ = this.metadataService.select(MetadataEnum.Weekday);
		const loanApp$ = this.loanAppService.loanApplication$.pipe(filter(Boolean));
		const bankData$ = this.mobileService.getAchBankAccount(this.loanAppService.loanApplicationId);

		this.configApiService.configPushToDebitCheckAutoPay().subscribe({
			next: (rsp) => {
				this.PushToDebitCheckAutoPay = rsp.value;
			}
		});
		this.translocoService.langChanges$.subscribe({
			next: (lang) => {
				this.language = lang;
			}
		});

		const sub = combineLatest([weekday$, loanApp$])
			.pipe(
				map(([metaWeekday, loanApp]) => {
					this.weekday = metaWeekday;
					this.isMonthly = loanApp.loanTerms.paymentFrequency === paymentFrequencyEnum.monthly;
					this.isSemiMonthly = loanApp.loanTerms.paymentFrequency === paymentFrequencyEnum.semiMonthly;
					this.isBiWeekly = loanApp.loanTerms.paymentFrequency === paymentFrequencyEnum.biWeekly;
					return loanApp;
				}),
				mergeMap((loanApp) => {
					return combineLatest([
						this.mobileService.getAchBankAccounts(loanApp.id),
						this.loanAppService.isGCPOrReturnApplicant()
							? this.mobileService.getAchBankAccountsForApplicant(loanApp.id)
							: of([]),
						bankData$
					]);
				}),
				map(([newAccounts, returningAccounts, achAccount]) => {
					this.setEsignUser(achAccount);
					const achAccounts = newAccounts?.concat(returningAccounts) || [];
					return achAccounts?.filter((item) => !achAccount?.id || item.id === achAccount.id);
				}),
				mergeMap((accounts) => {
					if (accounts?.length) {
						this.rachEnrollmentEligible = false;
						return of(accounts);
					} else {
						return this.mobileService.getRachEnrollmentEligibility(this.loanAppService.loanApplicationId).pipe(
							mergeMap((config) => {
								if (config.eligible === true) {
									this.rachEnrollmentEligible = true;
									return this.fetchRachAccounts(this.loanAppService.loanApplicationId);
								} else {
									return of([]);
								}
							})
						);
					}
				})
			)
			.subscribe({
				next: (accounts) => {
					if (isEmpty(accounts) && !this.rachEnrollmentEligible) {
						this.navigate();
					} else {
						this.loaded = true;
						this.bankAccountList = accounts;
					}
				}
			});
		this.subscription.add(sub);

		this.tagDataService.view(
			{
				loan_application_id: this.loanAppService.loanApplicationId,
				application_type: 'CONSUMER_INSTALLMENT_LOAN'
			},
			{
				tealium_event: this.isMetaRchEnabled ? 'meta_rch_enabled_autopay_onload' : 'meta_rch_disabled_autopay_onload'
			}
		);
	}

	fetchRachAccounts(loanAppId: number): Observable<IAchBankAccountResult[]> {
		return combineLatest([
			this.mobileService.getRachBankAccounts(loanAppId),
			this.mobileService.getRachBankAccount(loanAppId)
		]).pipe(
			map(([rachBankAccounts, rachBankAccount]) => {
				const bankAccounts: IAchBankAccountResult[] = [];
				rachBankAccounts.forEach((item) => {
					const each = item as IAchBankAccount;
					if (item.id === rachBankAccount?.id) {
						each.selected = true;
						this.mobileService.setUserAccountId(item.id);
					}
					bankAccounts.push(each);
				});
				return bankAccounts;
			})
		);
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	getDayOfWeekText(loanTerms: ILoanTerms): string {
		const dayMap = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'];
		let paymentDay = loanTerms?.paymentDay || '';
		const dayIndex = dayMap.findIndex((item) => item === paymentDay);
		return (dayIndex != -1 && this.weekday[dayIndex].text) || 'ERROR';
	}

	setEsignUser(bankData: IAchBankAccountResult): void {
		const loanApp = this.loanAppService.getLoanApp();
		const accountNumber = (bankData.accountNumber || '').replace(/\*/gi, '');

		const dayOfWeek = this.getDayOfWeekText(loanApp.loanTerms);

		this.esignUser = {
			loanApplicationId: loanApp.id,
			loanAmount: loanApp.loanTerms.loanAmount,
			paymentDay: dayOfWeek,
			paymentDayMonthlyValue: loanApp.loanTerms.paymentDate1,
			paymentDaySemiMonthlyValue1: loanApp.loanTerms.paymentDate1,
			paymentDaySemiMonthlyValue2: loanApp.loanTerms.paymentDate2,
			paymentAmount: loanApp.loanTerms.paymentAmount,
			accountNumber: accountNumber,
			existingBalance: loanApp.loanTerms.payoffAmount
		};
	}

	onSubmit(acceptAch: boolean): void {
		const accountId = this.mobileService.getUserAccountId();

		let rachEnrollment$: Observable<IResult | IRachBankAccount> = this.mobileService.updateRachBankAccount(
			accountId,
			acceptAch,
			this.loanAppService.loanApplicationId
		);

		if (acceptAch) {
			rachEnrollment$ =
				this.rachEnrollmentEligible && acceptAch
					? this.mobileService.updateRachAccount(this.loanAppService.loanApplicationId, accountId)
					: this.mobileService.updateRachBankAccount(accountId, acceptAch, this.loanAppService.loanApplicationId);
		}
		rachEnrollment$.subscribe({
			next: () => {
				this.navigate();
			}
		});
	}

	navigateToAddBank(): boolean {
		this.routingService.route(RoutingPathsEnum.esignAutoPayAddBank);
		return false;
	}

	confirmSkipAutoPay(data: IMessageDialogData): Observable<boolean> {
		const dialogRef = this.dialogService.open(MessageDialogComponent, { data });
		return dialogRef.afterClosed().pipe(take(1));
	}

	confirmSkipAutoPayDialog(): void {
		const data: IMessageDialogData = {
			icon: 'op-info',
			title: this.translocoService.translate('ESIGN_AUTO_PAY.skipAutoPayDialogHeading'),
			message: this.translocoService.translate('ESIGN_AUTO_PAY.skipAutoPayDialogContent'),
			confirmText: this.translocoService.translate('ESIGN_AUTO_PAY.setupAutoPay'),
			cancelText: this.translocoService.translate('ESIGN_AUTO_PAY.skip')
		};
		this.confirmSkipAutoPay(data)
			.pipe(
				switchMap((rsp: boolean) => {
					if (rsp !== undefined) {
						return rsp ? of(this.navigateToAddBank()) : of(this.navigate());
					} else {
						return of(false);
					}
				})
			)
			.subscribe();
	}

	navigate(): void {
		const disbursement = DisbursementUtils.fromLoanApp(this.loanAppService.getLoanApp());
		if (
			disbursement.onlineNotificationEnabled &&
			disbursement.isChannelAnyStore() &&
			!this.routingService.isSplStorelessEligibleWithStatus(ProductSubStatusesEnum.complete)
		) {
			this.routingService.route(RoutingPathsEnum.termsConfirmed);
		} else {
			this.routingService.route(RoutingPathsEnum.esignSignDocument);
		}
	}
}
