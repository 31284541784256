<div class="document-upload" *transloco="let t">
	<div>
		<ng-content></ng-content>

		<div *ngIf="showSingleFile && singleFile">
			<div
				class="single-file-container"
				[ngClass]="{ attention: singleFile?.ocrInfo?.documentStatus === fileStatusEnum.review, selfie: isSelfie }"
				[class.progress]="uploadProgress && uploadProgress !== 100"
				opDragDrop
				id="{{ id + '_drop_zone_pic' }}"
				(fileDropped)="onFileDropped($event)"
				(click)="fileUpload.click()"
				[opTagClick]="id + documentClassification.key + '_upload'"
			>
				<img
					*ngIf="
						singleFile &&
						(!uploadProgress || uploadProgress === 100) &&
						singleFile?.thumbnail &&
						singleFile?.ocrInfo?.documentStatus !== fileStatusEnum.review
					"
					class="thumbnail-img"
					[ngClass]="{ selfie: isSelfie }"
					id="{{ id + '_singleFile' + '_image' }}"
					alt=""
					[src]="singleFile?.thumbnail"
				/>
				<img
					*ngIf="
						singleFile &&
						(!uploadProgress || uploadProgress === 100) &&
						!singleFile?.thumbnail &&
						singleFile?.ocrInfo?.documentStatus !== fileStatusEnum.review
					"
					id="{{ id + '_singleFile' + '_image' }}"
					class="default-img"
					alt=""
					src="/assets/images/icons/op-document.svg"
				/>
				<ng-content
					*ngIf="singleFile?.ocrInfo?.documentStatus === fileStatusEnum.review"
					select="[singleUploadDocument]"
				></ng-content>

				<div class="op-mt-20" *ngIf="uploadProgress && uploadProgress !== 100">
					<mat-progress-bar class="file-progress" mode="determinate" [value]="uploadProgress"></mat-progress-bar>
					<div class="op-mt-20">{{ t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.uploading') }}</div>
				</div>
			</div>
			<div
				class="file-name op-label-font"
				[ngClass]="{ alert: singleFile?.ocrInfo?.documentStatus === fileStatusEnum.review }"
			>
				{{ singleFile?.name }}
			</div>
		</div>

		<input
			type="file"
			class="file-input"
			id="{{ id + '_file' }}"
			[accept]="requiredFileType"
			(change)="onFileSelected($event)"
			#fileUpload
		/>

		<div
			*ngIf="!showSingleFile || (showSingleFile && !singleFile)"
			class="dnd-container"
			opDragDrop
			id="{{ id + '_drop_zone' }}"
			(fileDropped)="onFileDropped($event)"
			(click)="fileUpload.click()"
			[opTagClick]="id + documentClassification.key + '_upload'"
		>
			<div *ngIf="uploadProgress && uploadProgress !== 100">
				<mat-progress-bar class="file-progress" mode="determinate" [value]="uploadProgress"></mat-progress-bar>
				<div class="op-mt-20">{{ t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.uploading') }}</div>
			</div>
			<ng-content *ngIf="!uploadProgress || uploadProgress === 100" select="[uploadDocument]"></ng-content>
		</div>
		<div *ngIf="showSingleFile && singleFile && isSelfie" class="op-mt-20 op-center">
			<button
				mat-flat-button
				color="op-link-green"
				id="{{ id + '_reupload' }}"
				name="removeAllBtn"
				type="button"
				(click)="fileUpload.click()"
				[opTagClick]="id + documentClassification.key + '_reupload'"
			>
				<ng-content class="op-body-1-font" select="[reUploadDocument]"></ng-content>
			</button>
			<hr class="op-mt-20" />
		</div>
	</div>

	<div *ngIf="!showSingleFile">
		<op-document-v-thumbnails-original
			id="{{ id + '_fileThumbnails' }}"
			[files]="categoryFiles"
			[categoryType]="categoryType"
		>
			<div class="op-body-1-bold-font op-mtb-20">
				{{ t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.uploadedDocuments') }} ({{ categoryFiles?.length }})
			</div>
			<ng-content select="[criteriaMet]"></ng-content>
		</op-document-v-thumbnails-original>
	</div>
</div>
