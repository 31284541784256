import { Component, OnDestroy, OnInit } from '@angular/core';
import { isEmpty } from 'lodash';
import { combineLatest, filter, map, Observable, share, Subscription, tap } from 'rxjs';
import {
	FileUploadService,
	FileUploadStatusEnum,
	FileUploadType,
	IFileUpload
} from 'src/app/core/services/file-upload/file-upload.service';
import { LoanApplicationService } from 'src/app/core/services/loan-application/loan-application.service';
import { MetadataEnum } from 'src/app/core/services/metadata/metadata.model';
import { MetadataService } from 'src/app/core/services/metadata/metadata.service';
import { FileStatusEnum, IMetadata } from 'src/app/core/services/mobile-api';
import { RoutingService } from 'src/app/core/services/routing/routing.service';
import { SessionStorageService } from 'src/app/core/services/storage/session-storage.service';

import { ocrBounceMap } from '../auto-verification.component';
import { AutoVerificationService, ICategoryTypeBadge, IDocVerification } from '../auto-verification.service';

export enum ProofStatusEnum {
	classificationSelection = 'PROOF_CLASSIFICATION_SELECTION',
	upload = 'PROOF_UPLOAD_DOCUMENT',
	status = 'PROOF_STATUS'
}

export interface IProofStateStorage {
	proofState: ProofStatusEnum;
	selectedDocumentClassification: IMetadata;
	statusHistory: Array<ProofStatusEnum>;
	[x: string]: any;
}

@Component({
	selector: 'op-base-category',
	templateUrl: './base-category.component.html',
	styleUrls: ['./base-category.component.scss']
})
export class BaseCategoryComponent implements OnInit, OnDestroy {
	protected choosing = false;
	public stateStorage: IProofStateStorage = {
		proofState: null,
		selectedDocumentClassification: null,
		statusHistory: new Array<ProofStatusEnum>()
	};
	protected categoryType: FileUploadType;
	protected storageKey: string;
	protected metaClassification: MetadataEnum;
	protected files$: Observable<IFileUpload[]>;
	protected documentClassificationList$: Observable<IMetadata[]>;
	protected subscription: Subscription = new Subscription();

	protected categoryFiles: IFileUpload[];
	protected selectedClassificationFiles: IFileUpload[];
	protected reviewFiles: IFileUpload[];
	protected finishedFiles: IFileUpload[];
	public attention = false;

	public bounceReasons: string[] = [];

	public proofStatusEnum: typeof ProofStatusEnum = ProofStatusEnum;

	public autoVerificationMap: ICategoryTypeBadge;

	constructor(
		protected fileUploadService: FileUploadService,
		protected sessionStorageService: SessionStorageService,
		protected routingService: RoutingService,
		protected metadataService: MetadataService,
		protected loanAppService: LoanApplicationService,
		protected autoVerificationService: AutoVerificationService
	) {}

	ngOnInit(): void {
		this.stateStorage = this.sessionStorageService.get(this.storageKey) || this.stateStorage;

		const autoVerification$ = this.autoVerificationService.autoVerification$;
		this.files$ = autoVerification$.pipe(
			map((rsp: IDocVerification) => rsp[this.categoryType]),
			tap((rsp: ICategoryTypeBadge) => (this.autoVerificationMap = Object.assign({}, rsp))),
			map((rsp) =>
				Object.values(rsp.classification)
					.flatMap((item) => item.files)
					.reverse()
			),
			tap((files: IFileUpload[]) => {
				this.categoryFiles = files;
				this.updateClassFiles(this.categoryFiles, this.selectedDocumentClassification);
			})
		);

		const loanApp$ = this.loanAppService.loanApplication$.pipe(filter(Boolean));
		const currentFileTypeUpload$ = this.fileUploadService.currentFileTypeUploads$;
		const observe$ = combineLatest([loanApp$, currentFileTypeUpload$]);
		const loanAppSub = observe$.subscribe({
			// subscribe to loan app to get spanish translation for bounce
			next: ([loanApp, currentFileTypeUpload]) => {
				// show bounce reasons only if no new doc has been uploaded
				if (currentFileTypeUpload.includes(this.categoryType)) {
					this.bounceReasons = [];
				} else {
					this.bounceReasons = this.loanAppService.getBounceReasons()[ocrBounceMap[this.categoryType]];
				}
			}
		});
		this.subscription.add(loanAppSub);

		if (this.metaClassification) {
			this.documentClassificationList$ = this.metadataService.select(this.metaClassification).pipe(
				map((rsp) => {
					// remove parenthesis from classification text
					rsp.forEach((item) => {
						item.text = item.text.replace(/\(.*\)/, '');
					});
					return rsp;
				}),
				tap((list) => {
					this.selectedDocumentClassification = list.find(
						(item) => item.key == this.selectedDocumentClassification?.key
					);
				}),
				share()
			);
		}
	}

	ngOnDestroy(): void {
		this.sessionStorageService.remove(this.storageKey);
		this.subscription.unsubscribe();
	}

	public getPageId(): string {
		if (this.isProofTypeSelection) {
			return `${this.categoryType}_classification_selection`;
		}

		if (this.isProofUpload) {
			return `${this.categoryType}_${this.selectedDocumentClassification?.key}`;
		}

		if (this.isProofStatus) {
			return `${this.categoryType}_status`;
		}
		return `${this.categoryType}`;
	}

	get isProofTypeSelection(): boolean {
		return this.proofState === ProofStatusEnum.classificationSelection;
	}
	get isProofTypeEmpty(): boolean {
		return isEmpty(this.proofState);
	}
	get isProofUpload(): boolean {
		return this.proofState === ProofStatusEnum.upload;
	}
	get isProofStatus(): boolean {
		return this.proofState === ProofStatusEnum.status;
	}

	get proofState(): ProofStatusEnum {
		return this.stateStorage?.proofState;
	}
	set proofState(value: ProofStatusEnum) {
		if (value != this.stateStorage?.proofState) {
			Array.prototype.push.call(this.stateStorage.statusHistory, this.stateStorage.proofState);
			this.statusHistory = { ...this.statusHistory };
			this.stateStorage.proofState = value;
			this.sessionStorageService.set(this.storageKey, this.stateStorage);
		}
	}
	public clearStateHistory() {
		this.statusHistory = new Array();
		this.sessionStorageService.set(this.storageKey, this.stateStorage);
	}

	get selectedDocumentClassification(): IMetadata {
		return this.stateStorage?.selectedDocumentClassification;
	}
	set selectedDocumentClassification(value: IMetadata) {
		if (
			value?.key != this.stateStorage?.selectedDocumentClassification?.key ||
			value?.text != this.stateStorage?.selectedDocumentClassification?.text
		) {
			this.stateStorage.selectedDocumentClassification = value;
			this.updateClassFiles(this.categoryFiles, value);
			this.sessionStorageService.set(this.storageKey, this.stateStorage);
		}
	}

	get statusHistory(): ProofStatusEnum[] {
		return this.stateStorage?.statusHistory || [];
	}
	set statusHistory(value: ProofStatusEnum[]) {
		this.stateStorage.statusHistory = value;
		this.sessionStorageService.set(this.storageKey, this.stateStorage);
	}

	protected updateClassFiles(categoryFiles: IFileUpload[], classification: IMetadata): void {
		this.selectedClassificationFiles = this.filterClassificationFiles(categoryFiles, classification);
		this.reviewFiles = this.filterOcrReview(this.selectedClassificationFiles);
		this.finishedFiles = categoryFiles?.filter((file) => file?.status === FileUploadStatusEnum.finished);
		this.attention = Boolean(this.reviewFiles?.length);
	}

	protected filterClassificationFiles(files: IFileUpload[], classification: IMetadata): IFileUpload[] {
		return files?.filter((item) => item.classification == classification?.key);
	}

	protected filterOcrReview(files: IFileUpload[]): IFileUpload[] {
		return files?.filter((file) => file?.ocrInfo?.documentStatus === FileStatusEnum.review);
	}

	onBack(): void {
		const state = Array.prototype.pop.call(this.stateStorage.statusHistory);
		this.statusHistory = { ...this.statusHistory };
		if (state) {
			this.fileUploadService.removePendingFiles(this.categoryType);
			this.fileUploadService.removeErrorFiles(this.categoryType);
			this.stateStorage.proofState = state;
			this.sessionStorageService.set(this.storageKey, this.stateStorage);
		} else {
			this.fileUploadService.removePendingFiles(this.categoryType);
			this.fileUploadService.removeErrorFiles(this.categoryType);
			this.routingService.back();
		}
	}
}
