<div class="proof-of-income" *transloco="let t">
	<op-back-link class="op-mt-20" [green]="false" id="{{ getPageId() }}Back" (back)="onBack()">{{
		t('ACCESSIBILITY.back')
	}}</op-back-link>
	<op-masthead [title]="t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.proofOfIncome.incomeTitle')"> </op-masthead>
	<op-content-container class="content" *ngIf="documentClassificationList$ | async as classificationList">
		<op-document-classification-original
			*ngIf="proofState === proofStatusEnum.classificationSelection"
			id="incomeClassification"
			[documentClassificationList]="classificationList"
			(documentClassification)="saveDocumentClassification($event)"
			(cancel)="onCancel($event)"
		>
		</op-document-classification-original>

		<div *ngIf="proofState === proofStatusEnum.upload">
			<div>
				<div class="op-body-1-bold-font">{{ stateStorage?.selectedDocumentClassification?.text }}</div>
				<div class="op-dark-grey">
					<ul>
						<li
							*ngFor="
								let value of t(
									'DOCUMENT_SUBMIT.AUTOMATIC_PROOF.proofOfIncome.classificationInstructions.' +
										stateStorage?.selectedDocumentClassification?.key
								);
								index as i
							"
						>
							<div [innerHTML]="value"></div>
							<ul *ngIf="i === 0 && stateStorage?.selectedDocumentClassification?.key === 'BANK_STATEMENT'">
								<li
									*ngFor="
										let v of t(
											'DOCUMENT_SUBMIT.AUTOMATIC_PROOF.proofOfIncome.classificationInstructions.' +
												stateStorage?.selectedDocumentClassification?.key +
												'_CRITERIA'
										)
									"
									[innerHTML]="v"
								></li>
							</ul>
						</li>
					</ul>
				</div>
			</div>

			<op-document-notification-original *ngIf="bounceReasons?.length" id="incomeBounceReasons" class="op-mtb-20">
				<ul>
					<li *ngFor="let bounce of bounceReasons">{{ bounce }}</li>
				</ul>
			</op-document-notification-original>

			<op-document-upload-original
				id="incomeUpload"
				[categoryType]="categoryType"
				[documentClassification]="stateStorage?.selectedDocumentClassification"
				[selectedClassificationFiles]="selectedClassificationFiles"
				[showSingleFile]="false"
				[attentionOverRide]="attention"
			>
				<div uploadDocument>
					<div class="op-icon-align-body-1 upload-document-wrapper">
						<div></div>
						<mat-icon class="op-dark-green" svgIcon="op-cloudUpload"></mat-icon>
						<div class="op-body-1-bold-font op-dark-green upload-document">
							{{ t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.uploadDocuments') }}
						</div>
					</div>
					<div class="op-dark-grey op-mt-8" [innerHTML]="t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.fileInstructions')"></div>
				</div>
				<div criteriaMet>
					<div *ngIf="showCriteria" class="criteria-wrapper">
						<div class="icon-align" [ngClass]="{ met: criteria[criteriaEnum.zeroToThirtyDays] }" id="criteria0_30">
							<mat-icon *ngIf="!criteria[criteriaEnum.zeroToThirtyDays]" svgIcon="op-alert3"></mat-icon>
							<mat-icon *ngIf="criteria[criteriaEnum.zeroToThirtyDays]" svgIcon="op-checkMark-solid"></mat-icon>
							<span *ngIf="!criteria[criteriaEnum.zeroToThirtyDays]" id="criteriaNotMet0_30" class="icon-text">
								{{ t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.proofOfIncome.criteria.0_30_DAYS') }}
							</span>
							<span *ngIf="criteria[criteriaEnum.zeroToThirtyDays]" id="criteriaMet0_30" class="icon-text">
								{{ t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.proofOfIncome.criteriaMet.0_30_DAYS') }}
							</span>
						</div>
						<div
							class="icon-align op-mt-10"
							[ngClass]="{ met: criteria[criteriaEnum.thirtyOneToSixtyFiveDays] }"
							id="criteria31_65"
						>
							<mat-icon *ngIf="!criteria[criteriaEnum.thirtyOneToSixtyFiveDays]" svgIcon="op-alert3"></mat-icon>
							<mat-icon *ngIf="criteria[criteriaEnum.thirtyOneToSixtyFiveDays]" svgIcon="op-checkMark-solid"></mat-icon>
							<span *ngIf="!criteria[criteriaEnum.thirtyOneToSixtyFiveDays]" id="criteriaNotMet31_65" class="icon-text">
								{{ t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.proofOfIncome.criteria.31_65_DAYS') }}
							</span>
							<span *ngIf="criteria[criteriaEnum.thirtyOneToSixtyFiveDays]" id="criteriaMet31_65" class="icon-text">
								{{ t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.proofOfIncome.criteriaMet.31_65_DAYS') }}
							</span>
						</div>
					</div>
				</div>
			</op-document-upload-original>

			<div class="op-mt-30">
				<button
					mat-flat-button
					color="primary"
					name="incomeContinueBtn"
					id="incomeContinueBtn"
					[disabled]="submitDisabled"
					type="submit"
					(click)="onContinue()"
					[opTagClick]="getPageId() + 'Continue'"
				>
					{{ t('GLOBAL.continue') }}
				</button>
			</div>

			<div class="op-mt-20" *ngIf="canCancel">
				<button
					mat-flat-button
					color="secondary"
					name="incomeCancelBtn"
					id="incomeCancelBtn"
					(click)="onCancel()"
					[opTagClick]="getPageId() + 'Cancel'"
				>
					{{ t('GLOBAL.cancel') }}
				</button>
			</div>
		</div>

		<div *ngIf="proofState === proofStatusEnum.status">
			<op-document-status-original
				id="incomeStatus"
				[categoryType]="categoryType"
				[categoryFiles]="finishedFiles"
				(done)="onDocStatus($event)"
				[documentClassificationList]="classificationList"
			>
				<div [innerHTML]="t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.additionDocInfo')"></div>

				<op-document-notification-original *ngIf="bounceReasons?.length" id="incomeBounceReasons" class="op-mtb-20">
					<ul>
						<li *ngFor="let bounce of bounceReasons">{{ bounce }}</li>
					</ul>
				</op-document-notification-original>
			</op-document-status-original>

			<div class="op-mt-30">
				<button
					mat-flat-button
					color="primary"
					name="doneBtn"
					id="incomeStatusBtn"
					(click)="onCancel()"
					[opTagClick]="getPageId() + 'Continue'"
				>
					{{ t('GLOBAL.continue') }}
				</button>
			</div>
		</div>
	</op-content-container>
</div>
