<div class="home" *transloco="let t">
	<op-masthead [title]="t('HOME.serveArea')"></op-masthead>
	<op-content-container>
		<div class="content">
			<div class="op-center">
				<img src="/assets/images/home.svg" alt="" />
			</div>

			<div class="op-label-font op-gray op-mtb-20">{{ t('ADDRESS.requiredFields') }}</div>

			<form [formGroup]="formGroup" (ngSubmit)="onSubmit(formGroup.value)">
				<div class="op-body-1-bold-font op-mb-10">{{ t('HOME.enterZipCode') | opRequired }}</div>
				<div class="zipSearch">
					<mat-form-field class="zipInput" appearance="outline">
						<input
							matInput
							name="zipCode"
							id="zipCode"
							type="text"
							inputmode="numeric"
							pattern="[0-9]*"
							formControlName="zipCode"
						/>
						<mat-error
							id="zipCodeError"
							*ngIf="formGroup.controls['zipCode'].invalid && formGroup.controls['zipCode'].touched"
							>{{ t('LANDING.zipCodeError') }}</mat-error
						>
					</mat-form-field>
					<div class="zipBtn">
						<button
							mat-flat-button
							color="primary"
							name="searchButton"
							id="zipSearchBtn"
							[disabled]="formGroup.invalid"
							type="submit"
							[innerHTML]="t('HOME.search')"
							[opTagClick]="'Home search'"
						></button>
					</div>
				</div>
			</form>

			<div class="op-header-3-font op-mt-20 op-mb-12">
				{{ t('HOME.alreadyStarted') }}
			</div>
			<div class="op-body-1-font op-mb-20">
				{{ t('HOME.continueWhereLeftOff') }}
			</div>
			<p></p>

			<button
				mat-flat-button
				color="secondary"
				id="findYourApplicationBtn"
				[routerLink]="['/find-your-application']"
				[opTagClick]="'Home continue application'"
			>
				{{ t('HOME.continueApplication') }}
			</button>

			<div class="op-pt-40"></div>

			<op-how-it-works-panel id="howItWorksPanel"></op-how-it-works-panel>

			<div class="op-pt-40"></div>
		</div>
	</op-content-container>
</div>
