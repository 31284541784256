<div class="esign-sign-document" *transloco="let t">
	<op-masthead [title]="t('ESIGN_SIGN_DOCUMENT.loanTermsConfirmed')"></op-masthead>
	<op-content-container class="content">
		<div class="op-center">
			<img src="/assets/images/sign-document.svg" alt="sign document" />
		</div>

		<div class="op-header-3-font op-mt-20 op-mb-40">{{ t('ESIGN_SIGN_DOCUMENT.completeReceiveFunds') }}</div>

		<op-learn-more iconSize="small" icon="op-mobilePhone" [title]="t('ESIGN_SIGN_DOCUMENT.phoneReady')">
			<p>{{ t('ESIGN_SIGN_DOCUMENT.confirmIdentity') }}</p>
		</op-learn-more>
		<p></p>

		<op-learn-more iconSize="small" icon="op-pencil" [title]="t('ESIGN_SIGN_DOCUMENT.reviewSign')">
			<p>{{ t('ESIGN_SIGN_DOCUMENT.viewElectronically') }}</p>
		</op-learn-more>
		<p></p>

		<op-learn-more iconSize="small" icon="op-bank" [title]="t('ESIGN_SIGN_DOCUMENT.receiveFunds')">
			<div *ngIf="isOnlineCheck; else deposit">
				<op-info-tip
					id="receiveFundsViaCheck"
					[key]="
						showMetaMessage
							? t('ESIGN_SIGN_DOCUMENT.onlineCheckNoteMetabank')
							: t('ESIGN_SIGN_DOCUMENT.onlineCheckNote')
					"
				>
					{{ t('ESIGN_SIGN_DOCUMENT.onlineCheckReminder') }}
				</op-info-tip>
			</div>
			<ng-template #deposit>
				<p *ngIf="!fundingInfo">{{ t('ESIGN_SIGN_DOCUMENT.depositThreeDays') }}</p>
				<p *ngIf="fundingInfo">{{ t(fundingInfo) }}</p>
			</ng-template>
		</op-learn-more>

		<div class="op-mt-20">
			<button
				class="op-full-width"
				mat-flat-button
				color="primary"
				name="submitBtn"
				id="signDocNextBtn"
				type="button"
				(click)="onSubmit()"
			>
				{{ t('ESIGN_SIGN_DOCUMENT.next') }}
			</button>
		</div>

		<div class="op-mt-40 op-mb-20" *ngIf="showGoToStoreInfo">
			<h4>{{ t('ESIGN_SIGN_DOCUMENT.goToStore') }}</h4>
			<ul>
				<li
					id="goToParticipatingPartnerStore"
					*ngIf="hasPartnerDisbursementChannel"
					[innerHtml]="t('ESIGN_SIGN_DOCUMENT.partnerMessage' | transloco: { partner: partnerBrand })"
				></li>
				<li id="goToNearestOportunStore" [innerHtml]="t('ESIGN_SIGN_DOCUMENT.oportunMessage')"></li>
			</ul>
		</div>

		<div class="op-mt-20">
			<a
				*ngIf="isPartnerApplication && !showMetaMessage"
				href=""
				onclick="return false"
				mat-flat-button
				color="link-md"
				id="findLocationLink"
				(click)="onFindLocation()"
				[opTagClick]="'Find Locations'"
			>
				{{ t('TERMS_CONFIRMED.findLocationNearMe') }}
			</a>
		</div>
	</op-content-container>
</div>
