<div class="add-debit-card" *transloco="let t">
	<op-back-link class="op-mt-20" [green]="false" id="addDebitCardBack">{{ t('ACCESSIBILITY.back') }}</op-back-link>
	<op-masthead [customClass]="'head-bp-4'" [title]="t('DISBURSEMENT_SELECTION.DEBIT_CARD.ADD.title')"></op-masthead>
	<div class="op-gray">{{ t('DISBURSEMENT_SELECTION.DEBIT_CARD.ADD.message') }}</div>
	<div class="debit-card-note op-mtb-20">
		<div *ngIf="!showCardError" class="card-note">
			<mat-icon svgIcon="op-attention-orange"></mat-icon>
			<span class="op-ml-5">{{ t('DISBURSEMENT_SELECTION.DEBIT_CARD.ADD.note') }}</span>
		</div>
		<div *ngIf="showCardError" class="card-note error op-pad-20">
			<mat-icon svgIcon="op-attention"></mat-icon>
			<span class="op-ml-5">{{ t('DISBURSEMENT_SELECTION.DEBIT_CARD.ADD.NOT_SUPPORTED.' + showCardError) }}</span>
		</div>
	</div>
	<div class="debit-card-note op-mb-5 op-black-bold">
		<op-info-tip id="routingInfoTip" [key]="t('DISBURSEMENT_SELECTION.DEBIT_CARD.ADD.remind')">
			{{ cardOwner | titlecase }}
		</op-info-tip>
	</div>
	<iframe
		*ngIf="tabaPayLink"
		onload="this.style.height=(this.contentWindow.document.body.scrollHeight+200)+'px';this.style.width=(this.contentWindow.document.body.scrollWidth)+'px';"
		style="height: 100%; width: 100%; border: none; overflow: hidden"
		[src]="tabaPayLink"
	>
	</iframe>
</div>
