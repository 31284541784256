import { animate, query, stagger, style, transition, trigger } from '@angular/animations';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { isEmpty } from 'lodash';
import { BehaviorSubject, combineLatest, forkJoin, iif, Observable, of, Subscription } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { AchBankAccountsService } from 'src/app/core/services/ach-bank-accounts/ach-bank-accounts.service';
import { DialogService } from 'src/app/core/services/dialog/dialog.service';
import {
	EventDataService,
	EventDataTagTypeEnum,
	EventDataTagValueEnum
} from 'src/app/core/services/event-data/event-data.service';
import { FileUploadService, FileUploadType, IFileUpload } from 'src/app/core/services/file-upload/file-upload.service';
import { ApplicantUtils } from 'src/app/core/services/loan-application/applicant/applicant-utils';
import { DisbursementUtils } from 'src/app/core/services/loan-application/disbursement/disbursement-utils';
import {
	ApplicationStatusEnum,
	BounceReasonsEnum,
	ILoanApplication,
	ILoanApplicationStatus
} from 'src/app/core/services/loan-application/loan-application.model';
import { LoanApplicationService } from 'src/app/core/services/loan-application/loan-application.service';
import { MetadataEnum } from 'src/app/core/services/metadata/metadata.model';
import { MetadataService } from 'src/app/core/services/metadata/metadata.service';
import { ConfigApiService, IConfigResult, IDocumentStatus, IMetadata, IResult } from 'src/app/core/services/mobile-api';
import { MobileApiService } from 'src/app/core/services/mobile-api/mobile-api.service';
import { NeuroIdService } from 'src/app/core/services/neuro-id/neuro-id.service';
import { RoutingPathsEnum, RoutingService } from 'src/app/core/services/routing/routing.service';
import { SoftPullService } from 'src/app/core/services/soft-pull/soft-pull.service';
import { SessionStorageService } from 'src/app/core/services/storage/session-storage.service';
import { TagDataService } from 'src/app/core/services/tag-data/tag-data.service';
import { AddressUtils } from 'src/app/core/utils/address-utils';
import { StateUtils } from 'src/app/core/utils/state-utils';
import { IMessageDialogData } from 'src/app/shared/components/dialogs/message-dialog/message-dialog.component';
import { FinishedFileUploadsPipe } from 'src/app/shared/pipes/finished-file-uploads/finished-file-uploads.pipe';

import { ConfirmRemoveDocument2DialogComponent } from '../confirm-remove-document2-dialog/confirm-remove-document2-dialog.component';
import { ConfirmSubmitDialogComponent } from '../confirm-submit-dialog/confirm-submit-dialog.component';
import { DocumentSubmitService } from '../document-submit.service';
import { AutoVerificationService, DocBadgeEnum, IDocVerification } from './auto-verification.service';

export interface IAutomaticVerification {
	verified?: boolean;
	needsAttention?: boolean;
	badge?: DocBadgeEnum;
	count?: number;
	bounceAttention?: boolean;
	categoryType: FileUploadType;
	icon: string;
	title: string;
	description?: string;
	click?: (type: FileUploadType) => void;
}
export interface IVisibilityFlagsTemp {
	[type: string]: IAutomaticVerification;
}
export interface IVisibilityFlags {
	[type: string]: boolean;
}

export const ocrBounceMap = {
	[FileUploadType.income]: BounceReasonsEnum.income,
	[FileUploadType.identification]: BounceReasonsEnum.identification,
	[FileUploadType.addresses]: BounceReasonsEnum.residence,
	[FileUploadType.bank]: BounceReasonsEnum.disbursement,
	[FileUploadType.selfie]: BounceReasonsEnum.photo
};

export const bounceOcrMap = {
	[BounceReasonsEnum.income]: FileUploadType.income,
	[BounceReasonsEnum.identification]: FileUploadType.identification,
	[BounceReasonsEnum.residence]: FileUploadType.addresses,
	[BounceReasonsEnum.disbursement]: FileUploadType.bank,
	[BounceReasonsEnum.photo]: FileUploadType.selfie
};

interface IAutomaticVerificationMap {
	[type: string]: IAutomaticVerification;
}

const listAnimation = trigger('listAnimation', [
	transition('* <=> *', [
		query(':enter', [style({ opacity: 0 }), stagger('60ms', animate('600ms ease-out', style({ opacity: 1 })))], {
			optional: true
		}),
		query(':leave', animate('200ms', style({ opacity: 0 })), { optional: true })
	])
]);

@Component({
	selector: 'op-auto-verification',
	templateUrl: './auto-verification.component.html',
	styleUrls: ['./auto-verification.component.scss'],
	animations: [listAnimation],
	providers: [DocumentSubmitService, FinishedFileUploadsPipe]
})
export class AutoVerificationComponent implements OnInit, OnDestroy {
	constructor(
		private mobileService: MobileApiService,
		private metadataService: MetadataService,
		private loanAppService: LoanApplicationService,
		private softPullService: SoftPullService,
		private fileUploadService: FileUploadService,
		private translocoService: TranslocoService,
		private routingService: RoutingService,
		private documentSubmitService: DocumentSubmitService,
		private autoVerificationService: AutoVerificationService,
		private tagDataService: TagDataService,
		private bankAccountService: AchBankAccountsService,
		private nidService: NeuroIdService,
		private finishedFileUploadsPipe: FinishedFileUploadsPipe,
		private configApiService: ConfigApiService,
		private dialogService: DialogService,
		private sessionStorageService: SessionStorageService,
		private eventDataService: EventDataService
	) {}

	private subscription = new Subscription();
	dropOffOnDocUpload = true;

	public fileUploadType: typeof FileUploadType = FileUploadType;

	isSoftPullEnabled: boolean;

	documentDeleteEnabled: boolean;
	showDeleteDocumentButton: boolean;

	private readonly showPhotoSource = new BehaviorSubject<boolean>(null);
	readonly showPhoto$ = this.showPhotoSource.asObservable();

	private readonly hasDocumentRequirementSectionSource = new BehaviorSubject<boolean>(null);
	readonly hasDocumentRequirementSection$ = this.hasDocumentRequirementSectionSource.asObservable();

	identificationType: IMetadata;

	showSkipSPLOfferButton: boolean;
	showChangeAchDisbursement: boolean;
	showReturnToOffers: boolean;
	showRejectDisbursementLink: boolean;

	applicantConsent: boolean;
	confirmSubmitTranslations: Array<any>;
	isWI: boolean;

	visibilityFlagsMap: IVisibilityFlags = {};
	automaticVerificationList: IAutomaticVerification[] = [];
	autoVerificationMap: IDocVerification;
	initialized = false;
	needsUploads = false;
	hasAlert = false;

	isNoDocState = false;
	isBankVerified: boolean;
	isIncomeVerified: boolean;

	showBouncedResubmitSection: boolean;

	submitProgress: number;
	submitTime: number;

	showReviewingDocuments = false;
	defaultOrder = [
		FileUploadType.income,
		FileUploadType.identification,
		FileUploadType.bank,
		FileUploadType.addresses,
		FileUploadType.selfie
	];

	defaultAutomaticVerificationMap: IAutomaticVerificationMap = {
		[FileUploadType.income]: {
			categoryType: FileUploadType.income,
			icon: 'op-moneyBag',
			title: 'DOCUMENT_SUBMIT.AUTOMATIC_PROOF.proofOfIncome.incomeTitle',
			description: 'DOCUMENT_SUBMIT.AUTOMATIC_PROOF.proofOfIncome.incomeDesc',
			click: this.onClick.bind(this)
		},
		[FileUploadType.identification]: {
			categoryType: FileUploadType.identification,
			icon: 'op-backgroundCheck',
			title: 'DOCUMENT_SUBMIT.AUTOMATIC_PROOF.proofOfIdentification.identificationTitle',
			description: 'DOCUMENT_SUBMIT.AUTOMATIC_PROOF.proofOfIdentification.identificationDesc',
			click: this.onClick.bind(this)
		},
		[FileUploadType.bank]: {
			categoryType: FileUploadType.bank,
			icon: 'op-bank',
			title: 'DOCUMENT_SUBMIT.AUTOMATIC_PROOF.proofOfBankAccount.bankAccountTitle',
			description: 'DOCUMENT_SUBMIT.AUTOMATIC_PROOF.proofOfBankAccount.bankAccountDesc',
			click: this.onClick.bind(this)
		},
		[FileUploadType.addresses]: {
			categoryType: FileUploadType.addresses,
			icon: 'op-house',
			title: 'DOCUMENT_SUBMIT.AUTOMATIC_PROOF.proofOfAddress.addressTitle',
			description: 'DOCUMENT_SUBMIT.AUTOMATIC_PROOF.proofOfAddress.addressDesc',
			click: this.onClick.bind(this)
		},
		[FileUploadType.selfie]: {
			categoryType: FileUploadType.selfie,
			icon: 'op-photoCamera',
			title: 'DOCUMENT_SUBMIT.AUTOMATIC_PROOF.proofOfSelfie.selfieTitle',
			description: 'DOCUMENT_SUBMIT.AUTOMATIC_PROOF.proofOfSelfie.selfieDesc',
			click: this.onClick.bind(this)
		}
	};

	ngOnInit(): void {
		const softPullSub = this.softPullService.softPull$.pipe(filter(Boolean)).subscribe({
			next: (softPull: IConfigResult) => {
				this.isSoftPullEnabled = softPull.value;
			}
		});
		this.subscription.add(softPullSub);

		const autoVerification$ = this.autoVerificationService.autoVerification$;
		const sub = autoVerification$.subscribe({
			next: (rsp: IDocVerification) => {
				this.autoVerificationMap = this.addVerifiedBadges(rsp);
				this.automaticVerificationList = this.updateAutoVerificationList(
					this.automaticVerificationList,
					this.autoVerificationMap
				);
				this.isNoDocState = this.checkNoDocState();
				this.hasAlert = this.automaticVerificationList.some(
					(item) => item.badge === DocBadgeEnum.attention || item.badge === DocBadgeEnum.processing
				);
			}
		});
		this.subscription.add(sub);

		if (isEmpty(Object.values(this.fileUploadService.getFileUploads()).flat())) {
			this.mobileService.getDocumentsStatus(null, this.loanAppService.loanApplicationId).subscribe({
				next: (rsp: IDocumentStatus[]) => {
					this.fileUploadService.reconcileFileUploads(rsp);
					this.showDeleteDocumentButton = this.documentSubmitService.showDeleteDocumentButton(
						this.documentDeleteEnabled,
						this.hasUploadedDocuments()
					);
				}
			});
		}

		this.metadataService.select(MetadataEnum.IncomeDocumentClassification).subscribe();

		const loanApp$ = this.loanAppService.loanApplication$.pipe(filter(Boolean));
		const bankAccounts$ = this.bankAccountService.bankAccounts$;
		const customerAddress$ = this.mobileService.getAddresses(
			this.loanAppService.loanApplicationId,
			this.loanAppService.getCurrentApplicantUtils()?.getCurrentApplicantIndex()
		);
		const documentDeleteEnabled$ = this.configApiService.configDocumentDeleteEnabled();

		const observe$ = combineLatest([loanApp$, bankAccounts$, customerAddress$, documentDeleteEnabled$]);

		const loanAppSub = observe$.subscribe({
			next: ([loanApp, bankAccounts, addrList, documentDeleteEnabled]) => {
				const applicant = ApplicantUtils.fromLoanApp(loanApp);
				this.isBankVerified = this.documentSubmitService.isBankConnected(bankAccounts);
				this.isIncomeVerified =
					this.documentSubmitService.isAutoVerifiedIncome() &&
					applicant.verifiedMonthlyIncomeSource !== 'OCR_VERIFICATION';

				this.isWI = StateUtils.isWisconsin(AddressUtils.getHomeState(addrList));

				this.autoVerificationMap = this.addVerifiedBadges(this.autoVerificationMap);
				this.updateVisibilityFlags();
				this.automaticVerificationList = this.updateAutoVerificationList(
					this.automaticVerificationList,
					this.autoVerificationMap
				);
				this.hasAlert = this.automaticVerificationList.some(
					(item) => item.badge === DocBadgeEnum.attention || item.badge === DocBadgeEnum.processing
				);

				this.documentDeleteEnabled = documentDeleteEnabled?.value || false;
				this.showDeleteDocumentButton = this.documentSubmitService.showDeleteDocumentButton(
					this.documentDeleteEnabled,
					this.hasUploadedDocuments()
				);
				this.isNoDocState = this.checkNoDocState();
				this.initialized = true;
			}
		});
		this.subscription.add(loanAppSub);

		const keyParams = {
			CONFIRM_SUBMIT_MODAL: null
		};
		const translocoSub = this.translocoService.selectTranslateObject(keyParams).subscribe({
			next: (result) => {
				this.confirmSubmitTranslations = result?.[0];
			}
		});
		this.subscription.add(translocoSub);

		this.mobileService
			.getApplicantConsent(
				this.loanAppService.loanApplicationId,
				this.loanAppService.getCurrentApplicantUtils()?.getCurrentApplicantIndex()
			)
			.subscribe({
				next: (rsp) => {
					this.applicantConsent = rsp.loanAppAuthorization && rsp.applicantWirelessConsent;
				}
			});
	}

	ngOnDestroy(): void {
		this.sessionStorageService.set('documentSubmit', false);
		if (this.dropOffOnDocUpload) {
			const plaidEvent = {
				eventType: EventDataTagTypeEnum.documentEventType,
				value1: EventDataTagValueEnum.documentValue
			};
			const tagEvent = {
				tealium_event: EventDataTagTypeEnum.documentEventType,
				event_action: EventDataTagValueEnum.documentValue
			};
			this.tagDataService.link({}, tagEvent);
			this.eventDataService.logEventDataToLoanApplication(plaidEvent);
		}
		this.subscription.unsubscribe();
	}

	private addVerifiedBadges(list: IDocVerification): IDocVerification {
		list[FileUploadType.income].badge = this.isIncomeVerified
			? DocBadgeEnum.verified
			: list[FileUploadType.income].badge;
		list[FileUploadType.bank].badge = this.isBankVerified ? DocBadgeEnum.verified : list[FileUploadType.bank].badge;
		return list;
	}

	private checkNoDocState(): boolean {
		const allVerified = this.automaticVerificationList?.every((item) => item.badge === DocBadgeEnum.verified);
		this.isNoDocState = (!this.automaticVerificationList?.length || allVerified) && !this.showBouncedResubmitSection;
		return this.isNoDocState;
	}

	private submitDocument(): Observable<IResult> {
		return this.mobileService.setSubmitV2(this.loanAppService.loanApplicationId).pipe(
			tap({
				next: (rsp) => {
					this.nidService.setNid('setCheckpoint', 'submit_final_review');
					this.nidService.setNid('applicationSubmit');
				},
				error: (err) => this.dialogService.openErrorDialog(err).subscribe()
			})
		);
	}

	private confirmDialog(): Observable<IResult> {
		if (
			!this.loanAppService.getProductOfferDetailsUtils()?.hasSingleProductOffered() &&
			!this.documentSubmitService?.checkIfValidStatusForSubmit() &&
			!this.hasSingleProductWithIncomeSourceOptionsOffered()
		) {
			this.tagDataService.link(
				{},
				{
					tealium_event: 'Plaid Income Verify Scenario III Submit',
					event_action: 'Submit'
				}
			);
		}
		return this.submitDocument();
	}

	private updateAutoVerificationList(
		viewList: IAutomaticVerification[],
		list: IDocVerification
	): IAutomaticVerification[] {
		const newList = viewList.map((item) => {
			if (list[item.categoryType]) {
				item.bounceAttention = list[item.categoryType]?.bounce;
				item.needsAttention = list[item.categoryType]?.badge === DocBadgeEnum.attention;
				item.badge = list[item.categoryType]?.badge;
				item.count = list[item.categoryType]?.count;
			}
			return item;
		});
		return this.sortVerificationList(newList);
	}

	private sortVerificationList(list: IAutomaticVerification[]): IAutomaticVerification[] {
		const needsAttention = list?.some((item) => item.needsAttention);
		const badgeOrder = Object.values(DocBadgeEnum);
		if (needsAttention) {
			list.sort((a, b) => badgeOrder.indexOf(a.badge) - badgeOrder.indexOf(b.badge));
		} else {
			list.sort((a, b) => this.defaultOrder.indexOf(a.categoryType) - this.defaultOrder.indexOf(b.categoryType));
			list.sort((a, b) => badgeOrder.indexOf(a.badge) - badgeOrder.indexOf(b.badge));
		}
		return list;
	}

	private pollApplicationStatus(): Observable<ILoanApplicationStatus> {
		const isStatusNotSubmitInProgress = (status: ApplicationStatusEnum) =>
			status !== ApplicationStatusEnum.submitInProgress && status !== ApplicationStatusEnum.verified;
		const isProgress = (p: number, t: number) => {
			this.submitProgress = p;
			this.submitTime = t;
		};

		const getSubmitDuration$ = this.configApiService.configOcrSubmitDelay(false).pipe(
			catchError(() => {
				return of({ value: null });
			})
		);
		const updateLoanApp$ = this.loanAppService.updateLoanApplication(null, null);
		return forkJoin({ duration: getSubmitDuration$, loanApp: updateLoanApp$ }).pipe(
			map(({ duration, loanApp }) => duration),
			map((rsp: IConfigResult) => Number(rsp?.value) * 1000),
			switchMap((duration) => {
				if (duration) {
					this.showReviewingDocuments = true;
					return this.loanAppService.pullApplicationStatus(isStatusNotSubmitInProgress, isProgress, duration);
				} else {
					return of({ applicationStatus: this.loanAppService.getLoanApp().applicationStatus });
				}
			}),
			catchError((err) => {
				return of({ applicationStatus: ApplicationStatusEnum.submitted });
			})
		);
	}

	onSubmit(): void {
		const missingDocumentSection = this.autoVerificationService.getMissingDocumentSection(
			this.automaticVerificationList.map((item) => {
				return { categoryType: item.categoryType, badge: item.badge };
			})
		);
		if (isEmpty(missingDocumentSection) || this.showBouncedResubmitSection) {
			this.needsUploads = false;
			// forward to the coApplicant, if there is one
			if (this.loanAppService.getCurrentApplicantUtils().isCoApplicant()) {
				this.dropOffOnDocUpload = false;
				this.routingService.route(RoutingPathsEnum.almostDone);
			} else {
				this.openConfirmSubmitDialog(this.confirmDialog.bind(this), this.cancelDialog.bind(this))
					.pipe(
						switchMap((rsp) => {
							return rsp.applicationStatus === ApplicationStatusEnum.submitInProgress
								? this.pollApplicationStatus()
								: of(rsp);
						})
					)
					.subscribe({
						next: (result) => {
							if (result) {
								this.dropOffOnDocUpload = false;
								if (result?.applicationStatus === ApplicationStatusEnum.preApproved) {
									this.routingService.route(RoutingPathsEnum.offerStatus);
								} else {
									this.routingService
										.routeFromLoanAppSub()
										.pipe(
											take(1),
											tap(() => {
												this.showReviewingDocuments = false;
											}),
											catchError((err) => {
												this.routingService.route(RoutingPathsEnum.status);
												return of(null);
											})
										)
										.subscribe();
								}
							}
						}
					});
			}
		} else {
			this.needsUploads = true;
			this.sessionStorageService.set('documentSubmit', true);
			this.tagDataService.link(
				{},
				{
					tealium_event: 'application_submit_needs_docs',
					event_action: 'submit',
					event_label: 'form_not_complete',
					event_category: 'rx_form',
					product_sub_category: this.sessionStorageService.get('productCategorySelection'),
					product_offer_status: this.tagDataService.getTealiumStringForOfferStatus(
						this.loanAppService.getLoanApp().productOfferDetails
					)
				}
			);
		}
	}

	private hasSingleProductWithIncomeSourceOptionsOffered(): boolean {
		const productOfferDetailUtilities = this.loanAppService.getProductOfferDetailsUtils();
		return (
			productOfferDetailUtilities.numberOfOffers === 2 &&
			!productOfferDetailUtilities.hasSecuredPersonalLoan() &&
			this.loanAppService.getCurrentApplicant().incomeSourceOptionsOffered
		);
	}

	private openConfirmSubmitDialog(confirm: () => Observable<any>, cancel: () => Observable<any>): Observable<any> {
		const data = {
			isSoftPullEnabled: this.isSoftPullEnabled,
			instructions: this.documentSubmitService.getSubmitInstructionsDescription(
				this.confirmSubmitTranslations,
				this.applicantConsent,
				this.isWI
			),
			isWI: this.isWI,
			earlyRenewalEligible: false,
			hasConsent: this.applicantConsent,
			noDocumentsRequired: this.isNoDocState
		};
		const dialogRef = this.dialogService.open(ConfirmSubmitDialogComponent, { data });
		this.tagDataService.link(
			{},
			{
				tealium_event: 'application_submit',
				event_action: 'submit',
				event_label: 'Form',
				event_category: 'rx_form',
				product_sub_category: this.sessionStorageService.get('productCategorySelection'),
				product_offer_status: this.tagDataService.getTealiumStringForOfferStatus(
					this.loanAppService.getLoanApp().productOfferDetails
				)
			}
		);
		return dialogRef.afterClosed().pipe(switchMap((rsp) => iif(() => rsp, confirm(), cancel())));
	}

	returnToOffers(): void {
		this.dropOffOnDocUpload = false;
		this.routingService.route(RoutingPathsEnum.offerStatus);
	}

	switchToAchDisbursement(): void {
		this.dropOffOnDocUpload = false;
		if (this.loanAppService.isBtmEligible()) {
			this.bankAccountService.isBankConnected()
				? this.routingService.route(RoutingPathsEnum.funds)
				: this.routingService.route(RoutingPathsEnum.bankVerification);
		} else {
			this.routingService.route(RoutingPathsEnum.receiveFunds);
		}
	}

	rejectDisbursementAch(isAchDisbursementSelected: boolean): Observable<ILoanApplication> {
		const updateDisbursement$ = this.loanAppService.isApplicationStatusBounced()
			? this.mobileService.updateDisbursement(true, this.loanAppService.loanApplicationId)
			: this.mobileService.updateDisbursementAch(isAchDisbursementSelected, this.loanAppService.loanApplicationId);
		return updateDisbursement$.pipe(
			switchMap(this.loanAppService.updateLoanApplication.bind(this.loanAppService, null, null))
		);
	}

	confirmAndRejectDisbursement(): void {
		const data: IMessageDialogData = {
			icon: 'op-info',
			title: this.translocoService.translate('DOCUMENT_SUBMIT.proofOfBankAccount.chooseNonAchDisbursementOption'),
			message: this.translocoService.translate('DOCUMENT_SUBMIT.proofOfBankAccount.areYouSureNonAchDisbursement'),
			confirmText: this.translocoService.translate('DOCUMENT_SUBMIT.proofOfBankAccount.confirmChange')
		};
		this.dialogService
			.openMessageDialog(
				data,
				() => this.rejectDisbursementAch(false),
				() => of(false)
			)
			.subscribe();
	}

	trackBy(index: number, item: IAutomaticVerification): FileUploadType {
		return item.categoryType;
	}

	private updateVisibilityFlags(): void {
		const disbursement = DisbursementUtils.fromLoanApp(this.loanAppService.getLoanApp());
		const isAchDisbursement = disbursement.isTypeAch();

		this.visibilityFlagsMap = {
			[FileUploadType.income]: this.documentSubmitService.showIncomeV2() || this.isIncomeVerified,
			[FileUploadType.identification]: this.documentSubmitService.showIdentificationV2(),
			[FileUploadType.bank]: this.documentSubmitService.showBankV2() || this.isBankVerified,
			[FileUploadType.addresses]: this.documentSubmitService.showAddressV2(),
			[FileUploadType.selfie]:
				this.documentSubmitService.showPhoto() ||
				this.fileUploadService.isSomeFileTypeUploadFinished(FileUploadType.selfie)
		};

		this.automaticVerificationList = Object.keys(this.visibilityFlagsMap)
			.map((key) => {
				return this.defaultAutomaticVerificationMap[key];
			})
			.filter((item) => this.visibilityFlagsMap[item.categoryType]);
		this.sortVerificationList(this.automaticVerificationList);

		const bounceReasons = this.loanAppService.getFilteredBounceReasons();
		this.showBouncedResubmitSection =
			this.loanAppService.isApplicationStatusBounced() && !Object.keys(bounceReasons)?.length;

		this.showSkipSPLOfferButton = this.documentSubmitService.canShowSkipSPLButton();
		this.showChangeAchDisbursement = this.documentSubmitService.hideChangeAchDisbursementLink();
		this.showReturnToOffers = this.documentSubmitService.showReturnToOffers();
		this.showRejectDisbursementLink =
			isAchDisbursement && !this.isBankVerified && this.visibilityFlagsMap[FileUploadType.bank];
		this.isNoDocState = this.checkNoDocState();
	}

	// Delete document section
	removeDocument(): void {
		this.showRemoveDocumentDialog(this.deleteDocuments.bind(this), this.cancelDialog.bind(this)).subscribe({
			next: (result) => {
				if (result) {
					this.showDeleteDocumentButton = this.documentSubmitService.showDeleteDocumentButton(
						this.documentDeleteEnabled,
						this.hasUploadedDocuments()
					);
				}
			}
		});
	}

	private showRemoveDocumentDialog(confirm: () => Observable<any>, cancel: () => Observable<any>): Observable<boolean> {
		const dialogRef = this.dialogService.open(ConfirmRemoveDocument2DialogComponent, {});
		return dialogRef.afterClosed().pipe(switchMap((rsp) => iif(() => rsp, confirm(), cancel())));
	}

	deleteDocuments(): Observable<boolean> {
		if (!this.documentDeleteEnabled) {
			return of(false);
		}
		return this.fileUploadService.deleteUploadsV2().pipe(
			tap(() => {
				this.sessionStorageService.set('documentSubmit', false);
				this.needsUploads = false;
			}),
			map((fileUploads) => isEmpty(fileUploads)),
			catchError((err) => {
				return this.mobileService.getDocumentsStatus(null, this.loanAppService.loanApplicationId).pipe(
					map((rsp) => {
						if (isEmpty(rsp)) {
							this.fileUploadService.resetFilesExceptSelfie();
							this.showDeleteDocumentButton = this.documentSubmitService.showDeleteDocumentButton(
								this.documentDeleteEnabled,
								this.hasUploadedDocuments()
							);
							return true;
						} else {
							return false;
						}
					}),
					switchMap((rsp) =>
						!rsp
							? this.dialogService.openErrorDialog(this.translocoService.translate('DIALOG_MESSAGE.genericTitle'))
							: of()
					)
				);
			})
		);
	}

	cancelDialog(): Observable<any> {
		return of(false);
	}

	private hasUploadedDocuments(): boolean {
		const fileUploads = this.fileUploadService.getFileUploads();
		const fileCount = Object.keys(fileUploads).reduce((acc: number, cur: string) => {
			if (cur !== FileUploadType.selfie) {
				const finishedFileUploads = this.finishedFileUploadsPipe.transform(fileUploads[cur]) as IFileUpload[];
				acc += finishedFileUploads.length;
			}
			return acc;
		}, 0);

		return fileCount > 0;
	}
	//  End Delete documents

	onClick(file: IAutomaticVerification): void {
		if (file.badge === DocBadgeEnum.verified) {
			return;
		}

		const routeMap = {
			[FileUploadType.income]: RoutingPathsEnum.documentSubmitProofOfIncome,
			[FileUploadType.identification]: RoutingPathsEnum.documentSubmitProofOfIdentification,
			[FileUploadType.addresses]: RoutingPathsEnum.documentSubmitProofOfAddress,
			[FileUploadType.bank]: RoutingPathsEnum.documentSubmitProofOfBankAccount,
			[FileUploadType.selfie]: RoutingPathsEnum.documentSubmitProofOfSelfie
		};
		this.routingService.route(routeMap[file?.categoryType]);
	}
}
