import { Component, Input } from '@angular/core';
import { FileUploadType, IFileUpload } from 'src/app/core/services/file-upload/file-upload.service';
import { FileStatusEnum } from 'src/app/core/services/mobile-api';
import { opRequired } from 'src/app/shared/decorators/required.decorator';

@Component({
	selector: 'op-document-v-thumbnails-original',
	templateUrl: './document-thumbnails-original.component.html',
	styleUrls: ['./document-thumbnails-original.component.scss']
})
export class DocumentVThumbnailsOriginalComponent {
	@Input()
	@opRequired()
	id: string;

	@Input()
	categoryType: FileUploadType;

	_files: Array<IFileUpload>;

	@Input()
	get files(): Array<IFileUpload> {
		return this._files;
	}
	set files(value: Array<IFileUpload>) {
		this._files = this.sortList(value?.reverse());
	}

	public fileStatusEnum: typeof FileStatusEnum = FileStatusEnum;

	trackBy(index: number, item: IFileUpload): string {
		return item?.ocrInfo?.documentUploadId;
	}

	private sortList(list: IFileUpload[]): IFileUpload[] {
		const isReview = (f) => f.ocrInfo?.documentStatus === FileStatusEnum.review;
		list?.sort((a, b) => {
			if (isReview(a) && !isReview(b)) {
				return -1;
			} else if (!isReview(a) && isReview(b)) {
				return 1;
			} else {
				return 0;
			}
		});
		return list;
	}
}
