import { Injectable, OnDestroy } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { chain, forEach, has, isEmpty, isFunction } from 'lodash';
import _isEqual from 'lodash/isEqual';
import {
	BehaviorSubject,
	catchError,
	concatMap,
	distinctUntilChanged,
	filter,
	finalize,
	first,
	map,
	Observable,
	of,
	retry,
	share,
	Subscription,
	switchMap,
	tap,
	timeout,
	timer
} from 'rxjs';

import { AddressUtils } from '../../utils/address-utils';
import { AuthenticationService } from '../authentication/authentication.service';
import { LandingService } from '../landing/landing.service';
import {
	ApplicationFlowEnum,
	IFastTrackData,
	INewApplicationResult,
	IVerifyFactorResult
} from '../mobile-api/mobile-api.model';
import { MobileApiService } from '../mobile-api/mobile-api.service';
import { SessionStorageService } from '../storage/session-storage.service';
import { ApplicantUtils } from './applicant/applicant-utils';
import { ApplicantStepCompleteEnum, IApplicant, VerificationStatusEnum } from './applicant/applicant.model';
import { DisbursementUtils } from './disbursement/disbursement-utils';
import {
	ApplicationStatusEnum,
	ApplicationTypeEnum,
	EditableSectionMap,
	EditableSectionsList,
	ILoanApplication,
	IShowBounceAttention
} from './loan-application.model';
import { ProductOfferDetailsUtils } from './product-offer/product-offer-details/product-offer-details-utils';
import { ProductCategoriesEnum } from './product-offer/product/product.model';

export interface IBounceReasons {
	[key: string]: string[];
}

/**
 * Service to track the loan application.
 * stores the loan application.
 * Includes helper functions.
 *
 * @export
 * @class loanAppService
 */
@Injectable({
	providedIn: 'root'
})
export class LoanApplicationService implements OnDestroy {
	// Make loanApplicationSource private so it's not accessible from the outside,
	// expose it as observable (read-only) instead.
	// Write to loanApplicationSource only through specified store methods below.
	private readonly loanApplicationSource = new BehaviorSubject<ILoanApplication>(null);

	// Exposed observable (read-only).
	readonly loanApplication$ = this.loanApplicationSource.asObservable();

	// NOTE: should match key in instrumentationInterceptor
	private readonly storageKey = 'loanId';
	private readonly subscription = new Subscription();

	private loanAppCache: any;
	private loanAppCachedObservable: Observable<ILoanApplication>;

	private frequencyPoll = 2000;
	private frequencyUpdate = 400;
	private duration = 20000;

	constructor(
		private landingService: LandingService,
		private mobileService: MobileApiService,
		private authService: AuthenticationService,
		private sessionStorageService: SessionStorageService,
		private translocoService: TranslocoService
	) {
		const sessionData = this.sessionStorageService.get(this.storageKey);

		// transloco return en right away.  Then es if current lang is spanish.
		// use switchMap to cancel en call.  Only make call if id is in session storage or Observable.
		const langSub = this.translocoService.langChanges$
			.pipe(
				distinctUntilChanged(),
				filter(() => Boolean(sessionData) || Boolean(this.loanApplicationId)),
				switchMap(() => this.updateLoanApplication(sessionData || null, true))
			)
			.subscribe();
		this.subscription.add(langSub);

		const storageKeySub = this.sessionStorageService.select(this.storageKey).subscribe({
			next: (value) => {
				if (isNaN(value) && !isEmpty(this.getLoanApp())) {
					this.setLoanApp(null);
				}
			},
			complete: () => {
				this.setLoanApp(null);
			}
		});
		this.subscription.add(storageKeySub);

		// Clear out data if no token
		this.authService.authorization$
			.pipe(
				map((rsp) => rsp?.token),
				map((token) => !token && this.setLoanApp(null))
			)
			.subscribe();

		const preAuth$ = this.landingService.landing$.pipe(share());

		preAuth$
			.pipe(
				map((rsp) => rsp?.newApplication),
				distinctUntilChanged((a, b) => _isEqual(a, b)),
				filter((val) => Boolean(val)),
				map(this.updateFromNewApp.bind(this))
			)
			.subscribe();

		preAuth$
			.pipe(
				map((rsp) => rsp?.multiFactor),
				distinctUntilChanged((a, b) => _isEqual(a, b)),
				filter((val) => Boolean(val)),
				map(this.updateFromMultiFactor.bind(this))
			)
			.subscribe();

		preAuth$
			.pipe(
				map((rsp) => rsp?.fastTrack),
				filter((val) => Boolean(val)),
				map(this.updateFromFastTrack.bind(this))
			)
			.subscribe();
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	/**
	 * Exposed the application Id as a property
	 *
	 * @readonly
	 * @type {string}
	 * @memberof loanAppService
	 */
	get loanApplicationId(): number {
		return this.getLoanApp()?.id;
	}

	get clientIdFromFirstApplicant(): string {
		return this.getLoanApp()?.applicants[0]?.clientId;
	}

	private updateFromNewApp(newApplication: INewApplicationResult): void {
		this.updateLoanApp({
			id: Number(newApplication.loanApplicationId)
		});
	}

	private updateFromMultiFactor(multiFactor: IVerifyFactorResult): void {
		this.updateLoanApp({
			id: Number(multiFactor.applicationId)
		});
	}

	private updateFromFastTrack(fastTrack: IFastTrackData): void {
		this.updateLoanApp({
			id: Number(fastTrack.loanAppId)
		});
	}

	private retryNotifier(error: any, retryCount: number): Observable<any> {
		if (error.message !== 'empty loanApp') {
			throw error;
		}
		return timer(500);
	}

	/**
	 * Call the BE to get the latest loan application data.
	 * Create a dataCache and ObservableCache incase the function is called
	 * multiple times close together.
	 *
	 * @private
	 * @param {number} [loanId=null]
	 * @param {boolean} [force=false]
	 * @return {*}  {Observable<ILoanApplication>}
	 * @memberof loanAppService
	 */
	private updateLoanAppFromBE(
		loanId: number = null,
		force: boolean = false,
		showBusy: boolean = true
	): Observable<ILoanApplication> {
		if (!loanId && !this.loanApplicationId && !this.loanAppCache && !this.loanAppCachedObservable) {
			return of(null);
		}

		let observable: Observable<any>;
		if (this.loanAppCache && !force) {
			observable = of(this.loanAppCache);
		} else if (this.loanAppCachedObservable && !force) {
			observable = this.loanAppCachedObservable;
		} else {
			this.loanAppCachedObservable = this.mobileService
				.getLoanApplication(loanId ? loanId : this.loanApplicationId, showBusy)
				.pipe(
					map((loanApp) => {
						if (isEmpty(loanApp)) {
							console.error('empty app');
							throw new Error('empty loanApp');
						}
						return loanApp;
					}),
					retry({ count: 2, delay: this.retryNotifier }),
					tap((loanApp) => (this.loanAppCache = loanApp)),
					tap(this.updateLoanApp.bind(this)),
					share(),
					finalize(() => {
						this.loanAppCachedObservable = null;
						setTimeout(() => (this.loanAppCache = null), 500);
					})
				);
			observable = this.loanAppCachedObservable;
		}
		return observable;
	}

	/**
	 * Refreshes the loanApplication from the BE.  Data is save to the BehaviorSubject and notifications
	 * are sent to subscribers
	 *
	 * @returns {Observable<ILoanApplication>}
	 * @param {boolean} [force=false]
	 * @memberof loanAppService
	 */
	updateLoanApplication(
		loanId: number = null,
		force: boolean = false,
		showBusy: boolean = true
	): Observable<ILoanApplication> {
		return this.updateLoanAppFromBE(loanId || this.loanApplicationId, force, showBusy);
	}

	/**
	 * Poll loan app status until isStatus returns true.
	 *
	 * @param {(status: ApplicationStatusEnum) => boolean} isStatus
	 * @return {*}  {Observable<ILoanApplication>}
	 * @memberof LoanApplicationService
	 */
	pullApplicationStatus(
		isStatus: (status: ApplicationStatusEnum) => boolean,
		isProgress: (progress: number, time: number) => void,
		duration: number = 0
	): Observable<ILoanApplication> {
		if (!isFunction(isStatus)) {
			return of(this.getLoanApp());
		}

		const getAppStatus$ = this.mobileService.getLoanApplicationStatus(this.loanApplicationId);
		const filterValue = this.frequencyPoll / this.frequencyUpdate;
		return timer(0, this.frequencyUpdate).pipe(
			filter((r) => {
				const d = duration || this.duration;
				const progress = (r * this.frequencyUpdate) / d;
				const timeLeft = d - r * this.frequencyUpdate;
				isProgress(progress * 100, Math.round(timeLeft / 1000));
				return r % filterValue === 0;
			}),
			concatMap(() => getAppStatus$),
			first((loanApp) => isStatus(loanApp?.applicationStatus)),
			switchMap(() => this.updateLoanApplication()),
			timeout(duration || this.duration),
			catchError((error) => {
				return this.updateLoanApplication();
			}),
			finalize(() => isProgress(100, 0))
		);
	}

	/**
	 * Get last value without subscribing to the observable (synchronously).
	 *
	 * @returns {ILoanApplication}
	 * @memberof loanAppService
	 */
	getLoanApp(loanApp: ILoanApplication = null): ILoanApplication {
		return loanApp || this.loanApplicationSource.getValue();
	}

	private setLoanApp(loanApp: ILoanApplication): void {
		this.loanApplicationSource.next(loanApp);
		loanApp?.id
			? this.sessionStorageService.set(this.storageKey, loanApp.id)
			: this.sessionStorageService.remove(this.storageKey);
	}

	private updateLoanApp(loanApp: Partial<ILoanApplication>): void {
		const loan = this.getLoanApp();
		this.setLoanApp({ ...loan, ...loanApp });
	}

	////////////////////////////////////////////////////////
	///////////// BEGIN UTILITY FUNCTIONS///////////////////
	////////////////////////////////////////////////////////

	/**
	 * Get the current applicant information.
	 *
	 * @returns {(IApplicant | null)}
	 * @memberof loanAppService
	 */
	getCurrentApplicant(): IApplicant {
		const app = this.getLoanApp();
		return app?.applicants?.length ? app?.applicants[0] : ({} as IApplicant);
	}

	getCurrentApplicantUtils(): ApplicantUtils {
		return ApplicantUtils.fromLoanApp(this.getLoanApp());
	}

	getDisbursementUtils(): DisbursementUtils {
		return DisbursementUtils.fromLoanApp(this.getLoanApp());
	}

	getProductOfferDetailsUtils(): ProductOfferDetailsUtils {
		return ProductOfferDetailsUtils.fromLoanApp(this.getLoanApp());
	}

	/**
	 * Check if a loan has a co applicant
	 *
	 * @return {*}  {boolean}
	 * @memberof loanAppService
	 */
	hasCoApplicant(): boolean {
		const app = this.getLoanApp();
		return Boolean(app?.applicants.length > 1);
	}

	get applicationStatus(): ApplicationStatusEnum {
		return this.getLoanApp()?.applicationStatus;
	}

	isApplicationStatusPreApproved(): boolean {
		const app = this.getLoanApp();
		return ApplicationStatusEnum.preApproved === app?.applicationStatus;
	}

	isApplicationStatusApproved(): boolean {
		const app = this.getLoanApp();
		return ApplicationStatusEnum.approved === app?.applicationStatus;
	}

	isRachWithoutAchOptInEligible(): boolean {
		const app = this.getLoanApp();
		return app?.rachWithoutAchOptInEligible;
	}

	isApplicationStatusBounced(): boolean {
		const app = this.getLoanApp();
		return ApplicationStatusEnum.bounced === app?.applicationStatus;
	}

	isApplicationStatusCoAppOffered(): boolean {
		const app = this.getLoanApp();
		return ApplicationStatusEnum.coAppOffered === app?.applicationStatus;
	}

	isApplicationStatusStarted(): boolean {
		const app = this.getLoanApp();
		return ApplicationStatusEnum.started === app?.applicationStatus;
	}

	isApplicationStatusNotApproved(): boolean {
		const app = this.getLoanApp();
		return ApplicationStatusEnum.notApproved === app.applicationStatus;
	}

	/**
	 * Get list of editable sections.
	 *
	 * @return {*}  {string[]}
	 * @memberof LoanApplicationService
	 */
	getEditableSections(): string[] {
		const applicant = this.getCurrentApplicant();
		let editableSections = applicant?.editableSections;
		return editableSections ? editableSections.split(',') : [];
	}

	/**
	 * Returns the Bounce Reasons as an objects.
	 * Each 'EditableSections' is a key, the value is an array of reasons.
	 * Filter out any sections that are not editable.
	 *
	 * @return {*}  {IBounceReasons}
	 * @memberof LoanApplicationService
	 */
	public getFilteredBounceReasons(): IBounceReasons {
		const bounceReason = this.getBounceReasons();
		const bounceSection = this.getEditableSections();
		return Object.keys(bounceReason)?.reduce((acc, cur) => {
			if (bounceSection?.includes(cur)) {
				acc[cur] = bounceReason[cur];
			}
			return acc;
		}, {});
	}

	/**
	 * Returns the Bounce Reasons as an objects.
	 * Each 'EditableSections' is a key, the value is an array of reasons.
	 *
	 * @return {*}  {IBounceReasons}
	 * @memberof LoanApplicationService
	 */
	getBounceReasons(): IBounceReasons {
		const applicant = this.getCurrentApplicant();
		return applicant
			? chain(applicant?.bounceReasons)
					.groupBy('section')
					.mapValues(function (reasons) {
						return chain(reasons).map('reason').flatten().value();
					})
					.value()
			: {};
	}

	/**
	 *
	 *
	 * @return {*}  {IShowBounceAttention}
	 * @memberof LoanApplicationService
	 */
	getEditableSectionFlags(): IShowBounceAttention {
		const showSection: IShowBounceAttention = { count: 0, referencesOnly: false };
		const sections = this.getEditableSections();
		const reasons = this.getBounceReasons();

		forEach(EditableSectionsList, (val) => {
			const attnItemProp = EditableSectionMap[val.key];

			if (!sections.includes(val.text)) {
				return;
			}
			showSection[attnItemProp] = reasons[val.text];
			showSection.count++;
		});
		showSection.referencesOnly = sections.length == 1 && Boolean(showSection['showReferencesItem']);
		return showSection;
	}

	/**
	 * Checks if a step has been completed.
	 *
	 * @param {string} step
	 * @param {ILoanApplication} [loanApp=null]
	 * @param {boolean} [isLoanAppStep=false]
	 * @returns {boolean}
	 * @memberof loanAppService
	 */
	isStepComplete(step: ApplicantStepCompleteEnum, isLoanAppStep: boolean = false): boolean {
		return isLoanAppStep
			? this.isLoanAppStepComplete(step)
			: this.isApplicantStepComplete(step, this.getCurrentApplicant());
	}

	/**
	 * Is loan Application step completed.
	 *
	 * @param {string} step
	 * @returns {boolean}
	 * @memberof loanAppService
	 */
	isLoanAppStepComplete(step: ApplicantStepCompleteEnum): boolean {
		const app = this.getLoanApp();
		return app[`${step}ProcessComplete`];
	}

	/**
	 * Is debt step completed.
	 *
	 * @param {string} step
	 * @returns {boolean}
	 * @memberof loanAppService
	 */
	isDebtStepComplete(): boolean {
		const applicant = this.getCurrentApplicant();
		return AddressUtils.isCaliforniaPostalCode(+applicant.homePostalCode) && applicant.financeProcessComplete;
	}

	/**
	 * Is Applicant step completed.
	 *
	 * @param {string} step
	 * @param {IApplicant} [applicant=null]
	 * @returns {boolean}
	 * @memberof loanAppService
	 */
	isApplicantStepComplete(step: ApplicantStepCompleteEnum, applicant: IApplicant = null): boolean {
		const app = applicant || this.getCurrentApplicant();
		return app[`${step}ProcessComplete`];
	}

	/**
	 * Determine if the Loan Application was originated through a partner source.
	 *
	 * @return {*}  {boolean}
	 * @memberof loanAppService
	 */
	isPartnerApplication(): boolean {
		const app = this.getLoanApp();
		return app?.partnerId > 0;
	}

	isCreditRunSuccess(): boolean | null {
		const applicant = this.getCurrentApplicant();
		return has(applicant, 'creditRunSuccess') && applicant.creditRunSuccess !== null
			? applicant.creditRunSuccess
			: null;
	}

	/**
	 * Determine if the Loan Application is eligible to verify income with Plaid.
	 *
	 * @return {*}  {boolean}
	 * @memberof loanAppService
	 */
	isIncomeVerificationEligible(): boolean {
		const applicant = this.getCurrentApplicant();
		return Boolean(
			applicant?.verifiedMonthlyIncomeSource !== 'PLAID' &&
				applicant?.incomeVerificationEligible === true &&
				applicant?.incomeSourceOptionsOffered !== true
		);
	}

	public isAutoVerifiedIncome(): boolean {
		const applicant = this.getCurrentApplicant();
		if (applicant) {
			return (
				VerificationStatusEnum.autoVerified === applicant.incomeSourceSelected ||
				VerificationStatusEnum.verified === applicant.incomeSourceSelected
			);
		}
		return false;
	}

	public isBankAccountVerified(): boolean {
		const applicant = this.getCurrentApplicant();
		return (
			(applicant?.bankAccountVerificationService === 'PLAID' && applicant?.bankAccountVerificationAdded) ||
			this.isAutoVerifiedIncome()
		);
	}

	public isVerifiedBankAccountExists(): boolean {
		const applicant = this.getCurrentApplicant();
		return applicant?.bankAccountVerificationService === 'PLAID' && applicant?.bankAccountVerificationAdded;
	}

	isAutoVerified(): boolean {
		const applicant = this.getCurrentApplicant();
		if (!applicant?.requirements) {
			return false;
		}
		const autoVerifyList = [
			'identificationRequired',
			'incomeRequired',
			'residenceRequired',
			'bankAccountRequired',
			'vehiclePhotosRequired',
			'vehicleRegistrationRequired',
			'vehicleDriversLicenseRequired'
		];
		const req = applicant.requirements;
		return autoVerifyList.every((item) => req?.[item] === false);
	}

	isIdentificationRequired(): boolean {
		const applicant = this.getCurrentApplicant();
		return applicant?.requirements ? applicant.requirements.identificationRequired : true;
	}

	isGCP(): boolean {
		const app = this.getLoanApp();
		return app?.applicationType === ApplicationTypeEnum.gcp;
	}

	isEarlyRenewal(): boolean {
		const app = this.getLoanApp();
		return app?.applicationType === ApplicationTypeEnum.earlyRenewal;
	}

	isSPL(): boolean {
		const app = this.getLoanApp();
		return app?.productCategory === ProductCategoriesEnum.securedPersonalLoan;
	}

	isUPL(): boolean {
		const app = this.getLoanApp();
		return app?.productCategory === ProductCategoriesEnum.unsecuredPersonalLoan;
	}

	isPersonalLoan(): boolean {
		const app = this.getLoanApp();
		return app?.productCategory === ProductCategoriesEnum.personalLoan;
	}

	getIssuingOrganization(): string {
		const app = this.getLoanApp();
		return app?.issuingOrganization;
	}

	/**
	 * Get the CoApplicant information.
	 *
	 * @returns {(IApplicant | empty Object)}
	 * @memberof loanAppService
	 */
	getCoApplicant(): IApplicant {
		const app = this.getLoanApp();
		return app?.applicants?.length > 1 ? app?.applicants[1] : ({} as IApplicant);
	}

	isBtmEligible(): boolean {
		return this.getCurrentApplicantUtils().isBtmEligible();
	}

	isAssetRefreshed(): boolean {
		return this.getCurrentApplicantUtils().isPlaidAssetRefresh();
	}

	isBtmIncomeVerified(): boolean {
		return this.getCurrentApplicantUtils().isBtmEligible() && this.isAutoVerifiedIncome();
	}

	isBtmIncomePartialVerified(): boolean {
		const applicantUtils = this.getCurrentApplicantUtils();
		return applicantUtils.isBtmEligible() && applicantUtils.getIncomeSourceOptionsOffered();
	}

	isBtmBankConnected(): boolean {
		const applicantUtils = this.getCurrentApplicantUtils();
		return applicantUtils.isBtmEligible() && applicantUtils.isBankAccountConnectionSourcePlaid();
	}

	isGCPOrReturnApplicant(): boolean {
		return this.getCurrentApplicantUtils().isReturning() || this.isGCP();
	}

	isPrequal(loanApp: ILoanApplication = null): boolean {
		const app = loanApp || this.getLoanApp();
		return app.applicationFlow === ApplicationFlowEnum.fastTrackPrequal;
	}

	isFTR(loanApp: ILoanApplication = null): boolean {
		const app = loanApp || this.getLoanApp();
		return app.applicationFlow === ApplicationFlowEnum.fastTrackReturning;
	}
}
