import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { isEmpty } from 'lodash';
import { combineLatest, map, Observable, Subscription } from 'rxjs';
import {
	FileUploadService,
	FileUploadType,
	IFileUpload,
	IFileUploads
} from 'src/app/core/services/file-upload/file-upload.service';
import { FileStatusEnum } from 'src/app/core/services/mobile-api';
import { SessionStorageService } from 'src/app/core/services/storage/session-storage.service';
import { opRequired } from 'src/app/shared/decorators/required.decorator';

import AutoVerificationUtils, { frontBackList } from '../auto-verification-utils-original';
import { DocBadgeEnum } from '../auto-verification-original.component';

@Component({
	selector: 'op-document-section-original',
	templateUrl: './document-section-original.component.html',
	styleUrls: ['./document-section-original.component.scss']
})
export class DocumentSectionOriginalComponent implements OnInit, OnDestroy {
	@Input()
	@opRequired()
	id: string;

	@Input()
	icon: string;

	@Input()
	categoryType: FileUploadType;

	@Input()
	title: string;

	@Input()
	description: string;

	@Input()
	bounceAttention: boolean = false;

	@Input()
	badge: DocBadgeEnum;

	needsUpload: boolean;
	attachments: boolean;
	processing: boolean;
	needsAttention: boolean = false;

	files$: Observable<IFileUploads>;
	categoryFiles: IFileUpload[];
	subscription: Subscription = new Subscription();

	public fileUploadType: typeof FileUploadType = FileUploadType;
	public docBadgeEnum: typeof DocBadgeEnum = DocBadgeEnum;

	constructor(private fileUploadService: FileUploadService, private sessionStorageService: SessionStorageService) {
		this.files$ = this.fileUploadService.fileUploads$;
	}

	ngOnInit(): void {
		const fileObserver$ = this.files$.pipe(map((rsp) => rsp?.[this.categoryType]));
		const sessionObserver$ = this.sessionStorageService.select('documentSubmit');
		const observe$ = combineLatest([fileObserver$, sessionObserver$]);

		const sub = observe$.subscribe({
			next: ([files, submitAttempt]) => {
				this.categoryFiles = files?.filter((f) => this.fileUploadService.isFileUploadStarted(f));

				if (this.categoryType === FileUploadType.identification) {
					// Identification files that need front back will only ever show the last 2 files uploaded.
					// modify the categoryFiles array to only show the last 2 files uploaded.
					const frontBackFiles = frontBackList.reduce((acc, curr) => {
						const files = this.categoryFiles?.filter((f) => String(f.classification) === curr);
						return { ...acc, [curr]: files };
					}, {});
					const otherFiles = this.categoryFiles?.filter((f) => !frontBackList.includes(String(f.classification)));

					if (Object.keys(frontBackFiles).some((key) => frontBackFiles[key]?.length)) {
						this.categoryFiles = Object.keys(frontBackFiles).reduce((acc, curr) => {
							return [...acc, ...frontBackFiles[curr]?.slice(-2)];
						}, []);
						this.categoryFiles.push(...otherFiles);
					}
				}

				if (isEmpty(this.categoryFiles)) {
					this.attachments = false;
					this.needsUpload = submitAttempt && !this.attachments;
					this.needsAttention = this.bounceAttention;
					this.processing = false;
					return;
				}

				this.attachments = Boolean(this.categoryFiles?.length);
				this.needsUpload = submitAttempt && !this.attachments;

				const sorted = AutoVerificationUtils.modifyOCRAttention(this.categoryFiles);
				const isSomeInReview = sorted?.some((f) => f?.ocrInfo?.documentStatus === FileStatusEnum.review);
				const isIncome = this.categoryType === FileUploadType.income;
				const criteriaMet = isIncome ? AutoVerificationUtils.isIncomeBankStatementCriteriaMet(sorted) : true;
				this.needsAttention = isSomeInReview || !criteriaMet || this.bounceAttention;

				this.processing = this.categoryFiles?.some((f) => f?.ocrInfo?.documentStatus === FileStatusEnum.pending);
			}
		});
		this.subscription.add(sub);
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}
}
