<div class="document-thumbnails" *transloco="let t">
	<div class="file-title" *ngIf="files?.length">
		<ng-content></ng-content>
	</div>
	<div class="file-list-wrapper">
		<div
			class="file-list-item-wrapper"
			[ngClass]="'file-status-' + (file.status | lowercase)"
			*ngFor="let file of files; let i = index; let isLast = last; trackBy: trackBy"
		>
			<op-document-badge-original
				*ngIf="file.ocrInfo?.documentStatus === fileStatusEnum.review"
				class="attention op-mb-5"
				id="{{ id + '_needsAttention_' + i }}"
				icon="op-alert3"
				[small]="true"
			>
				<div *ngIf="file.ocrInfo?.documentStatus === fileStatusEnum.review">
					<div *ngFor="let msg of file?.ocrInfo?.messages; let isLast = last">
						{{ msg }}
					</div>
				</div>
			</op-document-badge-original>
			<op-document-badge-original
				*ngIf="file.ocrInfo?.documentStatus === fileStatusEnum.pending"
				class="processing op-mb-5"
				id="{{ id + '_processing_' + i }}"
				[small]="true"
			>
				{{ t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.processing') }}
			</op-document-badge-original>
			<div class="file-list-item">
				<div class="img-wrapper">
					<img
						*ngIf="file.thumbnail"
						ngClass="thumbnail-img"
						id="{{ id + '_image_' + i }}"
						alt=""
						[src]="file.thumbnail"
					/>
					<img
						*ngIf="!file.thumbnail"
						ngClass="default-img"
						id="{{ id + '_svg_' + i }}"
						alt=""
						src="/assets/images/icons/op-document.svg"
					/>
				</div>
				<div class="message-wrapper">
					<div class="file-name" id="{{ id + '_name_' + i }}" title="{{ file.name }}">
						{{ file.name }}
					</div>
				</div>
			</div>
			<hr *ngIf="!isLast" />
		</div>
	</div>
</div>
