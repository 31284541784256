import { Component, OnDestroy, OnInit } from '@angular/core';
import { isEmpty } from 'lodash';
import { Observable } from 'rxjs';
import { FileUploadService, FileUploadType, IFileUpload } from 'src/app/core/services/file-upload/file-upload.service';
import { LoanApplicationService } from 'src/app/core/services/loan-application/loan-application.service';
import { MetadataEnum } from 'src/app/core/services/metadata/metadata.model';
import { MetadataService } from 'src/app/core/services/metadata/metadata.service';
import { IMetadata } from 'src/app/core/services/mobile-api';
import { RoutingService } from 'src/app/core/services/routing/routing.service';
import { SessionStorageService } from 'src/app/core/services/storage/session-storage.service';

import {
	BaseCategoryOriginalComponent,
	ProofStatusEnum
} from '../base-category-original/base-category-original.component';
import {
	DocumentStatusEventEnum,
	IDocumentStatusEvent
} from '../document-status-original/document-status-original.component';

@Component({
	selector: 'op-proof-of-address-original',
	templateUrl: './proof-of-address-original.component.html',
	styleUrls: ['./proof-of-address-original.component.scss']
})
export class ProofOfAddressOriginalComponent extends BaseCategoryOriginalComponent implements OnInit, OnDestroy {
	readonly categoryType = FileUploadType.addresses;
	readonly storageKey = 'proofOfAddress';
	readonly metaClassification = MetadataEnum.ResidenceDocumentClassification;

	documentClassificationList$: Observable<IMetadata[]>;

	canCancel: boolean = true;
	submitDisabled: boolean = true;

	constructor(
		metadataService: MetadataService,
		routingService: RoutingService,
		fileUploadService: FileUploadService,
		sessionStorageService: SessionStorageService,
		loanAppService: LoanApplicationService
	) {
		super(fileUploadService, sessionStorageService, routingService, metadataService, loanAppService);
	}

	ngOnInit(): void {
		super.ngOnInit();

		this.subscription = this.files$.subscribe({
			next: (files) => {
				if (!isEmpty(files) && (this.isProofTypeSelection || this.isProofTypeEmpty) && !this.choosing) {
					this.proofState = ProofStatusEnum.status;
				}

				if (this.isProofTypeEmpty) {
					this.proofState = ProofStatusEnum.classificationSelection;
				}

				this.canCancel = this.fileUploadService.isAllFilesUploadNotStarted(
					this.filterClassificationFiles(files, this.selectedDocumentClassification)
				);

				if (!this.canCancel) {
					// after user uploads a document do not allow to go back to classification selection.
					this.clearStateHistory();
				}

				this.submitDisabled = this.checkIfSubmitDisabled(files, this.selectedDocumentClassification);
			}
		});
	}

	ngOnDestroy(): void {
		super.ngOnDestroy();
	}

	private checkIfSubmitDisabled(files: IFileUpload[], docClassification: IMetadata): boolean {
		const finished = this.fileUploadService.isSomeFilesUploadFinished(
			this.filterClassificationFiles(files, docClassification)
		);
		return isEmpty(files) || !finished;
	}

	saveDocumentClassification(docClassification: IMetadata): void {
		this.choosing = true;
		this.selectedDocumentClassification = docClassification;
		this.proofState = ProofStatusEnum.upload;
		this.canCancel = this.fileUploadService.isAllFilesUploadNotStarted(this.selectedClassificationFiles);
		this.submitDisabled = this.checkIfSubmitDisabled(
			this.selectedClassificationFiles,
			this.selectedDocumentClassification
		);
	}

	onContinue(): void {
		this.fileUploadService.removeErrorFiles(this.categoryType);
		this.routingService.back();
	}

	onCancel(): void {
		this.fileUploadService.removeErrorFiles(this.categoryType);
		this.routingService.back();
	}

	onDocStatus(statusEvent: IDocumentStatusEvent): void {
		switch (statusEvent.event) {
			case DocumentStatusEventEnum.uploadMore:
				this.saveDocumentClassification(statusEvent.documentClassification);
				this.proofState = ProofStatusEnum.upload;
				break;
			case DocumentStatusEventEnum.addMore:
				this.choosing = true;
				this.proofState = ProofStatusEnum.classificationSelection;
				break;
		}
	}
}
