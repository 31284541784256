import { Injectable } from '@angular/core';

import { EnvironmentService } from '../environment/environment.service';

@Injectable({
	providedIn: 'root'
})
export abstract class ApiUrlService {
	constructor(protected environmentService: EnvironmentService) {}

	get API_URL(): string {
		return `${this.environmentService.apiUrl}`;
	}
	get API_MOBILE_URL(): string {
		return `${this.API_URL}/mobile`;
	}
	get API_MOBILE_CCAS_V1_URL(): string {
		return `${this.API_URL}/ccas/v1`;
	}
	get API_MOBILE_CCAS_V1_SELF_SERVICE_URL(): string {
		return `${this.API_MOBILE_CCAS_V1_URL}/self_service`;
	}
	get API_MOBILE_CCAS_V1_ACCOUNTS_URL(): string {
		return `${this.API_MOBILE_CCAS_V1_URL}/accounts`;
	}
	get API_MOBILE_LOAN_APP_URL(): string {
		return `${this.API_MOBILE_URL}/loan_applications`;
	}
	get API_MOBILE_METADATA_URL(): string {
		return `${this.API_MOBILE_URL}/metadata`;
	}
	// API has a typo here.
	get API_MOBILE_SERVICIABLE_URL(): string {
		return `${this.API_MOBILE_URL}/serviciable`;
	}
	get API_MOBILE_SERVICEABLE_URL(): string {
		return `${this.API_MOBILE_URL}/serviceable`;
	}
	get API_MOBILE_V2_LOAN_APP_URL(): string {
		return `${this.API_MOBILE_URL}/v2/loan_applications`;
	}
	get API_MOBILE_V3_LOAN_APP_URL(): string {
		return `${this.API_MOBILE_URL}/v3/loan_applications`;
	}
	get API_MOBILE_V2_URL(): string {
		return `${this.API_MOBILE_URL}/v2`;
	}
	get API_MOBILE_V3_URL(): string {
		return `${this.API_MOBILE_URL}/v3`;
	}
	get API_MOBILE_DISBURSEMENT_LOAN_APP_URL(): string {
		return `${this.API_MOBILE_URL}/disbursement/loan_applications`;
	}
	get API_MOBILE_SERVICING_LOAN_APP_URL(): string {
		return `${this.API_MOBILE_URL}/servicing/loan_applications`;
	}
	get API_MOBILE_MULTI_PRODUCT_URL(): string {
		return `${this.API_MOBILE_URL}/multiproduct/loan_applications`;
	}
}
