import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
	FormBuilder,
	FormGroup,
	Validators,
	ValidatorFn,
	AsyncValidatorFn,
	AbstractControl,
	ValidationErrors
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TranslocoService } from '@ngneat/transloco';
import { isEqual, upperFirst } from 'lodash';
import { forkJoin, iif, Observable, of, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, finalize, switchMap, take, tap } from 'rxjs/operators';
import { ILoanApplication } from 'src/app/core/services/loan-application/loan-application.model';
import { LoanApplicationService } from 'src/app/core/services/loan-application/loan-application.service';
import { ProductOfferDetailsUtils } from 'src/app/core/services/loan-application/product-offer/product-offer-details/product-offer-details-utils';
import { AddressTypeEnum } from 'src/app/core/services/mobile-api/mobile-api.model';
import { MobileApiService } from 'src/app/core/services/mobile-api/mobile-api.service';
import {
	IVehicleMakesDataEntity,
	IVehicleMakesResult,
	IVehicleModelsDataEntity,
	IVehicleModelsResult,
	IVehicleTrimsDataEntity,
	IVehicleTrimsResult,
	IVinData
} from 'src/app/core/services/mobile-api/vehicle-api/vehicle-api.model';
import { VehicleApiService } from 'src/app/core/services/mobile-api/vehicle-api/vehicle-api.service';
import { RoutingPathsEnum, RoutingService } from 'src/app/core/services/routing/routing.service';
import { VehicleUtils } from 'src/app/core/utils/vehicle-utils';
import { MessageDialogComponent } from 'src/app/shared/components/dialogs/message-dialog/message-dialog.component';
import { numberOnlyValidator } from 'src/app/shared/validators/form-validators';

import { IVinInformation, VinSearchComponent } from './vin-search/vin-search.component';
import { TagDataService } from 'src/app/core/services/tag-data/tag-data.service';

@Component({
	selector: 'op-vehicle',
	templateUrl: './vehicle.component.html',
	styleUrls: ['./vehicle.component.scss']
})
export class VehicleComponent implements OnInit, OnDestroy {
	constructor(
		private formBuilder: FormBuilder,
		private dialog: MatDialog,
		private mobileService: MobileApiService,
		private vehicleApiService: VehicleApiService,
		private translocoService: TranslocoService,
		private loanAppService: LoanApplicationService,
		private routingService: RoutingService,
		private tagDataService: TagDataService
	) {
		this.createForm(this.formBuilder);
	}
	formGroup: FormGroup;
	private subscription = new Subscription();
	vehicleUtils = new VehicleUtils();

	readonly CONFIRMED_MILEAGE = 250000;
	readonly INELIGIBLE_VEHICLE_ERROR_MESSAGE = 'loanApplication.ineligible.vehicle';
	mileageConfirmed = false;

	vinInfo: any;

	years: number[];
	makes: IVehicleMakesDataEntity[];
	models: IVehicleModelsDataEntity[];
	trims: IVehicleTrimsDataEntity[];
	customerStateName: string;
	numOfDaysToRenewRegistration: number;
	hasMultipleValidProductOffers: boolean;
	productOfferDetailUtilities: ProductOfferDetailsUtils;
	enableVinSearch: boolean;

	inValidVehicleTrimIds: number[] = [];
	inValidVehicleModelIds: number[] = [];
	inValidVehicleMakeIds: number[] = [];

	@ViewChild(VinSearchComponent) vinSearchComponent: VinSearchComponent;

	ngOnInit(): void {
		const loanAppSub = this.loanAppService.loanApplication$.pipe(filter(Boolean)).subscribe({
			next: (loanApp: ILoanApplication) => {
				this.productOfferDetailUtilities = ProductOfferDetailsUtils.fromLoanApp(loanApp);
				this.hasMultipleValidProductOffers = this.productOfferDetailUtilities.hasMultipleValidProductOffers();
			}
		});
		this.subscription.add(loanAppSub);
		this.subscription.add(this.yearValueChangeEvent());
		this.subscription.add(this.makeValueChangeEvent());
		this.subscription.add(this.modelValueChangeEvent());
		this.subscription.add(this.trimValueChangeEvent());
		this.subscription.add(this.mileageValueChangeEvent());

		this.mobileService.getAddresses(this.loanAppService.loanApplicationId).subscribe({
			next: (addresses) => {
				const customerState = this.vehicleUtils.getCustomerState(addresses, AddressTypeEnum.home);
				this.customerStateName = this.vehicleUtils.getCustomerStateName(customerState);
				this.numOfDaysToRenewRegistration = this.vehicleUtils.getNumOfDaysToRenewRegistration(customerState);
			}
		});

		this.getVehicleYearsData();
		this.enableVinSearch = true;

		this.getIneligibleRules();
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	private getIneligibleRules(): void {
		this.vehicleApiService.getIneligibleVehicleRules().subscribe({
			next: (data) => {
				this.inValidVehicleMakeIds = data.inValidVehicleMakeIds;
				this.inValidVehicleModelIds = data.inValidVehicleModelIds;
				this.inValidVehicleTrimIds = data.inValidVehicleTrimIds;
			}
		});
	}

	markFieldAsUntouched(field): void {
		this.formGroup.get(field).disable();
		this.formGroup.get(field).markAsUntouched();
	}

	private mileageValueChangeEvent(): Subscription {
		return this.formGroup
			.get('odometer')
			.valueChanges.pipe(distinctUntilChanged(isEqual), filter(Boolean))
			.subscribe({
				next: (rsp) => {
					this.mileageConfirmed = false;
				}
			});
	}

	private trimValueChangeEvent(): Subscription {
		return this.formGroup
			.get('trimId')
			.valueChanges.pipe(
				distinctUntilChanged(isEqual),
				filter(Boolean),
				tap((trimId: number) => {
					if (this.formGroup.get('trimId').invalid) {
						// send validation failed event to tealium
						const trimStr = this.trims ? this.trims.find((item) => item.id === trimId)?.trimName : null;
						this.sendValidationFailedEvent('trimId', trimStr);
					}
				}),
				tap(() => this.eraseVin())
			)
			.subscribe({
				next: (rsp) => {
					this.formGroup.patchValue({ odometer: null });
					this.formGroup.get('odometer').markAsUntouched();
				}
			});
	}

	private modelValueChangeEvent(): Subscription {
		return this.formGroup
			.get('modelId')
			.valueChanges.pipe(
				distinctUntilChanged(isEqual),
				filter(Boolean),
				switchMap((modelId: number) => {
					if (this.formGroup.get('modelId').invalid) {
						// send validation failed event to tealium
						const modelStr = this.models ? this.models.find((item) => item.id === modelId).modelName : null;
						this.sendValidationFailedEvent('modelId', modelStr);

						this.trims = [];
						this.markFieldAsUntouched('trimId');
						return of(null);
					} else {
						this.trims = [];
						this.markFieldAsUntouched('trimId');
						return this.getVehicleTrims(this.yearValue, this.makeIdValue, modelId);
					}
				}),
				tap(() => this.eraseVin())
			)
			.subscribe({
				next: (rsp) => {
					this.formGroup.patchValue({ trimId: null, odometer: null });
					this.formGroup.get('odometer').markAsUntouched();
				}
			});
	}

	private makeValueChangeEvent(): Subscription {
		return this.formGroup
			.get('makeId')
			.valueChanges.pipe(
				distinctUntilChanged(isEqual),
				filter(Boolean),
				switchMap((makeId: number) => {
					if (this.formGroup.get('makeId').invalid) {
						// send validation failed event to tealium
						const makeStr = this.makes ? this.makes.find((item) => item.id === makeId).makeName : null;
						this.sendValidationFailedEvent('makeId', makeStr);

						this.models = [];
						this.trims = [];
						this.markFieldAsUntouched('modelId');
						this.markFieldAsUntouched('trimId');
						return of(null);
					} else {
						this.markFieldAsUntouched('modelId');
						return this.getVehicleModels(this.yearValue, makeId);
					}
				}),
				tap(() => this.eraseVin())
			)
			.subscribe({
				next: (rsp) => {
					this.trims = [];
					this.formGroup.patchValue({ modelId: null, trimId: null, odometer: null });
					this.formGroup.get('trimId').disable();
					this.formGroup.get('trimId').markAsUntouched();
					this.formGroup.get('odometer').markAsUntouched();
				}
			});
	}

	private yearValueChangeEvent(): Subscription {
		return this.formGroup
			.get('year')
			.valueChanges.pipe(
				distinctUntilChanged(isEqual),
				filter(Boolean),
				switchMap((year: number) => {
					if (this.formGroup.get('year').invalid) {
						// send validation failed event to tealium
						this.sendValidationFailedEvent('year', year);

						this.makes = [];
						this.models = [];
						this.trims = [];
						this.markFieldAsUntouched('makeId');
						this.markFieldAsUntouched('modelId');
						this.markFieldAsUntouched('trimId');
						return of(null);
					} else {
						this.markFieldAsUntouched('makeId');
						return this.getVehicleMakes(year);
					}
				}),
				tap(() => this.eraseVin())
			)
			.subscribe({
				next: (rsp) => {
					this.models = [];
					this.trims = [];
					this.formGroup.patchValue({ makeId: null, modelId: null, trimId: null, odometer: null });
					['modelId', 'trimId'].forEach((item) => {
						this.formGroup.get(item).disable();
						this.formGroup.get(item).markAsUntouched();
					});
					this.formGroup.get('odometer').markAsUntouched();
				}
			});
	}

	private eraseVin(): void {
		this.vinSearchComponent.eraseVin();
		this.vinInfo = null;
	}

	setControlRequired(control: string, value: boolean): void {
		if (value) {
			if (control === 'makeId') {
				this.formGroup.get(control).setValidators([Validators.required, this.invalidMakeIdValidator()]);
			} else if (control === 'modelId') {
				this.formGroup.get(control).setValidators([Validators.required, this.invalidModelIdValidator()]);
			} else if (control === 'trimId') {
				this.formGroup.get(control).setValidators([Validators.required, this.invalidTrimIdValidator()]);
			}
			this.formGroup.get(control).updateValueAndValidity();
		}
	}

	createForm(fb: FormBuilder): void {
		this.formGroup = fb.group({
			year: [{ disabled: true, value: '' }, [Validators.required, this.invalidYearValidator()]],
			makeId: [{ disabled: true, value: '' }, [Validators.required, this.invalidMakeIdValidator()]],
			modelId: [{ disabled: true, value: '' }, [Validators.required, this.invalidModelIdValidator()]],
			trimId: [{ disabled: true, value: '' }, [Validators.required, this.invalidTrimIdValidator()]],
			odometer: [
				{ disabled: true, value: '' },
				[Validators.required, numberOnlyValidator(), this.maxMileageValidator()]
			]
		});
	}

	maxMileageValidator(): ValidatorFn {
		return (control: AbstractControl): { [key: string]: any } | null => {
			const val = control.value;
			if (val >= this.CONFIRMED_MILEAGE) {
				return { unsupportedMileage: true };
			}
			return null;
		};
	}

	invalidMakeIdValidator(): ValidatorFn {
		return (control: AbstractControl): { [key: string]: any } | null => {
			if (control?.value && this.inValidVehicleMakeIds.includes(control?.value)) {
				return { unsupportedMake: true };
			}
			return null;
		};
	}

	invalidModelIdValidator(): ValidatorFn {
		return (control: AbstractControl): { [key: string]: any } | null => {
			if (control?.value && this.inValidVehicleModelIds.includes(control?.value)) {
				return { unsupportedModel: true };
			}
			return null;
		};
	}

	invalidTrimIdValidator(): ValidatorFn {
		return (control: AbstractControl): { [key: string]: any } | null => {
			if (control?.value && this.inValidVehicleTrimIds.includes(control?.value)) {
				return { unsupportedTrim: true };
			}
			return null;
		};
	}

	invalidYearValidator(): ValidatorFn {
		return (control: AbstractControl): { [key: string]: any } | null => {
			const val = control.value;
			const currYear = new Date().getFullYear();
			if (val && currYear - val > 25) {
				return { unsupportedYear: true };
			}
			return null;
		};
	}

	disableForm(): void {
		this.formGroup.get('year').disable();
		this.formGroup.get('makeId').disable();
		this.formGroup.get('modelId').disable();
		this.formGroup.get('trimId').disable();
		this.formGroup.get('odometer').disable();

		this.years = null;
		this.makes = null;
		this.models = null;
		this.trims = null;
	}

	get yearValue(): number {
		return this.formGroup.get('year').value;
	}
	get makeIdValue(): number {
		return this.formGroup.get('makeId').value;
	}
	get modelIdValue(): number {
		return this.formGroup.get('modelId').value;
	}

	getVehicleMakes(year: number): Observable<IVehicleMakesResult> {
		return this.vehicleApiService.getVehicleMakes(year).pipe(
			tap((rsp) => {
				this.makes = rsp.data;
				rsp.data.length && this.formGroup.get('makeId').enable();
				this.setControlRequired('makeId', Boolean(rsp.data.length));
			})
		);
	}
	getVehicleModels(year: number, makeId: number): Observable<IVehicleModelsResult> {
		return this.vehicleApiService.getVehicleModels(year, makeId).pipe(
			tap((rsp) => {
				this.models = rsp.data;
				rsp.data.length && this.formGroup.get('modelId').enable();
				this.setControlRequired('modelId', Boolean(rsp.data.length));
			})
		);
	}
	getVehicleTrims(year: number, makeId: number, modelId: number): Observable<IVehicleTrimsResult> {
		return this.vehicleApiService.getVehicleTrims(year, makeId, modelId).pipe(
			tap((rsp) => {
				this.trims = rsp.data;
				this.formGroup.get('trimId').enable();
				this.formGroup.get('odometer').enable();
				this.setControlRequired('trimId', true);
				if (rsp.data.length === 1) {
					this.formGroup.get('trimId').setValue(rsp.data[0]);
				}
			})
		);
	}

	getVehicleInformation(year, makeId, modelId, trims): void {
		const fields = ['year', 'makeId', 'modelId', 'trimId'];
		forkJoin({
			makes: this.getVehicleMakes(year),
			models: this.getVehicleModels(year, makeId),
			trims: this.getVehicleTrims(year, makeId, modelId)
		}).subscribe({
			next: (rsp) => {
				const trimId = trims?.[0].id;
				this.formGroup.patchValue({ year, makeId, modelId, trimId }, { emitEvent: false });
				fields.forEach((val) => this.formGroup.get(val).markAsTouched());
				// clear mileage field
				this.formGroup.get('odometer').setValue(null);
				this.formGroup.get('odometer').markAsUntouched();
			}
		});
	}

	vinSearchResults(vinInfo: IVinInformation): void {
		this.vinInfo = vinInfo;
		const { vinResults } = vinInfo;
		const { year, makeId, modelId, trims } = vinResults.data;
		this.getVehicleInformation(year, makeId, modelId, trims);
	}

	onSubmit(post: any): void {
		const vinData = this.vinInfo?.vinResults.data;
		const isVinSame = this.isSameVin(vinData);

		const vehicle = this.formGroup.value;
		if (isVinSame) {
			vehicle.vin = this.vinInfo.vin;
		}
		vehicle.makeStr = this.makes ? this.makes.find((item) => item.id === vehicle.makeId).makeName : null;
		vehicle.modelStr = this.models ? this.models.find((item) => item.id === vehicle.modelId).modelName : null;
		vehicle.trimStr = this.trims ? this.trims.find((item) => item.id === vehicle.trimId)?.trimName : null;

		const setVehicleFinal$ = this.vehicleApiService.setVehicle(
			Object.assign({}, vehicle, { isFinalVehicle: true }),
			this.loanAppService.loanApplicationId
		);

		const cancelOdometer$ = of(false).pipe(
			tap((rsp) => {
				this.mileageConfirmed = false;
				this.formGroup.get('odometer').setValue(null);
			})
		);

		this.checkMileage(vehicle.odometer)
			.pipe(switchMap((rsp) => iif(() => Boolean(rsp), setVehicleFinal$, cancelOdometer$)))
			.subscribe({
				next: (rsp) => {
					if (!Boolean(rsp)) {
						return;
					} else {
						this.routingService.route(RoutingPathsEnum.vehicleEligibility);
					}
				},
				error: (err) => {
					if (!this.hasMultipleValidProductOffers && err?.error[0]?.msg == 'loanApplication.ineligible.vehicle') {
						this.routingService.route(RoutingPathsEnum.status);
					} else {
						this.routingService.route(RoutingPathsEnum.vehicleEligibility);
					}
				}
			});
	}

	isIneligible(error): boolean {
		return error?.msg === this.INELIGIBLE_VEHICLE_ERROR_MESSAGE;
	}

	showEligibilityPrompt(err): Observable<boolean> {
		if (this.isIneligible(err)) {
			const message = `${this.translocoService.translate('VEHICLE_ELIGIBILITY.INELIGIBLE.PROMPT.message')}
				<p></p>
				${this.translocoService.translate('VEHICLE_ELIGIBILITY.INELIGIBLE.PROMPT.subMessage')}`;
			const data = {
				title: this.translocoService.translate('VEHICLE_ELIGIBILITY.INELIGIBLE.PROMPT.title'),
				message,
				cancelText: upperFirst(this.translocoService.translate('GLOBAL.no')),
				confirmText: upperFirst(this.translocoService.translate('GLOBAL.yes'))
			};

			const dialogRef = this.dialog.open(MessageDialogComponent, { data });
			return dialogRef.afterClosed().pipe(take(1));
		} else {
			return of(null);
		}
	}

	checkMileage(odometer: number): Observable<boolean> {
		if (odometer >= this.CONFIRMED_MILEAGE && !this.mileageConfirmed) {
			const data = {
				title: this.translocoService.translate('VEHICLE.CONFIRM.title'),
				message: this.translocoService.translate('VEHICLE.CONFIRM.message'),
				cancelText: upperFirst(this.translocoService.translate('GLOBAL.no')),
				confirmText: upperFirst(this.translocoService.translate('GLOBAL.yes'))
			};

			const dialogRef = this.dialog.open(MessageDialogComponent, { data });
			return dialogRef.afterClosed().pipe(take(1));
		} else {
			return of(true);
		}
	}

	returnToOffers = function () {
		this.routingService.route(RoutingPathsEnum.offerStatus);
	};

	isSameVin(vinData: IVinData): boolean {
		const vinDataFromTrimId = vinData?.trims?.find(Boolean)?.id;
		const isVehicleMakeAndModelMatch =
			vinData &&
			['year', 'makeId', 'modelId'].every((item) => {
				return vinData[item] === this.formGroup.get(item).value;
			});
		if (vinData && vinDataFromTrimId === this.formGroup.get('trimId')?.value && isVehicleMakeAndModelMatch) {
			return true;
		}
		return false;
	}

	private setVehicleQuestionValidator(): void {
		this.formGroup.get('vehicleOwnershipStatus').clearValidators();
		this.formGroup.get('vehicleOwnershipStatus').setValidators(Validators.required);
		this.formGroup.get('vehicleOwnershipStatus').updateValueAndValidity();
	}

	onChangeVehicleOwnershipStatus(event): void {
		const fields = ['year', 'makeId', 'modelId', 'trimId'];
		this.formGroup.reset();
		this.getVehicleYearsData();
		this.enableVinSearch = true;
		this.setRequiredValidator(fields);
		this.setOdometerValidator();
	}

	private setOdometerValidator(): void {
		if (this.formGroup) {
			this.formGroup.get('odometer').setValidators([Validators.required, numberOnlyValidator()]);
			this.formGroup.get('odometer').updateValueAndValidity();
		}
	}

	private clearValidator(fields: string[]): void {
		if (this.formGroup) {
			fields.forEach((field) => {
				this.formGroup.get(field).clearValidators();
				this.formGroup.get(field).updateValueAndValidity();
			});
		}
	}

	private setRequiredValidator(fields: string[]): void {
		if (this.formGroup) {
			fields.forEach((field) => {
				this.formGroup.get(field).setValidators(Validators.required);
				this.formGroup.get(field).updateValueAndValidity();
			});
		}
	}

	private getVehicleYearsData(): void {
		this.vehicleApiService.getVehicleYears().subscribe({
			next: (years) => {
				this.years = years.data;
				years.data.length && this.formGroup.get('year').enable();
				years.data.length && this.formGroup.get('year');
			}
		});
	}

	returnOfferStatusRoute(): any {
		return RoutingPathsEnum.offerStatus;
	}

	private sendValidationFailedEvent(fieldName: string, value: string | number): void {
		this.tagDataService.link(
			{},
			{
				tealium_event: `${fieldName}-validation failed`,
				page_location: '/' + this.routingService.getCurrentRoute(),
				form_field: fieldName,
				event_label: value,
				event_category: 'Form',
				event_action: 'field_complete'
			}
		);
	}

	onMileageBlurEvent(): void {
		if (this.formGroup.get('odometer').invalid) {
			this.sendValidationFailedEvent('odometer', this.formGroup.get('odometer').value);
		}
	}
}
