import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { catchError, forkJoin, map, Observable, of } from 'rxjs';
import { LoanApplicationService } from 'src/app/core/services/loan-application/loan-application.service';
import { ProductOfferDetailsUtils } from 'src/app/core/services/loan-application/product-offer/product-offer-details/product-offer-details-utils';
import { ProductCategoriesEnum } from 'src/app/core/services/loan-application/product-offer/product/product.model';
import {
	EligibilityExperienceEnum,
	FeatureNameEnum,
	IEligibilityResult,
	MobileApiService,
	ProductCategorySelectionEnum
} from 'src/app/core/services/mobile-api';
import { SessionStorageService } from 'src/app/core/services/storage/session-storage.service';

export enum OcrEligibilityEnum {
	newExperience = 'newExperience',
	newExperienceDelay = 'newExperienceDelay',
	originalExperience = 'originalExperience'
}

@Injectable({
	providedIn: 'root'
})
export class AutoEligibilityResolver implements Resolve<OcrEligibilityEnum> {
	constructor(
		private mobileApiService: MobileApiService,
		private loanApplicationService: LoanApplicationService,
		private sessionStorageService: SessionStorageService
	) {}
	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<OcrEligibilityEnum> {
		const hasSpl = ProductOfferDetailsUtils.fromLoanApp(
			this.loanApplicationService.getLoanApp()
		).hasSecuredPersonalLoan();
		const productCategorySelection = this.sessionStorageService.get('productCategorySelection');

		// assuming spl when productCategorySelection is not set and they have spl option.
		const isSPL =
			productCategorySelection === ProductCategoriesEnum.securedPersonalLoan ||
			(hasSpl && productCategorySelection === ProductCategoriesEnum.personalLoan);

		const categorySelection = isSPL
			? ProductCategorySelectionEnum.securedPersonalLoan
			: ProductCategorySelectionEnum.unsecuredPersonalLoan;
		const loanId = this.loanApplicationService.loanApplicationId;
		const applicantIndex = this.loanApplicationService.getCurrentApplicant()?.applicantIndex;

		const newExperience$ = this.mobileApiService.setEligibility(
			FeatureNameEnum.newUploadExperience,
			categorySelection,
			loanId,
			applicantIndex
		);

		return newExperience$.pipe(
			map((rsp: IEligibilityResult) => {
				if (rsp?.eligible && rsp?.experience === EligibilityExperienceEnum.v2) {
					return OcrEligibilityEnum.newExperience;
				} else if (rsp?.eligible) {
					return OcrEligibilityEnum.newExperienceDelay;
				} else {
					return OcrEligibilityEnum.originalExperience;
				}
			}),
			catchError(() => of(OcrEligibilityEnum.originalExperience))
		);
	}
}
