<div class="referral" *transloco="let t">
	<p></p>
	<op-back-link class="op-mt-20" id="preQualBck" [green]="false" [opRoute]="'loanCompleted'">
		{{ t('REFERRAL.back') }}
	</op-back-link>

	<div class="referral-section">
		<img class="referral-offer-image" alt="referral-offer" src="assets/images/referral-offer.svg" role="presentation" />
		<img
			class="referral-offer-image-sm"
			alt="referral-offer"
			src="assets/images/referral-offer-sm.svg"
			role="presentation"
		/>
	</div>

	<h4 class="referral-title">
		{{ t('REFERRAL.SUMMER.refer_a_friend_to', { amount: referralBonusAmount }) }}
	</h4>
	<op-content-container>
		<div
			class="referral-section"
			[innerHTML]="t('REFERRAL.SUMMER.INFO_TEXT.top_content', { amount: referralBonusAmount })"
		></div>
		<div class="referral-section" [innerHTML]="t('REFERRAL.SUMMER.INFO_TEXT.bottom_content')"></div>

		<div class="center referral-section">
			<button
				mat-flat-button
				color="primary"
				id="copyReferralCodeBtn"
				(click)="copyReferralCode()"
				[disabled]="!referralCode"
			>
				{{ t('REFERRAL.SUMMER.BUTTONS.copy_code', { code: referralCode }) }}
			</button>
		</div>

		<div class="center referral-section" *ngIf="isMobile">
			<button mat-flat-button color="primary" id="shareViaSocialBtn" (click)="shareViaSocial()">
				<mat-icon svgIcon="op-share"></mat-icon>
				{{ t('REFERRAL.SUMMER.BUTTONS.refer_a_friend') }}
			</button>
		</div>

		<div class="center referral-section" *ngIf="!isMobile">
			<button mat-flat-button color="primary" id="shareViaEmailBtn" #email (click)="shareViaEmail()">
				<mat-icon svgIcon="op-email"></mat-icon>
				{{ t('REFERRAL.SUMMER.BUTTONS.email_referral') }}
			</button>
		</div>
	</op-content-container>
</div>
