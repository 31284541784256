import { Component, OnDestroy, OnInit } from '@angular/core';
import { isEmpty } from 'lodash';
import { FileUploadService, FileUploadType, IFileUpload } from 'src/app/core/services/file-upload/file-upload.service';
import { LoanApplicationService } from 'src/app/core/services/loan-application/loan-application.service';
import { MetadataEnum } from 'src/app/core/services/metadata/metadata.model';
import { MetadataService } from 'src/app/core/services/metadata/metadata.service';
import { IMetadata } from 'src/app/core/services/mobile-api';
import { RoutingService } from 'src/app/core/services/routing/routing.service';
import { SessionStorageService } from 'src/app/core/services/storage/session-storage.service';

import { BaseCategoryComponent, ProofStatusEnum } from '../base-category/base-category.component';
import { DocumentStatusEventEnum, IDocumentStatusEvent } from '../document-status/document-status.component';
import { AutoVerificationService } from '../auto-verification.service';

@Component({
	selector: 'op-proof-of-bank-account',
	templateUrl: './proof-of-bank-account.component.html',
	styleUrls: ['./proof-of-bank-account.component.scss']
})
export class ProofOfBankAccountComponent extends BaseCategoryComponent implements OnInit, OnDestroy {
	readonly categoryType = FileUploadType.bank;
	readonly storageKey = 'proofOfBankAccount';
	readonly metaClassification = MetadataEnum.BankAccountDocumentClassification;

	canCancel: boolean = true;
	submitDisabled: boolean = true;

	constructor(
		metadataService: MetadataService,
		routingService: RoutingService,
		fileUploadService: FileUploadService,
		sessionStorageService: SessionStorageService,
		loanAppService: LoanApplicationService,
		autoVerificationService: AutoVerificationService
	) {
		super(
			fileUploadService,
			sessionStorageService,
			routingService,
			metadataService,
			loanAppService,
			autoVerificationService
		);
	}

	ngOnInit(): void {
		super.ngOnInit();

		this.subscription = this.files$.subscribe({
			next: (files: IFileUpload[]) => {
				if (!isEmpty(files) && (this.isProofTypeSelection || this.isProofTypeEmpty) && !this.choosing) {
					this.proofState = ProofStatusEnum.status;
				}

				if (this.isProofTypeEmpty) {
					this.proofState = ProofStatusEnum.classificationSelection;
				}

				this.canCancel = this.fileUploadService.isAllFilesUploadNotStarted(
					this.filterClassificationFiles(files, this.selectedDocumentClassification)
				);

				this.submitDisabled = this.checkIfSubmitDisabled(files, this.selectedDocumentClassification);
			}
		});
	}

	ngOnDestroy(): void {
		super.ngOnDestroy();
	}

	private checkIfSubmitDisabled(files: IFileUpload[], docClassification: IMetadata): boolean {
		const finished = this.fileUploadService.isSomeFilesUploadFinished(
			this.filterClassificationFiles(files, docClassification)
		);
		return isEmpty(files) || !finished;
	}

	saveDocumentClassification(docClassification: IMetadata): void {
		this.choosing = true;
		this.selectedDocumentClassification = docClassification;
		this.proofState = ProofStatusEnum.upload;
		this.canCancel = this.fileUploadService.isAllFilesUploadNotStarted(this.selectedClassificationFiles);
		this.submitDisabled = this.checkIfSubmitDisabled(
			this.selectedClassificationFiles,
			this.selectedDocumentClassification
		);
	}

	onContinue(): void {
		this.fileUploadService.removeErrorFiles(this.categoryType);
		this.routingService.back();
	}

	onCancel(): void {
		this.fileUploadService.removeErrorFiles(this.categoryType);
		this.routingService.back();
	}

	onDocStatus(statusEvent: IDocumentStatusEvent): void {
		switch (statusEvent.event) {
			case DocumentStatusEventEnum.uploadMore:
				this.saveDocumentClassification(statusEvent.documentClassification);
				this.proofState = ProofStatusEnum.upload;
				break;
			case DocumentStatusEventEnum.addMore:
				this.choosing = true;
				this.proofState = ProofStatusEnum.classificationSelection;
				break;
		}
	}
}
