<div class="sms-alert" *transloco="let t">
	<div class="alert-container">
		<div class="sms-info op-left">
			<div class="sms-icon">
				<img alt="" [src]="'/assets/images/mobile-sms.svg'" />
			</div>
			<div class="sms-content">
				<div class="op-body-2-bold-font">{{ t('PERSONAL_INFO.stayConnected') }}</div>
				<div class="op-body-2-font op-gray">{{ t('PERSONAL_INFO.receiveSms') }}</div>
			</div>
		</div>
		<hr />
		<div class="sms-notification op-left">
			<div>
				<div class="op-body-2-bold-font">{{ t('PERSONAL_INFO.proceedWithAlerts') }}</div>
				<div class="op-body-2-font op-gray">{{ t('PERSONAL_INFO.standardRates') }}</div>
			</div>
			<div>
				<ng-content></ng-content>
			</div>
		</div>
	</div>
</div>
