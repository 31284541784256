<div class="loan-completed" *transloco="let t">
	<op-masthead
		*ngIf="initialized"
		[customClass]="isCreditCardProductEligible ? 'head' : ''"
		[title]="t('LOAN_COMPLETED.loanIsComplete', { name: user?.firstName })"
	>
	</op-masthead>

	<op-content-container *ngIf="initialized" class="content">
		<div class="op-center op-mb-16" *ngIf="!isCreditCardProductEligible">
			<img src="/assets/images/loan-complete.svg" alt="sign document" />
		</div>

		<div class="op-mb-8 op-header-2-font" [innerHTML]="t('LOAN_COMPLETED.fundsAvailableIn')"></div>
		<p
			id="receiveFundsAch"
			*ngIf="loanApplication?.disbursement?.disbursementType === 'ACH'"
			[innerHTML]="t('LOAN_COMPLETED.receiveFunds')"
		></p>
		<p
			id="receiveFundsCheck"
			*ngIf="loanApplication?.disbursement?.disbursementType === 'CHECK' && issuingOrganization"
			[innerHTML]="t('LOAN_COMPLETED.receiveFundsCheck.' + issuingOrganization)"
		></p>
		<p id="receiveFundsDebit" *ngIf="hasDebit && issuingOrganization">{{ t(fundingInfo) }}</p>

		<div class="op-mtb-20">
			<button mat-flat-button color="primary-small" id="downloadLoanDocBtn" (click)="downloadLoanDocuments()">
				{{ t('RETURNING.downloadLoanDocs') }}
			</button>
		</div>

		<div class="op-mb-40 op-body-1-font">
			<p [innerHTML]="t('LOAN_COMPLETED.callToRequestLoanDoc')"></p>
		</div>
		<div class="op-mt-20 op-mb-40 creditCardOfferWidget" id="creditCardOfferWidget" *ngIf="isCreditCardProductEligible">
			<op-credit-card-offer
				id="creditCardOffer"
				[redirectCode]="redirectCode"
				[eligibleOfferDays]="eligibleOfferDays"
				[showOfferDays]="showOfferDays"
				[multiProductSegmentNode]="multiProductSegmentNode"
				[multiProductPricingCode]="multiProductPricingCode"
				[productCategory]="productCategory"
				[creditCardAdditionalData]="creditCardAdditionalData"
				(OnClickTriggerAction)="trackEligibilityEvents($event)"
			></op-credit-card-offer>
		</div>
		<div class="op-mt-50 op-mb-40" id="digitOfferWidget" *ngIf="digitUrl">
			<op-digit-offer-for-approved
				id="digitApprovedOffer"
				[digitUrl]="digitUrl"
				(OnClickTriggerAction)="trackEligibilityEvents($event)"
				[isMobile]="isMobile"
				[showCreateButton]="showCreateButton"
				(mobileService)="goToMobileService()"
				(createAccount)="goToCreateAccount()"
			></op-digit-offer-for-approved>
		</div>

		<op-how-to-manage-loan
			id="howToManageLoan"
			*ngIf="!digitUrl"
			[isMobile]="isMobile"
			[showCreateButton]="showCreateButton"
			(mobileService)="goToMobileService()"
			(createAccount)="goToCreateAccount()"
		>
		</op-how-to-manage-loan>

		<div class="op-mtb-40 referral-card">
			<div class="referral-content">
				<div class="content">
					<p class="op-header-3-font">{{ t('LOAN_COMPLETED.referSummerKickstart') }}</p>
					<div class="refer-bonus op-gray op-body-2-font">{{ t('LOAN_COMPLETED.referSummerBonus') }}</div>
				</div>
				<div class="icon">
					<img alt="referral-icon" src="assets/images/referral-icon.svg" role="presentation" />
				</div>
			</div>
			<div class="get-your-code">
				<button mat-flat-button color="primary" id="gotoReferralBtn" (click)="goToReferral()">
					{{ t('LOAN_COMPLETED.getCode') }}
				</button>
			</div>
		</div>

		<div class="op-mt-20"></div>
		<op-unidos-us-information *ngIf="issuingOrganizationIsOportun && digitUrl === null"></op-unidos-us-information>
		<div class="op-pt-40"></div>
	</op-content-container>
</div>
