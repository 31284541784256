<div class="proof-of-selfie" *transloco="let t">
	<op-back-link class="op-mt-20" [green]="false" id="{{ getPageId() }}Back" (back)="onBack()">{{
		t('ACCESSIBILITY.back')
	}}</op-back-link>
	<op-masthead [title]="t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.proofOfSelfie.selfieTitle')"> </op-masthead>
	<op-content-container class="content">
		<div *ngIf="proofState === proofStatusEnum.upload">
			<div>
				<div class="op-dark-grey">
					<ul>
						<li
							*ngFor="
								let value of t(
									'DOCUMENT_SUBMIT.AUTOMATIC_PROOF.proofOfSelfie.classificationInstructions.' +
										stateStorage?.selectedDocumentClassification?.key
								)
							"
							[innerHTML]="value"
						></li>
					</ul>
				</div>
			</div>

			<op-document-notification-original *ngIf="bounceReasons?.length" id="selfieBounceReasons" class="op-mtb-20">
				<ul>
					<li *ngFor="let bounce of bounceReasons">{{ bounce }}</li>
				</ul>
			</op-document-notification-original>

			<op-document-upload-original
				id="selfieUpload"
				[categoryType]="categoryType"
				[documentClassification]="stateStorage?.selectedDocumentClassification"
				[selectedClassificationFiles]="selectedClassificationFiles"
				[showSingleFile]="true"
				[delayUpload]="true"
				[fileSide]="'front'"
				#docUpload
			>
				<div uploadDocument>
					<div class="op-icon-align-body-1 upload-document-wrapper">
						<div></div>
						<mat-icon class="op-dark-green" svgIcon="op-photoCamera"></mat-icon>
						<div class="op-body-1-bold-font op-dark-green upload-document">
							{{ t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.proofOfSelfie.takeSelfie') }}
						</div>
					</div>
					<div class="op-dark-grey op-mt-8" [innerHTML]="t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.fileInstructions')"></div>
				</div>

				<div reUploadDocument>
					<div class="op-icon-align-body-1 upload-document-wrapper">
						<div></div>
						<mat-icon class="op-dark-green" svgIcon="op-photoCamera"></mat-icon>
						<div class="op-body-1-bold-font op-dark-green upload-document">
							{{ t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.proofOfSelfie.reTakeSelfie') }}
						</div>
					</div>
					<div
						class="op-body-1-font op-dark-grey op-mt-8"
						[innerHTML]="t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.fileInstructions')"
					></div>
				</div>
			</op-document-upload-original>

			<div class="op-mt-30">
				<button
					mat-flat-button
					color="primary"
					name="selfieContinueBtn"
					id="selfieContinueBtn"
					[disabled]="submitDisabled"
					type="submit"
					(click)="onContinue()"
					[opTagClick]="getPageId() + 'Continue'"
				>
					{{ t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.saveAndContinue') }}
				</button>
			</div>

			<div class="op-mt-20" *ngIf="canCancel">
				<button
					mat-flat-button
					color="secondary"
					name="selfieCancelBtn"
					id="selfieCancelBtn"
					(click)="onCancel()"
					[opTagClick]="getPageId() + 'Cancel'"
				>
					{{ t('GLOBAL.cancel') }}
				</button>
			</div>
		</div>
	</op-content-container>
</div>
