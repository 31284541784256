import { Injectable, OnDestroy } from '@angular/core';
import { TranslateParams, TranslocoService, HashMap } from '@ngneat/transloco';
import { isNil } from 'lodash';
import { combineLatest, of, Subscription } from 'rxjs';
import { delay, distinctUntilChanged } from 'rxjs/operators';

import { EnvironmentService } from '../environment/environment.service';
import { LocalStorageService } from '../storage/local-storage.service';
import { SessionStorageService } from '../storage/session-storage.service';

export enum LanguageEnum {
	english = 'en',
	spanish = 'es'
}

@Injectable({
	providedIn: 'root'
})
export class LanguageService implements OnDestroy {
	public readonly langChanges$ = this.translocoService.langChanges$;
	private readonly storageKey = 'lang';
	private readonly lsStorageKey = 'ls.lang';
	private readonly subscription = new Subscription();

	constructor(
		private translocoService: TranslocoService,
		private localStorageService: LocalStorageService,
		private sessionStorageService: SessionStorageService,
		private environmentService: EnvironmentService
	) {
		const translocoSub = this.translocoService.langChanges$
			.pipe(distinctUntilChanged())
			.subscribe((lang) => this.updateStorage(lang));
		this.subscription.add(translocoSub);

		const local$ = this.localStorageService.select(this.lsStorageKey, false).pipe(distinctUntilChanged());
		const session$ = this.sessionStorageService.select(this.storageKey).pipe(distinctUntilChanged());

		// watch if lang is removed from storage and restore it.
		const langSub = combineLatest([local$, session$]).subscribe({
			next: ([local, session]) => {
				if (isNil(local) || isNil(session)) {
					this.updateStorage(this.translocoService.getActiveLang());
				}
			}
		});
		this.subscription.add(langSub);
	}

	/**
	 * Gets the active language from translocoService.
	 *
	 * @type {LanguageEnum}
	 * @memberof LanguageService
	 */
	get language(): LanguageEnum {
		return this.translocoService.getActiveLang() as LanguageEnum;
	}

	/**
	 * Sets the active language to translocoService
	 *
	 * @memberof LanguageService
	 */
	set language(value: LanguageEnum) {
		this.translocoService.setActiveLang(value === LanguageEnum.spanish ? LanguageEnum.spanish : LanguageEnum.english);
	}

	/**
	 * Checks if the active language is English
	 *
	 * @readonly
	 * @type {boolean}
	 * @memberof LanguageService
	 */
	get isEnglish(): boolean {
		return this.language === LanguageEnum.english;
	}

	/**
	 * Checks if the active language is Spanish
	 *
	 * @readonly
	 * @type {boolean}
	 * @memberof LanguageService
	 */
	get isSpanish(): boolean {
		return this.language === LanguageEnum.spanish;
	}

	/**
	 * Wraps the translocoService translate function
	 *
	 * @param {TranslateParams} key
	 * @return {*}  {string}
	 * @memberof LanguageService
	 */
	public translate(key: TranslateParams, params: HashMap<any> = undefined): string {
		return this.translocoService.translate(key, params);
	}

	private updateStorage(language: string): void {
		// aplica domain should be defaulted to es
		if (!this.sessionStorageService.get(this.storageKey) && this.environmentService.isAplica && language !== 'es') {
			this.translocoService.setActiveLang('es');
			return;
		}

		this.sessionStorageService.set(this.storageKey, language);

		// tealium chat expects a delay to when switching languages.
		of(language)
			.pipe(delay(100))
			.subscribe({
				next: (lang) =>
					this.localStorageService.set(this.lsStorageKey, lang === LanguageEnum.spanish ? lang : 'en-US', false)
			});
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}
}
