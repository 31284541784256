import { Injectable } from '@angular/core';

export enum Environment {
	Prod = 'PROD',
	Stage = 'STAGE',
	Qa = 'QA',
	Dev = 'DEV', // feature-box
	Local = 'LOCAL'
}

@Injectable({
	providedIn: 'root'
})
export class EnvironmentService {
	private _env: Environment;
	private _apiUrl: string;
	private _hostName: string;

	constructor() {
		this.setEnvVariables();
	}

	get env(): Environment {
		return this._env;
	}

	get apiUrl(): string {
		return this._apiUrl;
	}

	get hostName(): string {
		return this._hostName;
	}

	get isProduction(): boolean {
		return this._env === Environment.Prod;
	}
	get isStage(): boolean {
		return this._env === Environment.Stage;
	}
	get isQa(): boolean {
		return this._env === Environment.Qa;
	}
	get isDev(): boolean {
		return this._env === Environment.Dev;
	}
	get isInternal(): boolean {
		return this._env === Environment.Local;
	}

	get isApply(): boolean {
		return this._hostName.indexOf('apply') > -1;
	}

	get isAplica(): boolean {
		return this._hostName.indexOf('aplica') > -1 || this._hostName.indexOf('oe') > -1;
	}

	get isDevHost(): boolean {
		const devHostPattern = /.*(\.dev\.pfops\.com)/;
		return this._hostName.match(devHostPattern) !== null;
	}

	init(): Promise<void> {
		return new Promise((resolve) => {
			this.setEnvVariables();
			resolve();
		});
	}

	public getServicingHost(): string {
		let host = 'account.oportun.com';
		let env = this.env.toLowerCase();
		if (this.isQa) {
			host = `ng-ccas.12.app.${env}.pfops.com`;
		} else if (this.isStage) {
			host = `ng-ccas.1.app.${env}.pfops.com`;
		} else if (this.isDevHost || this.isDev) {
			host = window.location.host.replace(/(online-origination)-([A-Za-z]{2,})-(\d{3,})/gi, 'ng-ccas-MOBX-$3');
		}
		return window.location.protocol + '//' + host;
	}

	private setEnvVariables(): void {
		this._hostName = window?.location?.hostname;

		switch (this._hostName) {
			case 'apply.oportun.com':
			case 'aplica.oportun.com':
			case 'apply.progressfin.com':
			case 'aplica.progressfin.com':
			case 'ventas.oportun.com':
			case 'ventas.progressfin.com':
			case 'oe.oportun.com':
			case 'oe1.oportun.com':
				this._env = Environment.Prod;
				break;
			case 'aws-aplica.progressfin.com':
			case 'aws-apply.progressfin.com':
				this._env = Environment.Stage;
				break;
			case 'movil.dev.pfops.com':
			case 'localhost':
				this._env = Environment.Local;
				this._hostName = window?.location?.host;
				break;
			default:
				this._env = this.isDevHost ? Environment.Dev : Environment.Qa;
				break;
		}
		this._apiUrl = `//${this._hostName}/api`;
	}
}
