export interface ICcasUser {
	phoneNumber: string;
	firstName: string;
	lastName: string;
	emailAddress: string;
	dateOfBirth: string;
	zipCode: string;
	loanId: number;
	clientId: string;
	context: {
		applicantId: number;
		clientId: string;
		productType: string; // FIXME: Enum??
	};
}

export interface ICcasUserValidationResult {
	status: CcasUserValidationEnum;
	message: string;
}

export enum CcasUserValidationEnum {
	clientExistsInOktaInUse = 'CLIENT_EXIST_OKTA_IN_USE',
	clientExistHasOkta = 'CLIENT_EXIST_HAS_OKTA',
	clientExistsNoEmail = 'CLIENT_EXIST_NO_EMAIL',
	clientExistsNoOkta = 'CLIENT_EXIST_NO_OKTA',
	clientNotFound = 'CLIENT_NOT_FOUND'
}
