import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DialogService } from 'src/app/core/services/dialog/dialog.service';
import {
	FileUploadService,
	FileUploadStatusEnum,
	FileUploadType,
	IFileUpload
} from 'src/app/core/services/file-upload/file-upload.service';
import { LanguageService } from 'src/app/core/services/language/language.service';
import { LoanApplicationService } from 'src/app/core/services/loan-application/loan-application.service';
import { MetadataService } from 'src/app/core/services/metadata/metadata.service';
import { RoutingService } from 'src/app/core/services/routing/routing.service';
import { SessionStorageService } from 'src/app/core/services/storage/session-storage.service';

import {
	BaseCategoryOriginalComponent,
	ProofStatusEnum
} from '../base-category-original/base-category-original.component';
import { DocumentUploadOriginalComponent } from '../document-upload-original/document-upload-original.component';

@Component({
	selector: 'op-selfie-original',
	templateUrl: './selfie-original.component.html',
	styleUrls: ['./selfie-original.component.scss']
})
export class SelfieOriginalComponent extends BaseCategoryOriginalComponent implements OnInit, OnDestroy {
	readonly categoryType = FileUploadType.selfie;
	readonly storageKey = 'proofOfSelfie';

	constructor(
		metadataService: MetadataService,
		routingService: RoutingService,
		fileUploadService: FileUploadService,
		sessionStorageService: SessionStorageService,
		loanAppService: LoanApplicationService,
		private languageService: LanguageService,
		private dialogService: DialogService
	) {
		super(fileUploadService, sessionStorageService, routingService, metadataService, loanAppService);
	}

	canCancel: boolean = true;
	submitDisabled: boolean = true;

	@ViewChild(DocumentUploadOriginalComponent) docUpload: DocumentUploadOriginalComponent;

	ngOnInit(): void {
		super.ngOnInit();
		this.selectedDocumentClassification = { key: 'SELFIE', text: '' };
		this.proofState = ProofStatusEnum.upload;

		this.subscription = this.files$.subscribe({
			next: (files) => {
				this.canCancel = this.fileUploadService.isAllFilesUploadNotStarted(
					this.filterClassificationFiles(files, this.selectedDocumentClassification)
				);

				this.submitDisabled = this.checkIfSubmitDisabled(files);
			}
		});
	}

	ngOnDestroy(): void {
		super.ngOnDestroy();
	}

	private checkIfSubmitDisabled(files: IFileUpload[]): boolean {
		return !files?.length && !this.fileUploadService.isSomeFileTypeUploadPending(FileUploadType.selfie);
	}

	showErrorMessage(errorMessage: string): void {
		this.dialogService
			.openErrorDialog(errorMessage || this.languageService.translate('DOCUMENT_SUBMIT.errorDialog'))
			.subscribe();
	}

	onContinue(): void {
		this.docUpload.uploadSingleFile().subscribe({
			next: (files: IFileUpload[]) => {
				const errorFile = files.find((f) => f.status === FileUploadStatusEnum.error);
				if (errorFile) {
					this.fileUploadService.removeErrorFiles(this.categoryType);
					this.showErrorMessage(errorFile.error?.msg);
					return;
				}

				const allFinished = this.fileUploadService.isAllFilesUploadFinished(files);
				if (allFinished) {
					this.fileUploadService.removeErrorFiles(this.categoryType);
					this.routingService.back();
				}
			}
		});
	}

	onCancel(): void {
		this.fileUploadService.removePendingFiles(this.categoryType);
		this.fileUploadService.removeErrorFiles(this.categoryType);
		this.routingService.back();
	}
}
