<div class="plaid-link" *transloco="let t">
	<button
		mat-flat-button
		[color]="color"
		id="{{ id + '_plaidOpenBtn' }}"
		[disabled]="isBankConnectAvailable"
		(click)="openPlaid()"
	>
		{{ t(bankAccountExists ? 'PLAID_LINK.connectNewBank' : 'PLAID_LINK.initial') }}
		<mat-icon svgIcon="op-popout"></mat-icon>
	</button>
</div>
