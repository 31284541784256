import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FileUploadType } from 'src/app/core/services/file-upload/file-upload.service';
import { IMetadata } from 'src/app/core/services/mobile-api';
import { opRequired } from 'src/app/shared/decorators/required.decorator';

@Component({
	selector: 'op-document-classification',
	templateUrl: './document-classification.component.html',
	styleUrls: ['./document-classification.component.scss']
})
export class DocumentClassificationComponent implements OnInit {
	@Input()
	@opRequired()
	id: string;

	@Input()
	categoryType: FileUploadType;

	@Input()
	documentClassificationList: IMetadata[];

	@Output()
	documentClassification = new EventEmitter<IMetadata>();

	@Output()
	cancel = new EventEmitter<any>();

	formGroup: FormGroup;
	showOtherInformation = false;

	public fileUploadType: typeof FileUploadType = FileUploadType;

	constructor(private formBuilder: FormBuilder) {
		this.formGroup = this.formBuilder.group({
			documentClassification: []
		});
	}

	ngOnInit(): void {
		this.formGroup.valueChanges.subscribe((value) => {
			this.showOtherInformation = value.documentClassification === 'OTHER';
		});
	}

	onSubmit(event): void {
		this.documentClassification.emit(
			this.documentClassificationList.find((item) => item.key === this.formGroup.value.documentClassification)
		);
	}

	onCancel(): void {
		this.cancel.emit();
	}
}
