<div class="document-upload" *transloco="let t">
	<div>
		<div class="title-wrapper op-mb-8">
			<div class="title-margin">
				<ng-content></ng-content>
			</div>
			<div class="title-margin title-reupload" *ngIf="showReUploadButton">
				<button
					mat-flat-button
					color="link-text-b1"
					tabindex="0"
					id="{{ id + documentClassification.key + '_reupload_button' }}"
					(click)="fileUpload.click()"
					[opTagClick]="id + documentClassification.key + '_reUpload_button'"
				>
					<div *ngIf="!isSelfie">
						{{ t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.reUploadDocument') }}
					</div>
					<div *ngIf="isSelfie">
						{{ t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.proofOfSelfie.reTakeSelfie') }}
					</div>
				</button>
			</div>
		</div>
		<div *ngIf="showSingleFile && singleFile">
			<div
				class="single-file-container"
				[ngClass]="{
					attention: singleFile?.ocrInfo?.documentStatus === fileStatusEnum.review,
					progress: showProgress
				}"
				opDragDrop
				id="{{ id + '_drop_zone_pic' }}"
				(fileDropped)="onFileDropped($event)"
				[opTagClick]="id + documentClassification.key + '_upload'"
			>
				<div></div>
				<div>
					<div>
						<img
							*ngIf="singleFile && !showProgress && singleFile?.thumbnail"
							class="thumbnail-img"
							id="{{ id + '_singleFile' + '_image' }}"
							alt=""
							[src]="singleFile?.thumbnail"
						/>
						<img
							*ngIf="singleFile && !showProgress && !singleFile?.thumbnail"
							id="{{ id + '_singleFile' + '_default_image' }}"
							class="default-img"
							alt=""
							src="/assets/images/icons/op-document.svg"
						/>
					</div>

					<div
						class="file-name op-micro-font op-light-black"
						[ngClass]="{
							alert: singleFile?.ocrInfo?.documentStatus === fileStatusEnum.review,
							progress: showProgress,
							'op-label-font': showProgress
						}"
					>
						{{ singleFile?.name }}
					</div>
					<ng-content
						*ngIf="!showProgress && singleFile?.status !== fileUploadStatusEnum.pending"
						select="[singleUploadDocument]"
					></ng-content>

					<div class="op-mt-10" *ngIf="showProgress" id="{{ id + '_drop_zone_pic_progress_bar' }}">
						<mat-progress-bar class="file-progress" mode="determinate" [value]="uploadProgress"></mat-progress-bar>
						<div class="op-mt-10 op-label-font">{{ t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.processingDocument') }}</div>
					</div>
				</div>
				<div>
					<div
						*ngIf="singleFile?.status === fileUploadStatusEnum.pending"
						tabindex="0"
						class="delete-icon"
						(click)="removeSelectedFile(singleFile.id)"
						[opTagClick]="id + documentClassification.key + '_delete_button'"
					></div>
				</div>
			</div>
		</div>

		<input
			type="file"
			class="file-input"
			id="{{ id + '_file' }}"
			[accept]="requiredFileType"
			(change)="onFileSelected($event)"
			#fileUpload
		/>

		<div
			*ngIf="!showSingleFile || (showSingleFile && !singleFile)"
			class="dnd-container"
			opDragDrop
			id="{{ id + '_drop_zone' }}"
			(fileDropped)="onFileDropped($event)"
			[opTagClick]="id + documentClassification.key + '_upload'"
		>
			<div *ngIf="showProgress" id="{{ id + '_drop_zone_pic_progress' }}">
				<mat-progress-bar class="file-progress" mode="determinate" [value]="uploadProgress"></mat-progress-bar>
				<div class="op-mt-20">{{ t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.processingDocument') }}</div>
			</div>
			<div *ngIf="isSelfie && !showProgress">
				<img
					alt=""
					src="/assets/images/icons/op-selfiePlaceholder.svg"
					[opTagClick]="id + documentClassification.key + '_upload'"
				/>
			</div>
			<ng-content
				*ngIf="!uploadProgress || uploadProgress === 100 || categoryType === fileUploadType.income"
				select="[uploadDocument]"
			></ng-content>
			<div class="op-mt-10" *ngIf="(!uploadProgress || uploadProgress === 100) && !showProgress">
				<button
					mat-flat-button
					color="primary-small"
					class="op-mt-10"
					name="id + documentClassification.key + '_upload_button'"
					id="id + documentClassification.key + '_upload_button'"
					(click)="fileUpload.click()"
					[opTagClick]="id + documentClassification.key + '_upload_button'"
				>
					<div *ngIf="!isSelfie">{{ t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.uploadDocuments') }}</div>
					<div class="icon-align-selfie" *ngIf="isSelfie">
						<div class="icon-text-selfie">{{ t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.proofOfSelfie.takeSelfie') }}</div>
						<mat-icon svgIcon="op-photoCamera"></mat-icon>
					</div>
				</button>
			</div>
		</div>
	</div>

	<div *ngIf="!showSingleFile">
		<op-document-v-thumbnails id="{{ id + '_fileThumbnails' }}" [files]="categoryFiles" [categoryType]="categoryType">
			<div class="op-body-1-bold-font op-mtb-20">
				{{ t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.uploadedDocuments') }} ({{ categoryFiles?.length }})
			</div>
			<ng-content select="[criteriaMet]"></ng-content>
		</op-document-v-thumbnails>
	</div>
</div>
