import { Clipboard } from '@angular/cdk/clipboard';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslocoService } from '@ngneat/transloco';
import { DeviceDetectorService } from 'src/app/core/services/device-detector/device-detector.service';
import { DialogService } from 'src/app/core/services/dialog/dialog.service';
import { LoanApplicationService } from 'src/app/core/services/loan-application/loan-application.service';
import { IReferralCodeResult } from 'src/app/core/services/mobile-api/mobile-api.model';
import { MobileApiService } from 'src/app/core/services/mobile-api/mobile-api.service';
import { TagDataService } from 'src/app/core/services/tag-data/tag-data.service';

const REFERRAL_CODE_BONUS = 50;

@Component({
	selector: 'op-referral',
	templateUrl: './referral.component.html',
	styleUrls: ['./referral.component.scss']
})
export class ReferralComponent implements OnInit {
	clientId: string;
	referralBonusAmount: number = REFERRAL_CODE_BONUS;
	isMobile: boolean = false;
	referralCode: string = '';
	referralLink: string = '';

	constructor(
		private dialog: MatDialog,
		private deviceDetectorService: DeviceDetectorService,
		private loanAppService: LoanApplicationService,
		private mobileService: MobileApiService,
		private _snackBar: MatSnackBar,
		private translocoService: TranslocoService,
		private clipboard: Clipboard,
		private tagDataService: TagDataService,
		private dialogService: DialogService
	) {}

	ngOnInit(): void {
		const clientId = this.loanAppService.clientIdFromFirstApplicant;

		this.mobileService.getClientsReferrals(clientId).subscribe({
			next: (response: IReferralCodeResult) => {
				const { referralCode, referralLink } = response;
				this.referralCode = referralCode;
				this.referralLink = referralLink;
			},
			error: (err) => {
				this.dialogService.openErrorDialog(err).subscribe();
			}
		});
		this.isMobile = this.deviceDetectorService.isMobile();
	}

	public copyReferralCode = (): void => {
		this.clipboard.copy(this.referralCode);
		this.openReferralCopyCodeMessage();
		this.tagDataService.link(
			{},
			{
				event_action: 'click',
				event_category: 'Form',
				event_label: 'rx_form',
				tealium_event: 'copy_text_to_clipboard'
			}
		);
	};

	public openReferralCopyCodeMessage(): void {
		this._snackBar.open(
			this.translocoService.translate('REFERRAL.copiedToClipboard'),
			this.translocoService.translate('REFERRAL.copied'),
			{
				duration: 3000
			}
		);
	}

	public shareViaEmail(): void {
		const mailBox = document.createElement('a');
		const options = {
			mailto: '',
			subject: this.translocoService.translate('REFERRAL.EMAIL_REFERRAL.subject', {
				amount: this.referralBonusAmount
			}),
			body: this.translocoService.translate('REFERRAL.EMAIL_REFERRAL.body', {
				amount: this.referralBonusAmount,
				link: this.referralLink
			})
		};

		mailBox.setAttribute('style', 'position: fixed; top: 0; left: 0; opacity: 0');
		mailBox.setAttribute('id', 'emailShare');
		mailBox.setAttribute('href', `mailto:${options.mailto}?subject=${options.subject}&body=${options.body}`);
		mailBox.setAttribute('target', '_blank');

		document.body.appendChild(mailBox);
		document.getElementById('emailShare').click();
		document.body.removeChild(mailBox);
		this.tagDataService.link(
			{},
			{
				event_action: 'click',
				event_category: 'Form',
				event_label: 'rx_form',
				tealium_event: 'email_share'
			}
		);
	}

	public shareViaSocial(): void {
		const shareInformation = {
			title: this.translocoService.translate('REFERRAL.referral'),
			text: this.translocoService.translate('REFERRAL.SOCIAL_SHARE.message', {
				amount: REFERRAL_CODE_BONUS,
				link: this.referralLink
			})
		};

		// Fall back to email share if social share fails
		try {
			window?.navigator?.share(shareInformation);
			this.tagDataService.link(
				{},
				{
					event_action: 'click',
					event_category: 'Form',
					event_label: 'rx_form',
					tealium_event: 'social_share'
				}
			);
		} catch (error) {
			this.shareViaEmail();
		}
	}
}
