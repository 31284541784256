import { Component } from '@angular/core';
import { DialogService } from 'src/app/core/services/dialog/dialog.service';
import { RoutingPathsEnum, RoutingService } from 'src/app/core/services/routing/routing.service';

import { DisbursementType } from 'src/app/core/services/loan-application/disbursement/disbursement.model';
import { FundingMethodConfirmComponent } from 'src/app/shared/components/dialogs/funding-method-confirm/funding-method-confirm.component';
import { TranslocoService } from '@ngneat/transloco';

@Component({
	selector: 'op-add-manually',
	templateUrl: './add-manually.component.html',
	styleUrls: ['./add-manually.component.scss']
})
export class AddManuallyComponent {
	constructor(
		private dialogService: DialogService,
		private routingService: RoutingService,
		private translocoService: TranslocoService
	) {}

	notSaved() {
		this.dialogService
			.openErrorDialogWithTitle(
				this.translocoService.translate('FIND_YOUR_APPLICATION.ERROR_MODAL.title'),
				this.translocoService.translate('FIND_YOUR_APPLICATION.ERROR_MODAL.message')
			)
			.subscribe();
	}

	bankDetailsSaved($event): void {
		this.dialogService
			.openSimpleDialog(FundingMethodConfirmComponent, {
				data: {
					fundingOptions: DisbursementType.ach,
					account: {
						bankName: $event.bankName,
						accountType: $event.type,
						accountNumber: $event.number
					}
				}
			})
			.subscribe({
				next: (result) => {
					this.routingService.route(RoutingPathsEnum.documentSubmit);
				},
				error: () => {}
			});
	}
}
