<div
	id="{{ id }}"
	class="document-section"
	*transloco="let t"
	[ngClass]="needsAttention ? 'attention-border' : needsUpload ? 'needs-border' : 'default-border'"
	[ngClass]="{
		attention: needsAttention,
		needs: needsUpload,
		verified: badge === docBadgeEnum.verified
	}"
	type="button"
>
	<div>
		<div class="section-tags op-mb-10">
			<op-document-badge-original
				*ngIf="!attachments && !(needsAttention || needsUpload) && !processing && badge !== docBadgeEnum.verified"
				id="{{ id + '_no_attachments' }}"
				class="idle"
			>
				{{ t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.required') }}
			</op-document-badge-original>
			<op-document-badge-original
				*ngIf="badge === docBadgeEnum.verified && !bounceAttention"
				id="{{ id + '_verified' }}"
				class="ready"
				icon="op-checkMark-green"
			>
				{{ t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.verified') }}
			</op-document-badge-original>
			<op-document-badge-original
				*ngIf="attachments && !needsAttention && !processing"
				id="{{ id + '_attachments' }}"
				class="ready"
				icon="op-checkMark"
			>
				{{ categoryFiles?.length + ' ' + t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.attachments') }}
			</op-document-badge-original>
			<op-document-badge-original
				*ngIf="needsAttention && !processing"
				id="{{ id + '_needsAttention' }}"
				class="attention"
				icon="op-alert3"
			>
				{{ t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.needsAttention') }}
			</op-document-badge-original>
			<op-document-badge-original *ngIf="needsUpload" id="{{ id + '_needsUpload' }}" class="needs" icon="op-alert3">
				{{ t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.documentRequired') }}
			</op-document-badge-original>
			<op-document-badge-original *ngIf="processing" id="{{ id + '_processing' }}" class="processing">
				{{ t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.processing') }}
			</op-document-badge-original>
		</div>
		<div class="section-header op-header-2-font op-mb-10">
			<div class="header-icon">
				<mat-icon *ngIf="icon" svgIcon="{{ icon }}"></mat-icon>
			</div>
			<div class="header">{{ title }}</div>
		</div>
		<div class="section-description op-body-1-font op-gray op-mb-10">
			{{ description }}
		</div>
	</div>
	<div>
		<mat-icon *ngIf="badge !== docBadgeEnum.verified" svgIcon="op-caretRightSmall"></mat-icon>
	</div>
</div>
