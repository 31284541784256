<div class="document-status" *transloco="let t">
	<ng-content></ng-content>

	<div>
		<div
			class="op-mt-10"
			*ngFor="let file of categoryFilesStatus; let i = index"
			(click)="onUploadMore(file)"
			[opTagClick]="id + file.key + 'AddMore'"
		>
			<div class="file-list-item" id="{{ id + '_' + file.key }}">
				<div>
					<op-document-badge-original
						*ngIf="file.attention"
						id="{{ id + '_needsAttention_' + file.key }}"
						class="op-mb-5 attention"
						icon="op-alert3"
					>
						{{ t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.needsAttention') }}
					</op-document-badge-original>
					<div class="file-list-info" id="{{ id + '_' + file.key + '_name' }}">
						<div class="op-header-2-font op-mb-5">{{ file.text }}</div>
						<div class="op-dark-grey">{{ file.count + ' ' + t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.attachments') }}</div>
					</div>
				</div>
				<div class="caret-right">
					<mat-icon svgIcon="op-caretRightSmall"></mat-icon>
				</div>
			</div>
			<hr />
		</div>
	</div>

	<div class="add-more-files" (click)="onAddMore()" [opTagClick]="id + 'AddMore'">
		<div class="add-more-files-info" id="{{ id + '_add_more' }}">
			<mat-icon svgIcon="op-add2"></mat-icon>
			<div class="op-header-2-font add-more-text">
				{{ t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.addAdditionalDocuments') }}
			</div>
		</div>
		<div class="caret-right">
			<mat-icon svgIcon="op-caretRightSmall"></mat-icon>
		</div>
	</div>
</div>
