import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { isEmpty } from 'lodash';
import { distinctUntilChanged, map, Observable } from 'rxjs';
import { DialogService } from 'src/app/core/services/dialog/dialog.service';
import {
	FileUploadService,
	FileUploadStatusEnum,
	FileUploadType,
	IFileUpload
} from 'src/app/core/services/file-upload/file-upload.service';
import { LanguageService } from 'src/app/core/services/language/language.service';
import { LoanApplicationService } from 'src/app/core/services/loan-application/loan-application.service';
import { MetadataEnum } from 'src/app/core/services/metadata/metadata.model';
import { MetadataService } from 'src/app/core/services/metadata/metadata.service';
import { IMetadata } from 'src/app/core/services/mobile-api';
import { RoutingService } from 'src/app/core/services/routing/routing.service';
import { SessionStorageService } from 'src/app/core/services/storage/session-storage.service';

import AutoVerificationUtils, { frontBackList } from '../auto-verification-utils-original';
import {
	BaseCategoryOriginalComponent,
	ProofStatusEnum
} from '../base-category-original/base-category-original.component';
import {
	DocumentStatusEventEnum,
	IDocumentStatusEvent
} from '../document-status-original/document-status-original.component';
import { DocumentUploadOriginalComponent } from '../document-upload-original/document-upload-original.component';
import { IssuingCountryOriginalPipe } from './issuing-country-original.pipe';

@Component({
	selector: 'op-proof-of-identification-original',
	templateUrl: './proof-of-identification-original.component.html',
	styleUrls: ['./proof-of-identification-original.component.scss']
})
export class ProofOfIdentificationOriginalComponent extends BaseCategoryOriginalComponent implements OnInit, OnDestroy {
	readonly categoryType = FileUploadType.identification;
	readonly storageKey = 'proofOfIdentification';
	readonly metaClassification = MetadataEnum.IDDocumentClassification;

	countryList$: Observable<IMetadata[]>;

	documentClassificationList$: Observable<IMetadata[]>;
	showFrontBack: boolean = false;
	canCancel: boolean = true;
	submitDisabled: boolean = true;

	readonly KEY_USA = 'US';

	formGroup: FormGroup;

	@ViewChild(DocumentUploadOriginalComponent) docUpload: DocumentUploadOriginalComponent;

	constructor(
		metadataService: MetadataService,
		routingService: RoutingService,
		fileUploadService: FileUploadService,
		sessionStorageService: SessionStorageService,
		loanAppService: LoanApplicationService,
		private formBuilder: FormBuilder,
		private languageService: LanguageService,
		private dialogService: DialogService,
		private issuingCountryOriginalPipe: IssuingCountryOriginalPipe
	) {
		super(fileUploadService, sessionStorageService, routingService, metadataService, loanAppService);
		this.createForm(this.formBuilder);
	}

	ngOnInit(): void {
		super.ngOnInit();

		const storageKeySub = this.sessionStorageService
			.select(this.storageKey)
			.pipe(
				map((v) => v?.selectedDocumentClassification),
				distinctUntilChanged()
			)
			.subscribe({
				next: (selectedDocumentClassification) => this.setFrontBack(selectedDocumentClassification?.key)
			});
		this.subscription.add(storageKeySub);

		const fileSub = this.files$.subscribe({
			next: (files: IFileUpload[]) => {
				if (!isEmpty(files) && (this.isProofTypeSelection || this.isProofTypeEmpty) && !this.choosing) {
					this.proofState = ProofStatusEnum.status;
				}

				if (this.isProofTypeEmpty) {
					this.proofState = ProofStatusEnum.classificationSelection;
				}

				const classificationFiles = this.filterClassificationFiles(files, this.selectedDocumentClassification);
				this.canCancel = this.checkIfCanCancel(classificationFiles);

				this.submitDisabled = this.checkIfSubmitDisabled(
					files,
					this.selectedDocumentClassification,
					this.formGroup.get('issuingCountry').value
				);
			}
		});
		this.subscription.add(fileSub);

		this.countryList$ = this.metadataService.select(MetadataEnum.Country);

		this.formGroup.get('issuingCountry').valueChanges.subscribe({
			next: (value) => {
				this.submitDisabled = this.checkIfSubmitDisabled(
					this.selectedClassificationFiles,
					this.selectedDocumentClassification,
					value
				);
			}
		});
	}

	ngOnDestroy(): void {
		super.ngOnDestroy();
	}

	createForm(fb: FormBuilder): void {
		this.formGroup = fb.group({
			issuingCountry: [this.KEY_USA]
		});
	}

	protected updateClassFiles(categoryFiles: IFileUpload[], classification: IMetadata): void {
		const modified = AutoVerificationUtils.modifyOCRAttention(categoryFiles);
		this.selectedClassificationFiles = this.filterClassificationFiles(modified, classification);
		this.reviewFiles = this.filterOcrReview(this.selectedClassificationFiles);
		this.finishedFiles = modified?.filter((file) => file?.status === FileUploadStatusEnum.finished);
		const isFrontBack = frontBackList.includes(String(classification?.key));
		this.attention = !isFrontBack ? false : Boolean(this.reviewFiles?.length);
	}

	private checkIfCanCancel(files: IFileUpload[]): boolean {
		if (this.showFrontBack) {
			return isEmpty(files) || this.fileUploadService.isSomeFilesUploadPending(files);
		} else {
			return isEmpty(files) || this.fileUploadService.isAllFilesUploadNotStarted(files);
		}
	}

	private checkIfSubmitDisabled(files: IFileUpload[], docClassification: IMetadata, country: string): boolean {
		if (isEmpty(files)) {
			return true;
		}

		const isCountry = this.issuingCountryOriginalPipe.transform(String(docClassification?.key));
		if (this.showFrontBack) {
			return !(
				this.fileUploadService.isAllFilesUploadFinished(files) ||
				files?.filter((f) => f.status === FileUploadStatusEnum.pending).length === 2
			);
		} else if (isCountry) {
			return !country;
		} else {
			const finished = this.fileUploadService.isSomeFilesUploadFinished(
				this.filterClassificationFiles(files, docClassification)
			);
			return !finished;
		}
	}

	private setFrontBack(key: string | number): void {
		this.showFrontBack = frontBackList.includes(key?.toString());
	}

	saveDocumentClassification(docClassification: IMetadata): void {
		this.selectedDocumentClassification = docClassification;

		if (this.showFrontBack && !isEmpty(this.selectedClassificationFiles)) {
			const country = this.selectedClassificationFiles.find(Boolean).country;
			this.formGroup.get('issuingCountry').setValue(country);
		}

		this.canCancel = this.checkIfCanCancel(this.selectedClassificationFiles);
		this.submitDisabled = this.checkIfSubmitDisabled(
			this.selectedClassificationFiles,
			this.selectedDocumentClassification,
			this.formGroup.get('issuingCountry').value
		);
		this.proofState = ProofStatusEnum.upload;
	}

	showErrorMessage(errorMessage: string): void {
		this.dialogService
			.openErrorDialog(errorMessage || this.languageService.translate('DOCUMENT_SUBMIT.errorDialog'))
			.subscribe();
	}

	onContinue(): void {
		this.docUpload.uploadPendingFiles().subscribe({
			next: (files: IFileUpload[]) => {
				const errorFile = files.find((f) => f.status === FileUploadStatusEnum.error);
				if (errorFile) {
					this.fileUploadService.removeErrorFiles(this.categoryType);
					this.showErrorMessage(errorFile.error?.msg);
					return;
				}

				const allFinished = this.fileUploadService.isAllFilesUploadFinished(files);
				if (allFinished) {
					this.fileUploadService.removeErrorFiles(this.categoryType);
					this.routingService.back();
				}
			}
		});
	}

	onCancel(): void {
		this.fileUploadService.removePendingFiles(this.categoryType);
		this.fileUploadService.removeErrorFiles(this.categoryType);
		this.routingService.back();
	}

	onDocStatus(statusEvent: IDocumentStatusEvent): void {
		switch (statusEvent.event) {
			case DocumentStatusEventEnum.uploadMore:
				this.saveDocumentClassification(statusEvent.documentClassification);
				this.proofState = ProofStatusEnum.upload;
				break;
			case DocumentStatusEventEnum.addMore:
				this.choosing = true;
				this.proofState = ProofStatusEnum.classificationSelection;
				break;
		}
	}
}
