<div *ngIf="!PushToDebitCheckAutoPay">
	<div class="esign-auto-pay" *transloco="let t" aria-labelledby="auto-pay-label" aria-describedby="footnote">
		<op-masthead [title]="t('ESIGN_AUTO_PAY.autoPayQuestion')"></op-masthead>
		<op-content-container class="content" *ngIf="loaded">
			<div class="op-center">
				<img src="/assets/images/auto-pay.svg" alt="auto pay" />
			</div>

			<div id="auto-pay-label" class="op-mt-16 op-body-1-font" *ngIf="bankAccountList?.length > 0">
				<span
					*ngIf="!isMetaRchEnabled"
					role="presentation"
					[innerHTML]="'ESIGN_AUTO_PAY.useBankAccount' | transloco: { accountNumber: esignUser?.accountNumber }"
				></span>

				<span
					*ngIf="isMetaRchEnabled"
					role="presentation"
					[innerHTML]="'ESIGN_AUTO_PAY.useBankAccountWithoutNumber' | transloco"
				></span>
				<span>
					<span *ngIf="isBiWeekly">
						{{ t('ESIGN_AUTO_PAY.biWeekly') }}
						<span id="paymentDay">{{ esignUser?.paymentDay }}</span>
					</span>
					<span *ngIf="isMonthly">
						{{
							'ESIGN_AUTO_PAY.paymentDayMonthlyText'
								| transloco: { date1: esignUser?.paymentDayMonthlyValue | opDayOrdinal: language }
						}}
					</span>
					<span *ngIf="isSemiMonthly">
						{{
							t('ESIGN_AUTO_PAY.paymentDaySemiMonthlyText', {
								date1: esignUser?.paymentDaySemiMonthlyValue1 | opDayOrdinal: language,
								date2: esignUser?.paymentDaySemiMonthlyValue2 | opDayOrdinal: language
							})
						}}
					</span>
				</span>
			</div>

			<div class="op-center op-mt-40">
				<span class="op-display-2-font" role="presentation">
					{{ esignUser?.paymentAmount | currency: 'USD':'symbol':'0.2' }}
				</span>
			</div>

			<div class="op-mt-40">
				<div class="op-icon-align-body-1-bold">
					<span><mat-icon svgIcon="op-ready"></mat-icon></span>
					<span [innerHtml]="t('ESIGN_AUTO_PAY.free') | opSafeHtml"> </span>
				</div>
				<div class="op-icon-align-body-1-bold op-mt-16">
					<span><mat-icon svgIcon="op-atmCard"></mat-icon></span>
					<span [innerHtml]="t('ESIGN_AUTO_PAY.managePayment') | opSafeHtml"> </span>
				</div>
				<div class="op-icon-align-body-1-bold op-mt-16">
					<span><mat-icon svgIcon="op-messageBubble"></mat-icon></span>
					<span [innerHtml]="t('ESIGN_AUTO_PAY.smsReminder') | opSafeHtml"> </span>
				</div>
			</div>

			<div class="op-mt-10 op-icon-align-body-2">
				<span><mat-icon svgIcon="op-info"></mat-icon></span>
				<span>
					{{ 'ESIGN_AUTO_PAY.cancellation' | transloco }}
				</span>
			</div>

			<div class="op-mb-20" *ngIf="!isMetaRchEnabled">
				<button mat-flat-button color="primary" id="autoPaySignUpBtn" type="button" (click)="onSubmit(true)">
					{{ t('ESIGN_AUTO_PAY.signUp') }}
				</button>
				<button mat-flat-button color="secondary" id="autoPayDeclineBtn" type="button" (click)="onSubmit(false)">
					{{ t('ESIGN_AUTO_PAY.decline') }}
				</button>
				<div class="op-micro-font op-mt-8 op-gray" id="footnote">
					<div>{{ 'ESIGN_AUTO_PAY.pleaseReview' | transloco }}</div>
				</div>
			</div>

			<div class="op-mb-20" *ngIf="isMetaRchEnabled">
				<button mat-flat-button color="primary" id="autoPaySetupBtn" type="button" (click)="navigateToAddBank()">
					{{ 'ESIGN_AUTO_PAY.signUp' | transloco }}
				</button>
				<button
					mat-flat-button
					color="secondary"
					id="autoPaySkipBtn"
					type="button"
					(click)="confirmSkipAutoPayDialog()"
				>
					{{ 'ESIGN_AUTO_PAY.decline' | transloco }}
				</button>
				<div class="op-micro-font op-mt-8 op-gray" id="footnote">
					<div>{{ 'ESIGN_AUTO_PAY.pleaseReview' | transloco }}</div>
				</div>
			</div>

			<div class="op-body-1-bold-font op-mt-20 op-mb-8">{{ 'ESIGN_AUTO_PAY.customerSupport' | transloco }}</div>
			<a mat-flat-button color="link-md" href="tel:1-866-488-6090" id="callCustomerSupportLink">
				{{ t('ESIGN_AUTO_PAY.call') }}
			</a>
		</op-content-container>
	</div>
</div>

<div *ngIf="PushToDebitCheckAutoPay">
	<div class="esign-auto-pay" *transloco="let t" aria-labelledby="auto-pay-label" aria-describedby="footnote">
		<div class="esign-auto-pay-title">
			<span><op-masthead [customClass]="'head'" [title]="t('ESIGN_AUTO_PAY.autoPayQuestion')"></op-masthead></span>
		</div>
		<op-content-container class="content" *ngIf="loaded">
			<div>
				<div class="op-left auto-pay-amount">
					<div class="op-display-3-font op-mb-3" role="presentation">
						{{ esignUser?.paymentAmount | currency: 'USD':'symbol':'0.2' }}
					</div>
					<div class="op-body-2-font op-mb-20">{{ t('ESIGN_AUTO_PAY.autoPayAmount') }}</div>
				</div>

				<div *ngIf="bankAccountList?.length > 0">
					<span>{{ t('ESIGN_AUTO_PAY.useBankAccounts') }}</span>
					<span>
						<span *ngIf="isBiWeekly">
							{{ t('ESIGN_AUTO_PAY.biWeekly') }}
							<span id="paymentDay">{{ esignUser?.paymentDay }}</span>
						</span>
						<span *ngIf="isMonthly">
							{{
								'ESIGN_AUTO_PAY.paymentDayMonthlyText'
									| transloco: { date1: esignUser?.paymentDayMonthlyValue | opDayOrdinal: language }
							}}
						</span>
						<span *ngIf="isSemiMonthly">
							{{
								t('ESIGN_AUTO_PAY.paymentDaySemiMonthlyText', {
									date1: esignUser?.paymentDaySemiMonthlyValue1 | opDayOrdinal: language,
									date2: esignUser?.paymentDaySemiMonthlyValue2 | opDayOrdinal: language
								})
							}}
						</span>
					</span>
				</div>
				<div class="bank-accounts op-pad-16" *ngIf="bankAccountList?.length > 0">
					<div>
						<b>{{ t('ESIGN_AUTO_PAY.connectedBankAccounts') }}</b>
					</div>
					<op-select-bank-account-auto-pay id="selectBankAccount" [accounts]="bankAccountList">
					</op-select-bank-account-auto-pay>
					<div class="op-icon-align-label">
						<mat-icon svgIcon="op-info-green"></mat-icon>
						<span>{{ 'ESIGN_AUTO_PAY.cancellation' | transloco }}</span>
					</div>
				</div>
				<div *ngIf="bankAccountList?.length === 0">
					<span>{{ t('ESIGN_AUTO_PAY.addBankAccounts') }}</span>
				</div>
			</div>

			<div class="op-center">
				<ul>
					<li [innerHtml]="t('ESIGN_AUTO_PAY.freeBold') | opSafeHtml"></li>
					<li [innerHtml]="t('ESIGN_AUTO_PAY.managePaymentBold') | opSafeHtml"></li>
					<li [innerHtml]="t('ESIGN_AUTO_PAY.pause') | opSafeHtml"></li>
				</ul>
			</div>

			<div class="op-mb-20" *ngIf="!isMetaRchEnabled">
				<ng-container *ngIf="bankAccountList?.length > 0">
					<button
						mat-flat-button
						color="primary"
						id="autoPaySignUpBtn"
						type="button"
						[disabled]="!this.mobileService.getUserAccountId()"
						(click)="onSubmit(true)"
					>
						{{ t('ESIGN_AUTO_PAY.signUp') }}
					</button>
					<button mat-flat-button color="secondary" id="autoPayDeclineBtn" type="button" (click)="onSubmit(false)">
						{{ t('ESIGN_AUTO_PAY.decline') }}
					</button>
					<div class="op-micro-font op-mt-8 op-gray" id="footnote">
						<p>{{ 'ESIGN_AUTO_PAY.pleaseReview' | transloco }}</p>
					</div>
				</ng-container>
				<ng-container *ngIf="bankAccountList?.length === 0">
					<button mat-flat-button color="primary" id="autoPaySetupBtn" type="button" (click)="navigateToAddBank()">
						{{ 'ESIGN_AUTO_PAY.signUp' | transloco }}
					</button>
					<button
						mat-flat-button
						color="secondary"
						id="autoPaySkipBtn"
						type="button"
						(click)="confirmSkipAutoPayDialog()"
					>
						{{ 'ESIGN_AUTO_PAY.decline' | transloco }}
					</button>
				</ng-container>
			</div>

			<div class="op-mb-20" *ngIf="isMetaRchEnabled">
				<button mat-flat-button color="primary" id="autoPaySetupBtn" type="button" (click)="navigateToAddBank()">
					{{ 'ESIGN_AUTO_PAY.signUp' | transloco }}
				</button>
				<button
					mat-flat-button
					color="secondary"
					id="autoPaySkipBtn"
					type="button"
					(click)="confirmSkipAutoPayDialog()"
				>
					{{ 'ESIGN_AUTO_PAY.decline' | transloco }}
				</button>
				<div class="op-micro-font op-mt-8 op-gray" id="footnote">
					<p>{{ 'ESIGN_AUTO_PAY.pleaseReview' | transloco }}</p>
				</div>
			</div>

			<p class="op-body-1-bold-font">{{ 'ESIGN_AUTO_PAY.customerSupport' | transloco }}</p>
			<a mat-flat-button color="link-md" href="tel:1-866-488-6090" id="callCustomerSupportLink">{{
				t('ESIGN_AUTO_PAY.call')
			}}</a>
		</op-content-container>
	</div>
</div>
