<div class="plaid-link" *transloco="let t">
	<op-learn-more id="EstbCreditHistoryQ" src="/assets/images/connect-bank.svg">
		<div id="EstbCreditHistoryA">
			<div class="op-body-1-bold-font">
				{{ t('PLAID_CONNECT.connectYourBank') }}
			</div>
		</div>
	</op-learn-more>

	<div id="plaidIncentive" class="policy op-mb-12 op-dark-grey" [opTagClick]="'how plaid works'">
		<span class="op-body-2-font" [innerHtml]="t('PLAID_CONNECT.howItWorksContent')"></span>
		<span class="op-body-2-bold-font">{{ t('PLAID_CONNECT.potentialBenefits') }}</span>
	</div>

	<div class="op-icon-align-body-1 op-mb-10">
		<mat-icon class="op-dark-green" svgIcon="op-history"></mat-icon>
		<span class="op-primary-black" [innerHtml]="t('PLAID_CONNECT.fasterLoanApplication')"></span>
	</div>
	<div class="op-icon-align-body-1 op-mb-10">
		<mat-icon class="op-dark-green" svgIcon="op-refreshDouble"></mat-icon>
		<span class="op-primary-black" [innerHtml]="t('PLAID_CONNECT.automatedVerification')"></span>
	</div>
	<div class="op-icon-align-body-1">
		<mat-icon class="op-dark-green" svgIcon="op-graphAscend"> </mat-icon>
		<span class="op-primary-black" [innerHtml]="t('PLAID_CONNECT.expeditedFunds')"></span>
	</div>

	<div class="never-store op-center op-mtb-8 op-label-font">
		{{ t('PLAID_CONNECT.neverStoreLogin') }}
	</div>

	<div class="op-micro-font op-mb-20 op-dark-grey">
		<p class="policy" [innerHTML]="t('PLAID_CONNECT.youAgreePlaidAccess')"></p>
		<p class="policy" [innerHTML]="t('PLAID_CONNECT.reviewPlaidTerms')"></p>
	</div>

	<div class="op-center">
		<op-plaid-button
			#plaidButton
			class="op-mb-20"
			id="plaidButton"
			minimum="true"
			[sectionType]="sectionType"
			[bankOnly]="bankOnly"
		></op-plaid-button>
		<div class="op-body-2-font plaid-message op-button-width" *ngIf="bankConnectEvent === 'WAITING'">
			{{ t('DOCUMENT_SUBMIT.proofOfBankAccount.loadingAccounts') }}
		</div>
		<op-alert
			class="plaid-message op-button-width"
			level="ERROR"
			*ngIf="showNoAccountsError && bankConnectEvent !== 'WAITING'"
		>
			{{ t('DOCUMENT_SUBMIT.proofOfBankAccount.noAccountsError') }}
		</op-alert>
	</div>

	<div *ngIf="showSkip" class="op-center op-mb-30">
		<button
			mat-flat-button
			color="secondary"
			class="op-full-width"
			id="skipPlaidLink"
			(click)="skipPlaid()"
			[opTagClick]="'skipPlaid'"
		>
			{{ t('PLAID_CONNECT.skipForNow') }}
		</button>
	</div>

	<op-expansion-panel
		id="plaidWhichBank"
		[titleBold]="false"
		[title]="t('PLAID_CONNECT.whichBank')"
		[opTagClick]="'which bank'"
		[expanded]="false"
	>
		<span class="op-body-2-font">{{ t('PLAID_CONNECT.connectBank') }}</span>
	</op-expansion-panel>
</div>
