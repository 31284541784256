import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PhoneTypeEnum } from 'src/app/core/utils/phone-utils';

@Component({
	selector: 'op-sms-alert',
	templateUrl: './sms-alert.component.html',
	styleUrls: ['./sms-alert.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SmsAlertComponent implements OnInit {
	@Input()
	phoneType: PhoneTypeEnum;

	@Output()
	smsNotification = new EventEmitter<boolean>();

	constructor(private formBuilder: FormBuilder) {
		this.createForm(this.formBuilder);
	}

	formGroup: FormGroup;

	createForm(fb) {
		this.formGroup = fb.group({
			smsNotification: ['', []]
		});
	}

	ngOnInit(): void {
		this.formGroup.get('smsNotification').valueChanges.subscribe({
			next: (value) => {
				this.smsNotification.emit(value);
			}
		});
	}
}
