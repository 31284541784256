import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { EnvironmentService } from '../../environment/environment.service';
import { ApiUrlService } from '../api-url.service';
import { IConfigResult, IFeatureFlagResult } from './config-api.model';
import { SKIP_BUSY_INDICATOR } from 'src/app/core/interceptors/mobile-api-busy.interceptor';

@Injectable({
	providedIn: 'root'
})
export class ConfigApiService extends ApiUrlService {
	constructor(protected environmentService: EnvironmentService, private http: HttpClient) {
		super(environmentService);
	}

	configSoftPull(): Observable<IConfigResult> {
		const params = new HttpParams().set('name', 'SOFT_PULL');
		return this.http.get<IConfigResult>(`${this.API_MOBILE_SERVICEABLE_URL}/config`, { params });
	}

	configAsyncDocUploadEnabled(): Observable<IConfigResult> {
		const params = new HttpParams().set('name', 'ASYNC_DOC_UPLOAD_ENABLED');
		return this.http.get<IConfigResult>(`${this.API_MOBILE_SERVICEABLE_URL}/config`, { params });
	}

	configOnlineNotificationEnabled(): Observable<IConfigResult> {
		const params = new HttpParams().set('name', 'ONLINE_NOTIFICATION_ENABLED');
		return this.http.get<IConfigResult>(`${this.API_MOBILE_SERVICEABLE_URL}/config`, { params });
	}

	configMicroBiltEnabled(): Observable<IConfigResult> {
		const params = new HttpParams().set('name', 'MICROBILT_ENABLED');
		return this.http.get<IConfigResult>(`${this.API_MOBILE_SERVICEABLE_URL}/config`, { params });
	}

	configOnlineNotificationNewApplicantEnabled(): Observable<IConfigResult> {
		const params = new HttpParams().set('name', 'ONLINE_NOTIFICATION_NEW_APPLICANT_ENABLED');
		return this.http.get<IConfigResult>(`${this.API_MOBILE_SERVICEABLE_URL}/config`, { params });
	}

	configMobileUpgradeEnabledState(state: string): Observable<IConfigResult> {
		let params = new HttpParams();
		params = params.set('name', 'MOBILE_UPGRADE_ENABLED_STATES');
		params = params.set('value', state);
		return this.http.get<IConfigResult>(`${this.API_MOBILE_SERVICEABLE_URL}/config`, { params });
	}

	configMultiOfferEnabledStates(): Observable<IConfigResult> {
		const params = new HttpParams().set('name', 'MULTI_OFFER_ID_VERIFICATION_ENABLED_STATES');
		return this.http.get<IConfigResult>(`${this.API_MOBILE_SERVICEABLE_URL}/config`, { params });
	}

	configDocumentDeleteEnabled(): Observable<IConfigResult> {
		const params = new HttpParams().set('name', 'DOCUMENT_DELETE_ENABLED');
		return this.http.get<IConfigResult>(`${this.API_MOBILE_SERVICEABLE_URL}/config`, { params });
	}

	configFastTrackReturnEnabled(): Observable<IConfigResult> {
		const params = new HttpParams().set('name', 'FAST_TRACK_RETURNING_ENABLED');
		return this.http.get<IConfigResult>(`${this.API_MOBILE_SERVICEABLE_URL}/config`, { params });
	}

	configOverrideDisbursement(partnerId: number, state: string): Observable<IConfigResult> {
		const params = new HttpParams()
			.set('name', 'eligible.partner.channel.partners.enable')
			.set('partnerId', partnerId)
			.set('state', state);
		return this.http.get<IConfigResult>(`${this.API_MOBILE_SERVICEABLE_URL}/config`, { params });
	}

	configTermsTable(): Observable<IConfigResult> {
		const params = new HttpParams().set('name', 'ORIGINATION_FEE_UI_CONFIG_ENABLED');
		return this.http.get<IConfigResult>(`${this.API_MOBILE_SERVICEABLE_URL}/config`, { params });
	}

	showNewFundingDesign(loanId: number): Observable<IFeatureFlagResult> {
		return this.http.get<IFeatureFlagResult>(
			`${this.API_MOBILE_DISBURSEMENT_LOAN_APP_URL}/${loanId}/disbursement-selection`
		);
	}

	configOcrPollingFrequency(): Observable<IConfigResult> {
		const params = new HttpParams().set('name', 'MOBILE_OCR_POLLING_FREQUENCY');
		return this.http.get<IConfigResult>(`${this.API_MOBILE_SERVICEABLE_URL}/config`, { params });
	}

	configOcrPollingDuration(): Observable<IConfigResult> {
		const params = new HttpParams().set('name', 'MOBILE_OCR_POLLING_DURATION');
		return this.http.get<IConfigResult>(`${this.API_MOBILE_SERVICEABLE_URL}/config`, { params });
	}

	configIncomeImageCompression(): Observable<IConfigResult> {
		const params = new HttpParams().set('name', 'INCOME_IMAGE_COMPRESSION_ENABLED');
		return this.http.get<IConfigResult>(`${this.API_MOBILE_SERVICEABLE_URL}/config`, { params });
	}

	configIdImageCompression(): Observable<IConfigResult> {
		const params = new HttpParams().set('name', 'ID_IMAGE_COMPRESSION_ENABLED');
		return this.http.get<IConfigResult>(`${this.API_MOBILE_SERVICEABLE_URL}/config`, { params });
	}

	configOcrUploadDelay(): Observable<IConfigResult> {
		const params = new HttpParams().set('name', 'OCR_UPLOAD_DELAY_IN_SECS');
		return this.http.get<IConfigResult>(`${this.API_MOBILE_SERVICEABLE_URL}/config`, { params });
	}

	configOcrSubmitDelay(showBusy: boolean = true): Observable<IConfigResult> {
		const context = new HttpContext().set(SKIP_BUSY_INDICATOR, !showBusy);
		const params = new HttpParams().set('name', 'OCR_SUBMIT_DELAY_IN_SECS');
		return this.http.get<IConfigResult>(`${this.API_MOBILE_SERVICEABLE_URL}/config`, { params, context });
	}

	configEnablePlaidRefreshRetry(): Observable<IConfigResult> {
		const params = new HttpParams().set('name', 'PLAID_ASSET_REFRESH_RECONNECT_ENABLED');
		return this.http.get<IConfigResult>(`${this.API_MOBILE_SERVICEABLE_URL}/config`, { params });
	}

	configPushToDebitCheckAutoPay(): Observable<IConfigResult> {
		const params = new HttpParams().set('name', 'PUSH_TO_DEBIT_CHECK_AUTO_PAY_ENABLED');
		return this.http.get<IConfigResult>(`${this.API_MOBILE_SERVICEABLE_URL}/config`, { params });
	}

	configTabaPaySourceURL(): Observable<IConfigResult> {
		const params = new HttpParams().set('name', 'TABAPAY_IFRAME_URL');
		return this.http.get<IConfigResult>(`${this.API_MOBILE_SERVICEABLE_URL}/config`, { params });
	}

	showIdentityScreen(): Observable<IConfigResult> {
		const params = new HttpParams().set('name', 'prequal.identity.prior.to.convergence.enabled');
		return this.http.get<IConfigResult>(`${this.API_MOBILE_SERVICEABLE_URL}/config`, { params });
	}
  
	configGoogleMapsAPIKey(): Observable<IConfigResult> {
		const params = new HttpParams().set('name', 'GOOGLE_MAPS_API_KEY');
		return this.http.get<IConfigResult>(`${this.API_MOBILE_SERVICEABLE_URL}/config`, { params });
	}
}
