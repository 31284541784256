import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { isEmpty } from 'lodash';
import { combineLatest, map, Observable, Subscription } from 'rxjs';
import {
	FileUploadService,
	FileUploadType,
	IFileUpload,
	IFileUploads
} from 'src/app/core/services/file-upload/file-upload.service';
import { FileStatusEnum } from 'src/app/core/services/mobile-api';
import { SessionStorageService } from 'src/app/core/services/storage/session-storage.service';
import { opRequired } from 'src/app/shared/decorators/required.decorator';

import AutoVerificationUtils, { frontBackList } from '../auto-verification-utils';
import { DocBadgeEnum } from '../auto-verification.service';

@Component({
	selector: 'op-document-section',
	templateUrl: './document-section.component.html',
	styleUrls: ['./document-section.component.scss']
})
export class DocumentSectionComponent {
	@Input()
	@opRequired()
	id: string;

	@Input()
	icon: string;

	@Input()
	categoryType: FileUploadType;

	@Input()
	title: string;

	@Input()
	description: string;

	@Input()
	bounceAttention: boolean = false;

	@Input()
	badge: DocBadgeEnum = DocBadgeEnum.idle;

	@Input()
	count: number;

	public docBadgeEnum: typeof DocBadgeEnum = DocBadgeEnum;

	constructor() {}
}
