import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { isEmpty, upperFirst } from 'lodash';
import { BehaviorSubject, combineLatest, iif, Observable, of, Subscription } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { AchBankAccountsService } from 'src/app/core/services/ach-bank-accounts/ach-bank-accounts.service';
import { DialogService } from 'src/app/core/services/dialog/dialog.service';
import {
	EventDataService,
	EventDataTagTypeEnum,
	EventDataTagValueEnum
} from 'src/app/core/services/event-data/event-data.service';
import {
	FileUploadService,
	FileUploadType,
	IFileUpload,
	IFileUploads
} from 'src/app/core/services/file-upload/file-upload.service';
import { ApplicantUtils } from 'src/app/core/services/loan-application/applicant/applicant-utils';
import { DisbursementUtils } from 'src/app/core/services/loan-application/disbursement/disbursement-utils';
import {
	ApplicationStatusEnum,
	BounceReasonsEnum,
	ILoanApplication,
	IShowBounceAttention
} from 'src/app/core/services/loan-application/loan-application.model';
import { LoanApplicationService } from 'src/app/core/services/loan-application/loan-application.service';
import { ProductOfferDetailsUtils } from 'src/app/core/services/loan-application/product-offer/product-offer-details/product-offer-details-utils';
import {
	ProductActionsEnum,
	ProductCategoriesEnum
} from 'src/app/core/services/loan-application/product-offer/product/product.model';
import { MetadataEnum } from 'src/app/core/services/metadata/metadata.model';
import { MetadataService } from 'src/app/core/services/metadata/metadata.service';
import { ConfigApiService, IConfigResult, IMetadata } from 'src/app/core/services/mobile-api';
import { IProductAction } from 'src/app/core/services/mobile-api/mobile-api.model';
import { MobileApiService } from 'src/app/core/services/mobile-api/mobile-api.service';
import { NeuroIdService } from 'src/app/core/services/neuro-id/neuro-id.service';
import { IAutoVerifyScenario } from 'src/app/core/services/plaid-link/plaid-link.model';
import { RoutingPathsEnum, RoutingService } from 'src/app/core/services/routing/routing.service';
import { SoftPullService } from 'src/app/core/services/soft-pull/soft-pull.service';
import { SessionStorageService } from 'src/app/core/services/storage/session-storage.service';
import { TagDataService } from 'src/app/core/services/tag-data/tag-data.service';
import { AddressUtils } from 'src/app/core/utils/address-utils';
import { CheckDeliveryNoticeComponent } from 'src/app/shared/components/dialogs/check-delivery-notice/check-delivery-notice.component';
import {
	IMessageDialogData,
	MessageDialogComponent
} from 'src/app/shared/components/dialogs/message-dialog/message-dialog.component';
import { FinishedFileUploadsPipe } from 'src/app/shared/pipes/finished-file-uploads/finished-file-uploads.pipe';

import { ConfirmRemoveDocumentDialogComponent } from '../confirm-remove-document-dialog/confirm-remove-document-dialog.component';
import { ConfirmSubmitDialogComponent } from '../confirm-submit-dialog/confirm-submit-dialog.component';
import { DmvIdSectionKey } from '../document-submit.model';
import { DocumentSubmitService } from '../document-submit.service';

@Component({
	selector: 'op-manual-verification',
	templateUrl: './manual-verification.component.html',
	styleUrls: ['./manual-verification.component.scss'],
	providers: [DocumentSubmitService, FinishedFileUploadsPipe]
})
export class ManualVerificationComponent implements OnInit, OnDestroy {
	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private dialog: MatDialog,
		private mobileService: MobileApiService,
		private metadataService: MetadataService,
		private loanAppService: LoanApplicationService,
		private softPullService: SoftPullService,
		private fileUploadService: FileUploadService,
		private translocoService: TranslocoService,
		private routingService: RoutingService,
		private documentSubmitService: DocumentSubmitService,
		private tagDataService: TagDataService,
		private bankAccountService: AchBankAccountsService,
		private nidService: NeuroIdService,
		private finishedFileUploadsPipe: FinishedFileUploadsPipe,
		private configApiService: ConfigApiService,
		private dialogService: DialogService,
		private sessionStorageService: SessionStorageService,
		private eventDataService: EventDataService
	) {}

	public fileUploadType: typeof FileUploadType = FileUploadType;

	applicant: ApplicantUtils;

	addBankQueryParam: boolean;
	selectQueryParam: boolean;

	isSoftPullEnabled: boolean;

	sectionCount = 0;

	// *** Visibility sections *** //
	showBank: boolean;
	showIncome: boolean;
	showAddress: boolean;
	showIdentification: boolean;
	showVehicleDriversLicense: boolean;
	showVehiclePhotos: boolean;
	showVehicleRegistration: boolean;
	showReferencesItem: boolean;
	showSPLSection: boolean;
	showSkipSPLOfferButton: boolean;
	showSplDocSection: boolean;
	showContinueToUPLButton: boolean;
	showReturnToOffers: boolean;
	showRejectDisbursementLink: boolean;
	showDMVReqIdProof: boolean;
	showContinueUPLButton: boolean;
	showDeleteDocumentButton: boolean;

	skipOfferButtonText: string;

	// expandable sections
	expandBankSection: boolean;
	expandIncomeSection: boolean;
	expandAddressesSection: boolean;
	expandIdentificationSection: boolean;
	expandVehicleRegistrationSection: boolean;
	expandVehiclePhotosSection: boolean;
	expandVehicleDriversLicenseSection: boolean;
	expandDmvRequiredIdSection: boolean;
	expandSelfieSection: boolean;

	isOnlyReferenceItemBounced: boolean;
	isAchDisbursementSelected: boolean;
	isIncomeVerified: boolean;

	incomeConnected: boolean;
	isBankConnected: boolean;

	fileInfo: IFileUploads;
	documentDeleteEnabled: boolean;

	private readonly showPhotoSource = new BehaviorSubject<boolean>(null);
	readonly showPhoto$ = this.showPhotoSource.asObservable();

	private readonly hasDocumentRequirementSectionSource = new BehaviorSubject<boolean>(null);
	readonly hasDocumentRequirementSection$ = this.hasDocumentRequirementSectionSource.asObservable();

	identificationType: IMetadata;

	showChangeAchDisbursement: boolean;

	private subscription = new Subscription();
	confirmSubmitTranslations: Array<any>;
	applicantConsent: boolean;

	productOfferDetailUtilities: ProductOfferDetailsUtils;
	splMaxLoanAmount: number;

	autoVerifyScenario: IAutoVerifyScenario;

	isWI: boolean;

	showSection: any;
	showBouncedResubmitSection: boolean;

	dmvAcceptedIdList: string[];
	documentDeleted: boolean;
	dropOffOnDocUpload: boolean = true;

	private readonly US_DL_ID = 2201;

	ngOnInit(): void {
		const softPullSub = this.softPullService.softPull$.pipe(filter(Boolean)).subscribe({
			next: (softPull: IConfigResult) => {
				this.isSoftPullEnabled = softPull.value;
			}
		});
		this.subscription.add(softPullSub);

		this.applicant = ApplicantUtils.fromLoanApp(this.loanAppService.getLoanApp());
		if (!this.applicant.isCoApplicant()) {
			this.bankAccountService.refreshBankAccounts().subscribe();
		}

		this.addBankQueryParam = this.route.snapshot.queryParams?.addBank;
		this.selectQueryParam = this.route.snapshot.queryParams?.select;

		const types$ = this.metadataService.select(MetadataEnum.IdentificationType);
		const loanApp$ = this.loanAppService.loanApplication$.pipe(filter(Boolean));
		const bankAccounts$ = this.bankAccountService.bankAccounts$;
		const multiOfferEnabledStates$ = this.configApiService.configMultiOfferEnabledStates();
		const customerAddress$ = this.mobileService.getAddresses(
			this.loanAppService.loanApplicationId,
			this.applicant.getCurrentApplicantIndex()
		);
		const documentDeleteEnabled$ = this.configApiService.configDocumentDeleteEnabled();

		const observe$ = combineLatest([
			loanApp$,
			types$,
			bankAccounts$,
			customerAddress$,
			multiOfferEnabledStates$,
			documentDeleteEnabled$
		]);

		const loanAppSub = observe$.subscribe({
			next: ([loanApp, idTypes, bankAccounts, addrList, multiOfferStatesList, documentDeleteEnabled]) => {
				const disbursement = DisbursementUtils.fromLoanApp(loanApp as ILoanApplication);
				this.isAchDisbursementSelected = disbursement.isTypeAch();
				this.isBankConnected = this.documentSubmitService.isBankConnected(bankAccounts);
				this.applicant = new ApplicantUtils(this.loanAppService.getCurrentApplicant());

				this.productOfferDetailUtilities = ProductOfferDetailsUtils.fromLoanApp(loanApp as ILoanApplication);

				this.identificationType = this.documentSubmitService.getIdentificationType(idTypes);

				this.setVisibilityFlags(); // should be called before filterVisibleBouncedSections
				this.splMaxLoanAmount = this.documentSubmitService.getSplMaxLoanAmount();

				this.showSection = this.filterVisibleBouncedSections(this.loanAppService.getEditableSectionFlags());
				this.showBouncedResubmitSection = this.loanAppService.isApplicationStatusBounced() && !this.showSection.count;

				this.autoVerifyScenario = this.documentSubmitService.getAutoVerifyScenario();

				// get the customer home state
				const homeState = AddressUtils.getHomeAddress(addrList)?.state?.toUpperCase();
				this.isWI = homeState === 'WI';

				this.getDmvAcceptedDocListForState(homeState, multiOfferStatesList, idTypes);
				this.documentDeleteEnabled = documentDeleteEnabled?.value || false;
				this.showDeleteDocumentButton = this.documentSubmitService.showDeleteDocumentButton(
					documentDeleteEnabled?.value || false,
					this.hasUploadedDocuments()
				);
			}
		});
		this.subscription.add(loanAppSub);

		const fileUploadObserve$ = combineLatest([
			this.fileUploadService.fileUploads$,
			this.showPhoto$,
			this.hasDocumentRequirementSection$
		]);
		const fileUploadSub = fileUploadObserve$.subscribe({
			next: ([fileInfo]) => {
				this.fileInfo = fileInfo || {};
				this.showDeleteDocumentButton = this.documentSubmitService.showDeleteDocumentButton(
					this.documentDeleteEnabled,
					this.hasUploadedDocuments()
				);
			}
		});
		this.subscription.add(fileUploadSub);

		const keyParams = {
			CONFIRM_SUBMIT_MODAL: null
		};
		const translocoSub = this.translocoService.selectTranslateObject(keyParams).subscribe({
			next: (result) => {
				this.confirmSubmitTranslations = result[0];
			}
		});
		this.subscription.add(translocoSub);

		this.mobileService
			.getApplicantConsent(this.loanAppService.loanApplicationId, this.applicant.getCurrentApplicantIndex())
			.subscribe({
				next: (rsp) => {
					this.applicantConsent = rsp.loanAppAuthorization && rsp.applicantWirelessConsent;
				}
			});

		this.setOauthVisibilitySection();
	}

	filterVisibleBouncedSections(showSection): IShowBounceAttention {
		this.showVehicleRegistration = this.showVehicleRegistration && !this.isOnlyDmvIdBounce();
		this.showDMVReqIdProof = this.isDmvSectionBounced(BounceReasonsEnum.vehicleRegistration);

		const sectionMap = {
			showPhotoIdItem: this.showIdentification,
			showAddressItem: this.showAddress,
			showIncomeItem: this.showIncome,
			showReferencesItem: null,
			showBankAccountItem: this.showBank,
			showPhotoItem: this.showPhotoSource.getValue(),
			showVehiclePhotos: this.showVehiclePhotos,
			showVehicleRegistration: this.showVehicleRegistration,
			showVehicleDriversLicense: this.showVehicleDriversLicense,
			showDMVReqIdProof: this.showDMVReqIdProof,
			count: true,
			referencesOnly: true
		};

		const filteredVisibleBouncedSections = Object.keys(showSection)
			.filter((key) => sectionMap[key])
			.reduce((obj, key) => {
				obj[key] = showSection[key];
				return obj;
			}, {} as IShowBounceAttention);

		filteredVisibleBouncedSections.count = Object.keys(filteredVisibleBouncedSections).length - 2;

		return filteredVisibleBouncedSections;
	}

	private isDmvSectionBounced(bounceReason): boolean {
		let editableSectionArr: string[] = this.loanAppService.getEditableSections();
		let bounceReasons = this.loanAppService.getBounceReasons();
		let bounceReasonsList = bounceReasons ? Object.keys(bounceReasons) : [];

		return editableSectionArr.includes(bounceReason) && bounceReasonsList.includes(DmvIdSectionKey);
	}

	private hasUploadedDocuments(): boolean {
		const fileUploads = this.fileUploadService.getFileUploads();
		const fileCount = Object.keys(fileUploads).reduce((acc: number, cur: string) => {
			if (cur !== FileUploadType.selfie) {
				const finishedFileUploads = this.finishedFileUploadsPipe.transform(fileUploads[cur]) as IFileUpload[];
				acc += finishedFileUploads.length;
			}
			return acc;
		}, 0);

		return fileCount > 0;
	}

	private getDmvAcceptedDocListForState(homeState: string, dmvResp: any, idTypes: IMetadata[]) {
		let dmvIdTypesList: any = this.getIdTypesForState(homeState, dmvResp.value.dmvIdTypes);
		let isValidDmvId = Boolean(
			dmvIdTypesList && this.identificationType && dmvIdTypesList.includes(this.identificationType.key + '')
		);
		let editableSectionArr: string[] = this.loanAppService.getEditableSections();

		let isSPLPreApproved = this.productOfferDetailUtilities.isPreApprovedProduct(
			ProductCategoriesEnum.securedPersonalLoan
		);
		let isSPLBounced = this.productOfferDetailUtilities.isBouncedProduct(ProductCategoriesEnum.securedPersonalLoan);
		let isDMVIdInEditable = editableSectionArr.length > 0 && editableSectionArr.includes(DmvIdSectionKey);
		let isMultiOfferEnabledForState = this.isMultiOfferEnabledForState(
			homeState,
			dmvResp.value.splIdVerificationEnabledStates
		);
		let identificationRequiredFlag = this.loanAppService.isIdentificationRequired();
		let isEditableSectionMissing = editableSectionArr.length === 0;
		const issuingStateCheckRequiredStates: string[] = dmvResp?.value?.issuingStateCheckRequiredStates?.split(',');
		const issuingState = this.applicant.getIdentificationDocument()?.issuingState;
		const idType = this.applicant.getIdentificationDocument()?.type;
		const isDLIssuingStateInCrossStateReqList = issuingStateCheckRequiredStates?.includes(homeState);

		if (
			this.checkToDisplayDMVIdProofSection(
				isValidDmvId,
				isEditableSectionMissing,
				isDMVIdInEditable,
				isSPLPreApproved,
				isSPLBounced,
				identificationRequiredFlag,
				isMultiOfferEnabledForState,
				isDLIssuingStateInCrossStateReqList,
				homeState,
				issuingState,
				parseInt(idType)
			)
		) {
			this.showDMVReqIdProof = true;
			// need to update this object to showup in attention section
			if (!this.showSection.showDMVReqIdProof) {
				this.updateDmvAttentionObject();
			}
			this.dmvAcceptedIdList = this.getDmvAcceptedIdList(
				idTypes,
				dmvIdTypesList,
				homeState,
				isDLIssuingStateInCrossStateReqList
			);
		}
	}

	private checkToDisplayDMVIdProofSection(
		isValidDmvId: boolean,
		isEditableSectionMissing: boolean,
		isDMVIdInEditable: boolean,
		isSPLPreApproved: boolean,
		isSPLBounced: boolean,
		identificationRequiredFlag: boolean,
		isMultiOfferEnabledForState: boolean,
		isDLIssuingStateInCrossStateReqList: boolean,
		homeState: string,
		issuingState: string,
		idType: number
	) {
		return (
			(((!isValidDmvId && (isEditableSectionMissing || isDMVIdInEditable) && (isSPLPreApproved || isSPLBounced)) ||
				(!identificationRequiredFlag && isSPLPreApproved)) &&
				isMultiOfferEnabledForState) ||
			(isDLIssuingStateInCrossStateReqList && homeState !== issuingState && idType === this.US_DL_ID)
		);
	}

	updateDmvAttentionObject(): void {
		const bounceReasonObject = this.loanAppService.getBounceReasons();
		this.showSection.showDMVReqIdProof = bounceReasonObject['VEHICLE_REGISTRATION'];
		this.showSection.count = this.showSection.count + 1;
		this.showBouncedResubmitSection = this.loanAppService.isApplicationStatusBounced() && !this.showSection.count;
	}

	getDmvAcceptedIdList(
		idTypes: any,
		dmvIdTypesList: any,
		homeState: string,
		isDLIssuingStateInCrossStateReqList: boolean
	): any {
		let docList: string[] = [];
		dmvIdTypesList.forEach((dmvIdType) => {
			let idElement = idTypes.find((element) => element.key === parseInt(dmvIdType));
			if (idElement) {
				idElement.key == this.US_DL_ID && isDLIssuingStateInCrossStateReqList
					? docList.push(
							`${idElement.text} ${this.translocoService.translate('DOCUMENT_SUBMIT.issuedIn')} ${homeState}`
					  )
					: docList.push(idElement.text);
			}
		});
		return docList;
	}

	isMultiOfferEnabledForState(homeState: string, splIdVerificationEnabledStates: any): boolean {
		let stateList = splIdVerificationEnabledStates?.split(',');
		return Boolean(stateList?.includes(homeState));
	}

	getIdTypesForState(homeState: string, dmvIdTypes: any) {
		let homeStateElement = dmvIdTypes?.find((element) => element.state === homeState);
		return homeStateElement ? homeStateElement.idTypes?.split(',') : null;
	}

	ngOnDestroy(): void {
		if (this.dropOffOnDocUpload) {
			const plaidEvent = {
				eventType: EventDataTagTypeEnum.documentEventType,
				value1: EventDataTagValueEnum.documentValue
			};
			const tagEvent = {
				tealium_event: EventDataTagTypeEnum.documentEventType,
				event_action: EventDataTagValueEnum.documentValue
			};
			this.tagDataService.link({}, tagEvent);
			this.eventDataService.logEventDataToLoanApplication(plaidEvent);
		}
		this.subscription.unsubscribe();
	}

	getRequiredDocumentSectionCount(): number {
		return this.showContinueToUPLButton || this.loanAppService.isUPL() || !this.showSplDocSection
			? this.getRequiredDocumentSectionCountForUPL()
			: this.getRequiredDocumentSectionCountForSPL();
	}

	getRequiredDocumentSectionCountForSPL(): number {
		const sectionArray = [
			this.showAddress,
			this.showBank && !this.isBankConnected,
			this.showIdentification,
			this.showIncome && !this.isIncomeVerified,
			this.showVehicleDriversLicense,
			this.showVehiclePhotos,
			this.showVehicleRegistration,
			this.showDMVReqIdProof
		];
		return sectionArray.filter((item) => item).length;
	}

	getRequiredDocumentSectionCountForUPL(): number {
		const sectionArray = [
			this.showAddress,
			this.showBank && !this.isBankConnected,
			this.showIdentification,
			this.showIncome && !this.isIncomeVerified
		];
		return sectionArray.filter((item) => item).length;
	}

	panelEvent(event: any, type: FileUploadType): void {
		const section = `expand${upperFirst(type)}Section`;
		this[section] = event === 'opened';

		this.tagDataService.link(
			{},
			{
				event_label: type + ' ' + event,
				tealium_event: 'accordion click',
				event_action: event
			}
		);
	}

	toggleAchDisbursement(): void {
		this.dropOffOnDocUpload = false;
		if (this.loanAppService.isBtmEligible()) {
			this.bankAccountService.isBankConnected()
				? this.routingService.route(RoutingPathsEnum.funds)
				: this.routingService.route(RoutingPathsEnum.bankVerification);
		} else {
			this.routingService.route(RoutingPathsEnum.receiveFunds);
		}
	}

	private updateProductAction(productActionReqParams: IProductAction): Observable<any> {
		return this.mobileService.updateProductAction(productActionReqParams, this.loanAppService.loanApplicationId).pipe(
			switchMap(() => this.loanAppService.updateLoanApplication()),
			switchMap((loanApp) => {
				if (
					loanApp.applicationStatus === ApplicationStatusEnum.bounced ||
					loanApp.applicationStatus === ApplicationStatusEnum.preApproved
				) {
					return this.submitDocument();
				} else {
					this.dropOffOnDocUpload = false;
					this.routingService.route(RoutingPathsEnum.status);
					return of();
				}
			})
		);
	}

	confirmDialog(): Observable<any> {
		if (
			this.productOfferDetailUtilities?.hasSingleProductOffered() ||
			this.documentSubmitService?.checkIfValidStatusForSubmit() ||
			this.hasSingleProductWithIncomeSourceOptionsOffered()
		) {
			return this.submitDocument();
		} else if (this.documentSubmitService?.areAllBouncedProducts()) {
			if (!this.documentSubmitService?.isSplBounceValid() && this.documentSubmitService?.isUplBounceValid()) {
				this.showSplDocSection = true;
				return this.submitDocument();
			} else if (!this.showSplDocSection && this.applicant?.hasSecuredPauseEligible()) {
				return this.updateProductAction({ productAction: ProductActionsEnum.paused });
			} else {
				return this.submitDocument();
			}
		} else {
			this.tagDataService.link(
				{},
				{
					tealium_event: 'Plaid Income Verify Scenario III Submit',
					event_action: 'Submit'
				}
			);
			return this.submitDocument();
		}
	}

	submitDocument(): Observable<any> {
		const isUPLApproved = this.productOfferDetailUtilities.isApprovedProduct(
			ProductCategoriesEnum.unsecuredPersonalLoan
		);
		let editableSectionArr: string[] = this.loanAppService.getEditableSections();
		const isIdentificationOnlyBounce =
			editableSectionArr.length === 1 && editableSectionArr.includes(BounceReasonsEnum.identification);
		const vehicleRegistrationUploaded =
			this.showSplDocSection ||
			((this.documentSubmitService.areAllBouncedProducts() || isUPLApproved) && isIdentificationOnlyBounce);

		return this.mobileService.setSubmit(vehicleRegistrationUploaded, this.loanAppService.loanApplicationId).pipe(
			tap({
				next: (rsp) => {
					this.nidService.setNid('setCheckpoint', 'submit_final_review');
					this.nidService.setNid('applicationSubmit');
				},
				error: (err) => this.dialogService.openErrorDialog(err).subscribe()
			})
		);
	}

	cancelDialog(): Observable<any> {
		return of(false);
	}

	openDialog(confirm: () => Observable<any>, cancel: () => Observable<any>): Observable<any> {
		const data = {
			isSoftPullEnabled: this.isSoftPullEnabled,
			instructions: this.documentSubmitService.getSubmitInstructionsDescription(
				this.confirmSubmitTranslations,
				this.applicantConsent,
				this.isWI
			),
			isWI: this.isWI,
			earlyRenewalEligible: false,
			hasConsent: this.applicantConsent
		};
		const dialogRef = this.dialog.open(ConfirmSubmitDialogComponent, { data });
		this.tagDataService.link(
			{},
			{
				tealium_event: 'application_submit',
				event_action: 'submit',
				event_label: 'Form',
				event_category: 'rx_form',
				product_sub_category: this.sessionStorageService.get('productCategorySelection'),
				product_offer_status: this.tagDataService.getTealiumStringForOfferStatus(
					this.loanAppService.getLoanApp().productOfferDetails
				)
			}
		);
		return dialogRef.afterClosed().pipe(switchMap((rsp) => iif(() => rsp, confirm(), cancel())));
	}

	onSubmit(): void {
		// forward to the coApplicant, if there is one
		if (this.applicant.isCoApplicant()) {
			this.dropOffOnDocUpload = false;
			this.routingService.route(RoutingPathsEnum.almostDone);
		} else {
			this.openDialog(this.confirmDialog.bind(this), this.cancelDialog.bind(this)).subscribe((result) => {
				if (result) {
					this.dropOffOnDocUpload = false;
					this.routingService.route(RoutingPathsEnum.status);
				}
			});
		}
	}

	hasSingleProductWithIncomeSourceOptionsOffered(): boolean {
		return (
			this.productOfferDetailUtilities?.numberOfOffers === 2 &&
			!this.productOfferDetailUtilities?.hasSecuredPersonalLoan() &&
			this.loanAppService.getCurrentApplicant().incomeSourceOptionsOffered
		);
	}

	private setVisibilityFlags(): void {
		this.showPhotoSource.next(this.documentSubmitService.showPhoto());
		this.showBank =
			this.documentSubmitService.showBank(this.route, this.isBankConnected) && this.isAchDisbursementSelected;
		this.showIncome = this.documentSubmitService.showIncome();
		this.showAddress = this.documentSubmitService.showAddress();
		this.showIdentification = this.documentSubmitService.showIdentification();
		this.showVehicleDriversLicense = this.documentSubmitService.showVehicleDriversLicense();
		this.showVehiclePhotos = this.documentSubmitService.showVehiclePhotos();
		this.showVehicleRegistration = this.documentSubmitService.showVehicleRegistration();
		this.showReferencesItem = this.documentSubmitService.showReferencesItem();
		this.showSPLSection = this.documentSubmitService.showSplSection();
		this.showSplDocSection = this.showSPLSection;
		this.showContinueToUPLButton = this.documentSubmitService.canShowContinueToUPLButton(this.showSplDocSection);
		this.showSkipSPLOfferButton = this.documentSubmitService.canShowSkipSPLButton();
		this.showChangeAchDisbursement = this.documentSubmitService.hideChangeAchDisbursementLink();
		this.showReturnToOffers = this.documentSubmitService.showReturnToOffers();
		const applicant = ApplicantUtils.fromLoanApp(this.loanAppService.getLoanApp());
		this.isIncomeVerified =
			this.documentSubmitService.isAutoVerifiedIncome() && applicant.verifiedMonthlyIncomeSource !== 'OCR_VERIFICATION';
		this.showDMVReqIdProof = this.documentSubmitService.showDmvReqIdProof();
		this.showRejectDisbursementLink = this.isAchDisbursementSelected && !this.isBankConnected && this.showBank;

		this.skipOfferButtonText = this.showSplDocSection
			? this.translocoService.translate('DOCUMENT_SUBMIT.skipThisOffer')
			: this.translocoService.translate('DOCUMENT_SUBMIT.viewOfferDocuments');
		this.isOnlyReferenceItemBounced =
			this.showReferencesItem && this.documentSubmitService.isJustOneDocSubmissionGroupAllowed();

		this.sectionCount = this.getRequiredDocumentSectionCount();
		this.hasDocumentRequirementSectionSource.next(this.sectionCount > 0);
	}

	toggleSPLDocVisibility(): void {
		this.showSplDocSection = !this.showSplDocSection;
		this.skipOfferButtonText = this.showSplDocSection
			? this.translocoService.translate('DOCUMENT_SUBMIT.skipThisOffer')
			: this.translocoService.translate('DOCUMENT_SUBMIT.viewOfferDocuments');
		this.showContinueToUPLButton = this.documentSubmitService.canShowContinueToUPLButton(this.showSplDocSection);
		this.sectionCount = this.getRequiredDocumentSectionCount();
		this.hasDocumentRequirementSectionSource.next(this.sectionCount > 0);
	}

	returnToOffers(): void {
		this.dropOffOnDocUpload = false;
		this.routingService.route(RoutingPathsEnum.offerStatus);
	}

	onContinueToUPL(): void {
		//TODO: Check and implement the flow when withdrawSPL is false
		if (this.documentSubmitService.shouldWithdrawSPL()) {
			const productActionReqParams: IProductAction = { productAction: ProductActionsEnum.paused };
			this.mobileService
				.updateProductAction(productActionReqParams, this.loanAppService.loanApplicationId)
				.pipe(
					switchMap((data) => {
						return this.loanAppService.updateLoanApplication();
					}),
					map((loanApp) => {
						this.productOfferDetailUtilities = ProductOfferDetailsUtils.fromLoanApp(loanApp as ILoanApplication);
						let isSPLPreApproved = this.productOfferDetailUtilities.isPreApprovedProduct(
							ProductCategoriesEnum.securedPersonalLoan
						);
						let hasSPLOnly = this.productOfferDetailUtilities.hasOnlySPL();
						return hasSPLOnly && isSPLPreApproved;
					}),
					switchMap((isSPLOnly) => {
						if (isSPLOnly) {
							// user has submitted to UPL
							// but aat risk3 UPL was declined and SPL is set to pre-approved
							const message = `${this.translocoService.translate('DOCUMENT_SUBMIT.continueWithSplPopup.message')}
							<br/>
							<br/>
							<p>${this.translocoService.translate('DOCUMENT_SUBMIT.continueWithSplPopup.message2')}</p>`;
							const data = {
								title: this.translocoService.translate('DOCUMENT_SUBMIT.continueWithSplPopup.title'),
								message,
								confirmText: upperFirst(this.translocoService.translate('GLOBAL.next'))
							};
							return this.dialogService.openSimpleMessageDialog(data);
						} else {
							return of(this.routingService.routeFromLoanApp());
						}
					})
				)
				.subscribe();
		}
	}

	setOauthVisibilitySection() {
		if (this.documentSubmitService.isOauthFlow()) {
			const expandSection: string = this.documentSubmitService.getOauthExpandSection();
			switch (expandSection) {
				case FileUploadType.bank:
					this.expandBankSection = true;
					break;
				case FileUploadType.income:
					this.expandIncomeSection = true;
					break;
			}
		}
	}

	checkVerificationStatus(completedVerificationSection: FileUploadType): void {
		this.loanAppService.updateLoanApplication().subscribe();
	}

	updateDisbursementAch(isAchDisbursementSelected: boolean): Observable<ILoanApplication> {
		return this.mobileService
			.updateDisbursementAch(isAchDisbursementSelected, this.loanAppService.loanApplicationId)
			.pipe(switchMap(this.loanAppService.updateLoanApplication.bind(this.loanAppService, null, null)));
	}

	rejectDisbursementAch(isAchDisbursementSelected: boolean): Observable<ILoanApplication> {
		const updateDisbursement$ = this.loanAppService.isApplicationStatusBounced()
			? this.mobileService.updateDisbursement(true, this.loanAppService.loanApplicationId)
			: this.mobileService.updateDisbursementAch(isAchDisbursementSelected, this.loanAppService.loanApplicationId);
		return updateDisbursement$.pipe(
			switchMap(this.loanAppService.updateLoanApplication.bind(this.loanAppService, null, null))
		);
	}

	confirmAndRejectDisbursement(): void {
		const data: IMessageDialogData = {
			icon: 'op-info',
			title: this.translocoService.translate('DOCUMENT_SUBMIT.proofOfBankAccount.chooseNonAchDisbursementOption'),
			message: this.translocoService.translate('DOCUMENT_SUBMIT.proofOfBankAccount.areYouSureNonAchDisbursement'),
			confirmText: this.translocoService.translate('DOCUMENT_SUBMIT.proofOfBankAccount.confirmChange')
		};
		this.confirmRejectDisbursementDialog(data)
			.pipe(
				switchMap((rsp: boolean) => {
					return rsp ? this.rejectDisbursementAch(false) : of(false);
				})
			)
			.subscribe();
	}

	confirmRejectDisbursementDialog(data: IMessageDialogData): Observable<boolean> {
		const dialogRef = this.dialog.open(MessageDialogComponent, { data });
		return dialogRef.afterClosed().pipe(take(1));
	}

	collapseAllSections(): void {
		const sections = [
			'expandBankSection',
			'expandIncomeSection',
			'expandAddressesSection',
			'expandIdentificationSection',
			'expandVehicleRegistrationSection',
			'expandVehiclePhotosSection',
			'expandVehicleDriversLicenseSection',
			'expandDmvRequiredIdSection',
			'expandSelfieSection'
		];
		sections.forEach((item) => (this[item] = false));
	}

	removeDocument(): void {
		this.showRemoveDocumentDialog(this.deleteDocuments.bind(this), this.cancelDialog.bind(this)).subscribe({
			next: (result) => {
				if (result) {
					this.collapseAllSections();
					this.showDeleteDocumentButton = this.documentSubmitService.showDeleteDocumentButton(
						this.documentDeleteEnabled,
						this.hasUploadedDocuments()
					);
				}
			}
		});
	}

	showRemoveDocumentDialog(confirm: () => Observable<any>, cancel: () => Observable<any>): Observable<boolean> {
		const dialogRef = this.dialog.open(ConfirmRemoveDocumentDialogComponent);
		return dialogRef.afterClosed().pipe(switchMap((rsp) => iif(() => rsp, confirm(), cancel())));
	}

	deleteDocuments(): Observable<boolean> {
		if (!this.documentDeleteEnabled) {
			return of(false);
		}
		return this.fileUploadService.deleteUploads().pipe(
			map((fileUploads) => (isEmpty(fileUploads) ? true : false)),
			catchError((err) => this.dialogService.openErrorDialog(err))
		);
	}

	reloadPage(): void {
		this.router.routeReuseStrategy.shouldReuseRoute = () => false;
		this.router.onSameUrlNavigation = 'reload';
		this.router.navigate([this.router?.url]);
	}

	isOnlyDmvIdBounce() {
		let editableSectionArr: string[] = this.loanAppService.getEditableSections();
		let bounceReasonsLength = this.loanAppService.getCurrentApplicant().bounceReasons?.length;

		return bounceReasonsLength === 1 && editableSectionArr.includes(DmvIdSectionKey);
	}

	private openDenyAchDisbursementDialog(): Observable<boolean> {
		const dialogRef = this.dialog.open(CheckDeliveryNoticeComponent, {});
		return dialogRef.afterClosed().pipe(take(1));
	}
}
