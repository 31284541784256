<div class="document-submit-auto" *transloco="let t">
	<div *ngIf="initialized">
		<div class="title-remove-all">
			<op-masthead
				[customClass]="'head'"
				[title]="
					!isNoDocState
						? t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.title')
						: t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.readyToSubmit')
				"
			>
			</op-masthead>
			<div class="op-body-1-font remove-all op-right">
				<a
					href=""
					mat-flat-button
					color="link-md"
					onclick="return false"
					id="removeAllBtn"
					*ngIf="showDeleteDocumentButton && (automaticVerificationList?.length || showBouncedResubmitSection)"
					(click)="removeDocument()"
					[opTagClick]="'remove all documents'"
				>
					{{ t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.removeAllUploads') }}
				</a>
			</div>
		</div>
		<div class="op-mb-30" *ngIf="automaticVerificationList?.length && !showBouncedResubmitSection">
			{{ t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.pleaseProvide') }}
		</div>

		<div *ngIf="showBouncedResubmitSection" class="op-mtb-30" id="bounceResubmit">
			<div class="bounced-attention">
				<h4>{{ t('DOCUMENT_SUBMIT.noAddlDocsRequired') }}</h4>
				<ul>
					<li class="attention">{{ t('DOCUMENT_SUBMIT.submitAgainToContinue') }}</li>
				</ul>
			</div>
		</div>

		<op-content-container class="content">
			<div
				class="document-category"
				[@listAnimation]="automaticVerificationList.length"
				*ngFor="let fileType of automaticVerificationList; let i = index; trackBy: trackBy"
				id="docSubmitProofOf_{{ fileType.categoryType }}_{{ i }}"
			>
				<op-document-section-original
					id="docSubmitProofOf_{{ fileType.categoryType }}"
					tabindex="0"
					class="op-mb-20"
					[categoryType]="fileType.categoryType"
					[bounceAttention]="fileType.bounceAttention"
					[icon]="fileType.icon"
					[title]="t(fileType.title)"
					[description]="t(fileType.description)"
					(click)="fileType.click(fileType.categoryType)"
					[opTagClick]="'doc submit ' + fileType.categoryType + ' selected'"
				></op-document-section-original>
			</div>

			<div *ngIf="isNoDocState">
				<p>{{ t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.applicationReady') }}</p>
				<div
					class="document-category"
					[@listAnimation]="noDocList.length"
					*ngFor="let docType of noDocList; let i = index; trackBy: trackBy"
					id="noDoc_{{ docType.categoryType }}_{{ i }}"
				>
					<op-document-section-original
						id="noDocSection_{{ docType.categoryType }}"
						tabindex="0"
						class="op-mb-20"
						[categoryType]="docType.categoryType"
						[badge]="docType.badge"
						[icon]="docType.icon"
						[title]="t(docType.title)"
					></op-document-section-original>
				</div>
			</div>

			<div class="op-mt-30">
				<button
					mat-flat-button
					color="primary"
					class="submit-button op-full-width"
					name="submitBtn"
					id="submitBtn"
					[disabled]="disableSubmit"
					(click)="onSubmit()"
					[opTagClick]="'Submit for final review'"
				>
					{{ t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.submitFinalReview') }}
				</button>
			</div>

			<div class="op-mt-20">
				<button
					*ngIf="showReturnToOffers"
					mat-flat-button
					color="secondary"
					class="submit-button op-full-width"
					name="returnToOffersButton"
					id="returnToOfferBtn"
					[disabled]="false"
					(click)="returnToOffers()"
					[opTagClick]="'return to offers'"
				>
					{{ t('DOCUMENT_SUBMIT.returnToOffers') }}
				</button>
			</div>

			<div class="op-mt-20 op-center">
				<a
					href=""
					mat-flat-button
					color="link-md"
					class="ach-link"
					onclick="return false"
					id="chooseAchLink"
					*ngIf="!showChangeAchDisbursement"
					(click)="switchToAchDisbursement()"
					[opTagClick]="'switch to ach disbursement'"
				>
					{{ t('DOCUMENT_SUBMIT.proofOfBankAccount.chooseAchDisbursementOption') }}
				</a>
				<a
					href=""
					mat-flat-button
					color="link-md"
					class="ach-link"
					onclick="return false"
					id="chooseNonAchLink"
					*ngIf="showRejectDisbursementLink"
					(click)="confirmAndRejectDisbursement()"
					[opTagClick]="'reject ach disbursement'"
				>
					{{ t('DOCUMENT_SUBMIT.proofOfBankAccount.chooseNonAchDisbursementOption') }}
				</a>
			</div>
		</op-content-container>
	</div>
</div>
