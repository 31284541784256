import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { isEqual, isNil } from 'lodash';
import { NgxImageCompressService } from 'ngx-image-compress';
import { finalize, forkJoin, Observable, Subscription, switchMap } from 'rxjs';
import { DialogService } from 'src/app/core/services/dialog/dialog.service';
import {
	FileSideEnum,
	FileUploadService,
	FileUploadStatusEnum,
	FileUploadType,
	IFileUpload
} from 'src/app/core/services/file-upload/file-upload.service';
import { LanguageService } from 'src/app/core/services/language/language.service';
import { ConfigApiService, FileStatusEnum, IMetadata } from 'src/app/core/services/mobile-api';
import { TagDataService } from 'src/app/core/services/tag-data/tag-data.service';
import { opRequired } from 'src/app/shared/decorators/required.decorator';

@Component({
	selector: 'op-document-upload-original',
	templateUrl: './document-upload-original.component.html',
	styleUrls: ['./document-upload-original.component.scss']
})
export class DocumentUploadOriginalComponent implements OnInit, OnDestroy, OnChanges {
	@Input()
	@opRequired()
	id: string;

	@Input()
	@opRequired()
	categoryType: FileUploadType;

	@Input()
	@opRequired()
	documentClassification: IMetadata;

	@Input()
	@opRequired()
	showSingleFile: boolean;

	@Input()
	fileSide: FileSideEnum;

	@Input()
	@opRequired()
	selectedClassificationFiles: IFileUpload[];

	@Input()
	delayUpload: boolean;

	@Input()
	requiredFileType = 'application/pdf,image/jpg,image/jpeg,image/gif,image/png,image/bmp';

	@Input()
	country: string;

	@Input()
	attentionOverRide: boolean;

	uploadSub: Subscription;

	categoryFiles: IFileUpload[];

	singleFile: IFileUpload;
	isSelfie: boolean;
	uploadProgress: number = 0;

	attention = false;

	public fileStatusEnum: typeof FileStatusEnum = FileStatusEnum;

	isIncomeCompression: boolean = false;
	isIdCompression: boolean = false;
	incomeCompressionPercent: number = 40;
	idCompressionPercent: number = 25;
	compressionQuality = 75;

	minCompressionSize = 750000;

	subscription: Subscription = new Subscription();

	constructor(
		private fileUploadService: FileUploadService,
		private languageService: LanguageService,
		private dialogService: DialogService,
		private imageCompress: NgxImageCompressService,
		private tagDataService: TagDataService,
		private configApiService: ConfigApiService
	) {}

	ngOnChanges(changes: SimpleChanges): void {
		const selectedClassFiles = changes.selectedClassificationFiles;
		if (!isEqual(selectedClassFiles?.currentValue, selectedClassFiles?.previousValue) && !isNil(this.showSingleFile)) {
			this.updateClassFiles(selectedClassFiles?.currentValue);
		}
	}

	private getConfigValue<Type>(value: string | boolean, initial: Type): Type {
		if (isNil(value)) {
			return initial;
		}

		const type = typeof initial;
		if (type === 'boolean') {
			return Boolean(value) as Type;
		}
		if (type === 'number') {
			const v = Number(value);
			return !isNaN(v) ? (v as Type) : initial;
		}
		if (type === 'string') {
			return String(value) as Type;
		}

		return initial;
	}

	ngOnInit(): void {
		this.isSelfie = this.categoryType === FileUploadType.selfie;
		const incomeCompression$ = this.configApiService.configIncomeImageCompression();
		const idCompression$ = this.configApiService.configIdImageCompression();
		const configSub = forkJoin({
			incomeCompression: incomeCompression$,
			idCompression: idCompression$
		}).subscribe({
			next: (rsp) => {
				this.isIncomeCompression = this.getConfigValue(rsp?.incomeCompression?.value, this.isIncomeCompression);
				this.isIdCompression = this.getConfigValue(rsp?.idCompression.value, this.isIdCompression);
				this.incomeCompressionPercent = this.getConfigValue(
					rsp?.incomeCompression?.percentage,
					this.incomeCompressionPercent
				);
				this.idCompressionPercent = this.getConfigValue(rsp?.idCompression?.percentage, this.idCompressionPercent);
			}
		});

		this.subscription.add(configSub);
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	private tag(tag: string, action: string = 'click'): void {
		this.tagDataService.link(
			{},
			{
				tealium_event: tag,
				event_action: action,
				event_label: 'upload',
				event_category: 'rx_form'
			}
		);
	}

	private updateClassFiles(files: IFileUpload[]): void {
		this.categoryFiles = files;
		if (this.categoryFiles?.length && this.showSingleFile) {
			this.singleFile = this.categoryFiles.find((f) => f.side === this.fileSide);
		} else {
			this.singleFile = null;
		}

		this.attention =
			this.categoryFiles?.some((f) => f?.ocrInfo?.documentStatus === FileStatusEnum.review) || this.attentionOverRide;
		const sendEvent = Array.isArray(files)
			? files.find((file) => file?.status === FileUploadStatusEnum.uploading)
			: null;
		this.updateProgress(sendEvent);
	}

	onFileDropped(files): void {
		this.tag(`${this.id}${this.documentClassification.key}drop_upload`, 'drop');
		for (const item of files) {
			this.readAndCompressSelectedFile(item);
		}
	}

	private selectFile(file: File): void {
		if (this.delayUpload) {
			this.saveFileSelected(file);
		} else {
			this.uploadFileSelected(file);
		}
	}

	private fileReader(file: File): Observable<string> {
		return new Observable((observer) => {
			const reader = new FileReader();
			reader.onload = (event: any) => {
				observer.next(event.target.result);
				observer.complete();
			};
			reader.readAsDataURL(file);
		});
	}

	private compressFile(image: string): Observable<string> {
		const ratio =
			this.categoryType == FileUploadType.income ? this.incomeCompressionPercent : this.idCompressionPercent;
		return new Observable((observer) => {
			this.imageCompress.compressFile(image, -1, ratio, this.compressionQuality).then(
				(result: string) => {
					observer.next(result);
					observer.complete();
				},
				(result: string) => {
					observer.next(result);
					observer.complete();
				}
			);
		});
	}

	private dataURItoBlob(dataURI) {
		const byteString = window.atob(dataURI);
		const arrayBuffer = new ArrayBuffer(byteString.length);
		const int8Array = new Uint8Array(arrayBuffer);
		for (let i = 0; i < byteString.length; i++) {
			int8Array[i] = byteString.charCodeAt(i);
		}
		return new Blob([int8Array], { type: 'image/jpeg' });
	}

	private updateFileExtension(fileName: string, newExt: string): string {
		const pos = fileName.includes('.') ? fileName.lastIndexOf('.') : fileName.length;
		const fileRoot = fileName.substring(0, pos);
		return `${fileRoot}.${newExt}`;
	}

	private dataToFile(data: string, fileName: string): File {
		const imageBlob = this.dataURItoBlob(data.split(',')[1]);
		return new File([imageBlob], this.updateFileExtension(fileName, 'jpeg'), {
			type: 'image/jpeg'
		});
	}

	private shouldExcludeCompression(file: File): boolean {
		const isIncome = this.categoryType === FileUploadType.income && this.isIncomeCompression;
		const isId = this.categoryType === FileUploadType.identification && this.isIdCompression;
		const isPdf = file.type === 'application/pdf';
		const isMinSize = file?.size < this.minCompressionSize;
		return (!isIncome && !isId) || isPdf || isMinSize;
	}

	private readAndCompressSelectedFile(file: File): void {
		if (this.shouldExcludeCompression(file)) {
			this.selectFile(file);
			return;
		}

		this.fileReader(file)
			.pipe(switchMap((image) => this.compressFile(image)))
			.subscribe({
				next: (data: string) => {
					this.selectFile(this.dataToFile(data, file.name));
				}
			});
	}

	showErrorMessage(errorMessage: string): void {
		this.dialogService
			.openErrorDialog(errorMessage || this.languageService.translate('DOCUMENT_SUBMIT.errorDialog'))
			.subscribe();
	}

	onFileSelected(event): void {
		this.readAndCompressSelectedFile(event.target.files[0]);
		event.target.value = '';
	}

	private saveFileSelected(file: File): void {
		if (file) {
			this.fileUploadService
				.saveThumbnail(this.categoryType, file, {
					id:
						this.singleFile?.id && this.categoryType !== FileUploadType.identification
							? this.singleFile.id
							: this.findLatestPendingSide(this.fileSide)?.id,
					country: this.country,
					classification: String(this.documentClassification?.key),
					side: this.fileSide
				})
				.subscribe();
		}
	}

	private findLatestPendingSide(side: FileSideEnum): IFileUpload {
		return this.categoryFiles?.find((f) => f.side === side && f.status === FileUploadStatusEnum.pending);
	}

	uploadSingleFile(): Observable<IFileUpload[]> {
		return this.fileUploadService.uploadFiles([this.singleFile]).pipe(finalize(() => this.reset()));
	}

	uploadPendingFiles(): Observable<IFileUpload[]> {
		return this.fileUploadService.uploadPendingFiles(this.categoryType).pipe(finalize(() => this.reset()));
	}

	private updateProgress(sendEvent: IFileUpload): void {
		if (sendEvent?.status === FileUploadStatusEnum.uploading) {
			this.uploadProgress = sendEvent.progress;
		}
	}

	private updateFinished(sendEvent: IFileUpload): void {
		if (sendEvent?.status === FileUploadStatusEnum.finished) {
			this.uploadProgress = 0;
		}
		if (sendEvent?.status === FileUploadStatusEnum.error) {
			this.uploadProgress = 0;
			this.fileUploadService.removeErrorFiles(this.categoryType);
			this.showErrorMessage(sendEvent.error?.msg);
		}
	}

	private uploadFileSelected(file: File): void {
		if (file) {
			const upload$ = this.fileUploadService
				.sendFileV2(this.categoryType, file, {
					classification: String(this.documentClassification?.key),
					side: this.fileSide
				})
				.pipe(finalize(() => this.reset()));

			this.uploadSub = upload$.subscribe({
				next: (sendEvent: IFileUpload) => {
					this.updateFinished(sendEvent);
				}
			});
		}
	}

	cancelUpload(): void {
		this.uploadSub.unsubscribe();
		this.reset();
	}

	reset(): void {
		this.uploadSub = null;
	}
}
