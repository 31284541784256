import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import {
	AbstractControl,
	AbstractControlOptions,
	AsyncValidatorFn,
	FormBuilder,
	FormGroup,
	ValidationErrors,
	ValidatorFn,
	Validators
} from '@angular/forms';
import { Observable, of, Subscription, timer } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { AchBankAccountsService } from 'src/app/core/services/ach-bank-accounts/ach-bank-accounts.service';
import { ILoanApplication } from 'src/app/core/services/loan-application/loan-application.model';
import { LoanApplicationService } from 'src/app/core/services/loan-application/loan-application.service';
import { ISetAchBankAccountResult } from 'src/app/core/services/mobile-api';
import { MobileApiService } from 'src/app/core/services/mobile-api/mobile-api.service';
import { IssuingPartnerService } from 'src/app/core/services/partner/issuing-partner.service';
import { SessionStorageService } from 'src/app/core/services/storage/session-storage.service';
import { TagDataService } from 'src/app/core/services/tag-data/tag-data.service';

@Component({
	selector: 'op-manually-add-bank',
	templateUrl: './manually-add-bank.component.html',
	styleUrls: ['./manually-add-bank.component.scss']
})
export class ManuallyAddBankComponent implements OnInit, OnDestroy {
	@Output()
	saved = new EventEmitter<ISetAchBankAccountResult>();

	@Output()
	notSaved = new EventEmitter<void>();

	@Input()
	showContinue: boolean = true;

	@Input()
	showTermsOnNextLine: boolean = true;

	@Input()
	showAccountNameBannerOnTop: boolean = false;

	issuingOrganization: string;

	constructor(
		protected formBuilder: FormBuilder,
		protected mobileService: MobileApiService,
		protected loanAppService: LoanApplicationService,
		protected bankAccountService: AchBankAccountsService,
		protected tagDataService: TagDataService,
		protected sessionStorageService: SessionStorageService,
		protected issuingPartnerService: IssuingPartnerService
	) {
		this.createForm(this.formBuilder);
	}
	formGroup: FormGroup;

	POST_APPROVAL_STATUSES = ['APPROVED', 'TERMS_CONFIRMED'];

	private subscription = new Subscription();

	showRequiredDocsMessage: boolean;
	bankName: string;

	ngOnInit(): void {
		this.formGroup.valueChanges.subscribe((val) => {
			if (val?.routingNumber === null) {
				this.bankName = null;
			}
		});

		const loanSub = this.loanAppService.loanApplication$.pipe(filter(Boolean)).subscribe({
			next: (loanApp: ILoanApplication) => {
				this.issuingOrganization = this.issuingPartnerService.lender;
				this.showRequiredDocsMessage = this.POST_APPROVAL_STATUSES.includes(loanApp.applicationStatus);
			}
		});
		this.subscription.add(loanSub);
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	createForm(fb: FormBuilder): void {
		const accountNumberRegex = '^[0-9]{4,17}$'; // TODO: move to validator utility function
		this.formGroup = fb.group(
			{
				accountType: [null, [Validators.required]],
				routingNumber: [
					null,
					[Validators.required, Validators.minLength(9), Validators.maxLength(9)],
					[this.routingNumberValidator()]
				],
				accountNumber: [null, [Validators.required, Validators.pattern(accountNumberRegex)]],
				accountNumberConfirmed: [null, [Validators.required, Validators.pattern(accountNumberRegex)]],
				customerConfirms: [false, [Validators.requiredTrue]]
			},
			{
				validator: [this.mustMatch('accountNumber', 'accountNumberConfirmed')]
			} as AbstractControlOptions
		);
	}

	mustMatch(controlName: string, matchingControlName: string): ValidatorFn {
		return (formGroup: AbstractControl): ValidationErrors | null => {
			const ctrl = formGroup.get(controlName);
			const matchingCtrl = formGroup.get(matchingControlName);

			if (matchingCtrl.errors && !matchingCtrl.errors.mustMatch) {
				return;
			}

			if (ctrl.value !== matchingCtrl.value) {
				matchingCtrl.setErrors({ mustMatch: true });
			} else {
				matchingCtrl.setErrors(null);
			}
		};
	}

	routingNumberValidator(): AsyncValidatorFn {
		return (control: AbstractControl): Observable<ValidationErrors | null> => {
			const debounceTime = 500; //milliseconds
			return timer(debounceTime).pipe(
				switchMap(() =>
					this.mobileService.updateRoutingNumberValidation(control.value, this.loanAppService.loanApplicationId)
				),
				map((routing) => {
					this.bankName = routing.bankName;
					return null;
				}),
				catchError(() =>
					of({
						invalidRoutingNumber: true
					})
				)
			);
		};
	}

	onSubmit(): void {
		const post = this.formGroup.value;
		const bank = { ...post, routingNumberConfirmed: post.routingNumber };
		this.mobileService.setAchBankAccount(bank, this.loanAppService.loanApplicationId).subscribe({
			next: (rsp) => {
				this.tagDataService.link(
					{},
					{
						tealium_event: 'manual_bank_account_added',
						event_action: 'submit',
						event_label: 'Form',
						event_category: 'rx_form',
						product_sub_category: this.sessionStorageService.get('productCategorySelection'),
						product_offer_status: this.tagDataService.getTealiumStringForOfferStatus(
							this.loanAppService.getLoanApp().productOfferDetails
						)
					}
				);
				this.bankAccountService.refreshBankAccountList(this.loanAppService.loanApplicationId).subscribe();
				this.saved.emit(rsp);
				if (!rsp?.verified) {
					this.formGroup.reset();
				}
			},
			error: (err) => {
				this.notSaved.emit();
				this.formGroup.reset();
			}
		});
	}
}
