import { Component, OnDestroy, OnInit } from '@angular/core';
import { isEmpty } from 'lodash';
import { Observable } from 'rxjs';
import {
	FileUploadService,
	FileUploadStatusEnum,
	FileUploadType,
	IFileUpload
} from 'src/app/core/services/file-upload/file-upload.service';
import { LoanApplicationService } from 'src/app/core/services/loan-application/loan-application.service';
import { MetadataEnum } from 'src/app/core/services/metadata/metadata.model';
import { MetadataService } from 'src/app/core/services/metadata/metadata.service';
import { DocumentStatusCriteriaEnum, FileStatusEnum, IMetadata } from 'src/app/core/services/mobile-api';
import { RoutingService } from 'src/app/core/services/routing/routing.service';
import { SessionStorageService } from 'src/app/core/services/storage/session-storage.service';
import { TagDataService } from 'src/app/core/services/tag-data/tag-data.service';

import AutoVerificationUtils from '../auto-verification-utils-original';
import {
	BaseCategoryOriginalComponent,
	ProofStatusEnum
} from '../base-category-original/base-category-original.component';
import {
	DocumentStatusEventEnum,
	IDocumentStatusEvent
} from '../document-status-original/document-status-original.component';

@Component({
	selector: 'op-proof-of-income-original',
	templateUrl: './proof-of-income-original.component.html',
	styleUrls: ['./proof-of-income-original.component.scss']
})
export class ProofOfIncomeOriginalComponent extends BaseCategoryOriginalComponent implements OnInit, OnDestroy {
	readonly categoryType = FileUploadType.income;
	readonly storageKey = 'proofOfIncome';
	readonly metaClassification = MetadataEnum.IncomeDocumentClassification;

	documentClassificationList$: Observable<IMetadata[]>;
	canCancel: boolean = true;
	submitDisabled: boolean = true;

	public criteriaEnum: typeof DocumentStatusCriteriaEnum = DocumentStatusCriteriaEnum;
	showCriteria: boolean = false;
	criteria: {
		[key: string]: boolean;
	} = {};

	constructor(
		metadataService: MetadataService,
		routingService: RoutingService,
		fileUploadService: FileUploadService,
		sessionStorageService: SessionStorageService,
		loanAppService: LoanApplicationService,
		private tagDataService: TagDataService
	) {
		super(fileUploadService, sessionStorageService, routingService, metadataService, loanAppService);
	}

	ngOnInit(): void {
		super.ngOnInit();

		this.subscription = this.files$.subscribe({
			next: (files: IFileUpload[]) => {
				this.showCriteria = this.getShowCriteria(files, this.selectedDocumentClassification);
				this.attention = this.showAttention(this.reviewFiles, this.showCriteria, this.criteria);

				if (!isEmpty(files) && (this.isProofTypeSelection || this.isProofTypeEmpty) && !this.choosing) {
					this.proofState = ProofStatusEnum.status;
				}

				if (this.isProofTypeEmpty) {
					this.proofState = ProofStatusEnum.classificationSelection;
				}

				this.canCancel = this.fileUploadService.isAllFilesUploadNotStarted(
					this.filterClassificationFiles(files, this.selectedDocumentClassification)
				);

				this.submitDisabled = this.checkIfSubmitDisabled(files, this.selectedDocumentClassification);
			}
		});
	}

	ngOnDestroy(): void {
		super.ngOnDestroy();
	}

	private getShowCriteria(files: IFileUpload[], classification: IMetadata): boolean {
		this.criteria = {
			[DocumentStatusCriteriaEnum.zeroToThirtyDays]: files?.some((f) =>
				f?.ocrInfo?.criteriaMet?.some((c) => c === DocumentStatusCriteriaEnum.zeroToThirtyDays)
			),
			[DocumentStatusCriteriaEnum.thirtyOneToSixtyFiveDays]: files?.some((f) =>
				f?.ocrInfo?.criteriaMet?.some((c) => c === DocumentStatusCriteriaEnum.thirtyOneToSixtyFiveDays)
			)
		};

		const isBankStatement = classification?.key === 'BANK_STATEMENT';
		const verification = AutoVerificationUtils.isLastOcrFileVerification(files);
		const someOcrDone = files?.some(
			(f) =>
				f?.ocrInfo?.documentStatus === FileStatusEnum.review || f?.ocrInfo?.documentStatus === FileStatusEnum.complete
		);

		return someOcrDone && isBankStatement && !verification;
	}

	private showAttention(files: IFileUpload[], showCriteria: boolean, criteria: any): boolean {
		return (
			Boolean(files?.length) ||
			(showCriteria &&
				(!criteria?.[DocumentStatusCriteriaEnum.zeroToThirtyDays] ||
					!criteria?.[DocumentStatusCriteriaEnum.thirtyOneToSixtyFiveDays]))
		);
	}

	private tag(tag: string, action: string = 'click'): void {
		this.tagDataService.link(
			{},
			{
				tealium_event: tag,
				event_action: action,
				event_label: 'upload',
				event_category: 'rx_form'
			}
		);
	}

	protected updateClassFiles(categoryFiles: IFileUpload[], classification: IMetadata): void {
		const modified = AutoVerificationUtils.modifyOCRAttention(categoryFiles);
		this.selectedClassificationFiles = this.filterClassificationFiles(modified, classification);
		this.reviewFiles = this.filterOcrReview(this.selectedClassificationFiles);
		this.finishedFiles = modified?.filter((file) => file?.status === FileUploadStatusEnum.finished);
		this.showCriteria = this.getShowCriteria(this.selectedClassificationFiles, classification);
		this.attention = this.showAttention(this.reviewFiles, this.showCriteria, this.criteria);
	}

	private checkIfSubmitDisabled(files: IFileUpload[], docClassification: IMetadata): boolean {
		const finished = this.fileUploadService.isSomeFilesUploadFinished(
			this.filterClassificationFiles(files, docClassification)
		);
		return files?.length < 1 || !finished;
	}

	saveDocumentClassification(docClassification: IMetadata): void {
		this.choosing = true;
		this.selectedDocumentClassification = docClassification;
		this.proofState = ProofStatusEnum.upload;
		this.canCancel = this.fileUploadService.isAllFilesUploadNotStarted(this.selectedClassificationFiles);
		this.submitDisabled = this.checkIfSubmitDisabled(
			this.selectedClassificationFiles,
			this.selectedDocumentClassification
		);
	}

	onContinue(): void {
		this.fileUploadService.removeErrorFiles(this.categoryType);
		this.routingService.back();
	}

	onCancel(): void {
		this.fileUploadService.removeErrorFiles(this.categoryType);
		this.routingService.back();
	}

	onDocStatus(statusEvent: IDocumentStatusEvent): void {
		switch (statusEvent.event) {
			case DocumentStatusEventEnum.uploadMore:
				this.saveDocumentClassification(statusEvent.documentClassification);
				this.proofState = ProofStatusEnum.upload;
				break;
			case DocumentStatusEventEnum.addMore:
				this.choosing = true;
				this.proofState = ProofStatusEnum.classificationSelection;
				break;
		}
	}
}
