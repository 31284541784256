import { formatCurrency } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { isEmpty } from 'lodash';
import { combineLatest, forkJoin, Observable, of, Subscription, throwError } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { AchBankAccountsService } from 'src/app/core/services/ach-bank-accounts/ach-bank-accounts.service';
import { AutoPayBankAccountVerificationService } from 'src/app/core/services/auto-pay-bank-account-verification/auto-pay-bank-account-verification.service';
import { DateUtilsService } from 'src/app/core/services/date-utils/date-utils.service';
import { DialogService } from 'src/app/core/services/dialog/dialog.service';
import { EN_FAQ_LINK, ES_FAQ_LINK } from 'src/app/shared/constants/language-const';
import { LanguageService } from 'src/app/core/services/language/language.service';
import { DisbursementUtils } from 'src/app/core/services/loan-application/disbursement/disbursement-utils';
import { ILoanApplication } from 'src/app/core/services/loan-application/loan-application.model';
import { LoanApplicationService } from 'src/app/core/services/loan-application/loan-application.service';
import { ProductOfferDetailsUtils } from 'src/app/core/services/loan-application/product-offer/product-offer-details/product-offer-details-utils';
import {
	paymentFrequencyEnum,
	ProductCategoriesEnum
} from 'src/app/core/services/loan-application/product-offer/product/product.model';
import { MetadataEnum } from 'src/app/core/services/metadata/metadata.model';
import { MetadataService } from 'src/app/core/services/metadata/metadata.service';
import { ConfigApiService, IDebitCard, IMetadata, VehicleApiService } from 'src/app/core/services/mobile-api';
import { MobileApiService } from 'src/app/core/services/mobile-api/mobile-api.service';
import { OriginationPartnerService } from 'src/app/core/services/partner/origination-partner.service';
import { RoutingPathsEnum, RoutingService } from 'src/app/core/services/routing/routing.service';
import { SessionStorageService } from 'src/app/core/services/storage/session-storage.service';
import {
	EXISTING_LOAN_MISMATCH,
	EXISTING_LOAN_MISSING,
	EXISTING_LOAN_PAIDOFF,
	LOAN_TERMS_MISMATCH_ERROR
} from 'src/app/shared/constants/common-const';

import { FundingInfoUtils } from '../../core/services/loan-application/funding-info/funding-info-utils';
import { ProductSubStatusesEnum } from '../../core/services/loan-application/product-offer/product/product.model';
import { IPartnerResponse } from '../../core/services/mobile-api/partner-api/partner-api.model';
import { IDebitCardDetails, IDisbursementOptions } from 'src/app/core/services/mobile-api/mobile-api.model';
import {
	DisbursementChannelEnum,
	DisbursementType
} from 'src/app/core/services/loan-application/disbursement/disbursement.model';

@Component({
	selector: 'op-esign-confirm-term',
	templateUrl: './esign-confirm-term.component.html',
	styleUrls: ['./esign-confirm-term.component.scss']
})
export class EsignConfirmTermComponent implements OnInit, OnDestroy {
	fundsAvailability: string;
	cardType: string;
	institutionName: string;
	last4Debit: string;
	fundingInfo: string;
	bankAccountIds: any;
	constructor(
		private mobileService: MobileApiService,
		private loanAppService: LoanApplicationService,
		private routingService: RoutingService,
		private configApiService: ConfigApiService,
		private metadataService: MetadataService,
		private vehicleService: VehicleApiService,
		private dateUtilService: DateUtilsService,
		private dialogService: DialogService,
		private originationPartnerService: OriginationPartnerService,
		private languageService: LanguageService,
		private sessionStorageService: SessionStorageService,
		private bankAccountService: AchBankAccountsService,
		private autoPayBankAccountVerificationService: AutoPayBankAccountVerificationService
	) {}

	esignUser;
	loanTerms;
	productOfferDetailUtilities: ProductOfferDetailsUtils;
	disbursement: DisbursementUtils;

	isSPLSoftSelected: boolean;
	isSPL: boolean;
	isGCP: boolean;
	hasMultipleValidProductOffers: boolean;
	hasIneligibleVehicle: boolean;

	isMonthly: boolean;
	isSemiMonthly: boolean;
	isBiWeekly: boolean;

	showAch: boolean;
	hasDebit: boolean;
	rachAccountNumber: number;
	isBankValid: boolean;
	autoPayEligibility: boolean;

	weekdays: IMetadata[];
	selectedProductCategory: string;
	vehicleInformation: string;
	currentDate: string;
	partner: IPartnerResponse;
	shouldShowUpdateDisbursement: boolean = false;
	isPaymentsError: boolean;
	bankVerified: boolean = false;
	language: string;
	useTermTableWithPrincipalAmount: boolean = false;
	checkDebitAutoPayEnabled: boolean = false;

	private subscription = new Subscription();
	faqLink: string = EN_FAQ_LINK;

	ngOnInit(): void {
		this.currentDate = this.dateUtilService.format(new Date(), 'MM/dd/yyyy');

		const weekdays$ = this.metadataService.select(MetadataEnum.Weekday).pipe(
			tap((weekdays) => {
				this.weekdays = weekdays;
				if (this.esignUser) {
					this.esignUser.paymentDay = this.getDayOfWeekText();
				}
			})
		);

		this.languageService.langChanges$.subscribe({
			next: (lang) => {
				this.language = lang;
				this.faqLink = this.languageService.isSpanish ? ES_FAQ_LINK : EN_FAQ_LINK;
			}
		});

		const loanApp$ = this.loanAppService.loanApplication$.pipe(filter(Boolean));
		const sub = combineLatest([weekdays$, loanApp$])
			.pipe(
				map(([weekdays, loanApp]) => {
					this.weekdays = weekdays;
					if (this.esignUser) {
						this.esignUser.paymentDay = this.getDayOfWeekText();
					}

					this.productOfferDetailUtilities = ProductOfferDetailsUtils.fromLoanApp(loanApp);
					this.hasMultipleValidProductOffers = this.productOfferDetailUtilities.hasMultipleValidProductOffers();
					this.hasIneligibleVehicle = this.productOfferDetailUtilities.hasIneligibleVehicle();
					this.selectedProductCategory = this.getProductCategorySelection(
						loanApp,
						this.hasMultipleValidProductOffers,
						this.hasIneligibleVehicle
					);
					this.isSPLSoftSelected = ProductCategoriesEnum.securedPersonalLoan === this.selectedProductCategory;

					this.isSPL = this.loanAppService.isSPL() || (this.hasMultipleValidProductOffers && this.isSPLSoftSelected);
					this.isGCP = this.loanAppService.isGCP();

					this.disbursement = DisbursementUtils.fromLoanApp(loanApp);
					this.showAch = this.disbursement.isOnlineAch();
					this.rachAccountNumber = this.disbursement.rachAccountId;
					this.hasDebit = this.disbursement.isDebitCard();

					return loanApp;
				}),
				switchMap((loanApp: ILoanApplication) => this.getPartnerIfCashAmountExceeded(loanApp)),
				switchMap((loanApp: ILoanApplication) => (this.isSPL ? this.getVehicleInformation(loanApp.id) : of({}))),
				switchMap((loanApp: ILoanApplication) => this.forkJoinMobile(this.selectedProductCategory))
			)
			.subscribe({
				next: (combinedResponse) => {
					this.loanTerms = combinedResponse.loanTerms || this.loanAppService.getLoanApp()?.loanTerms;
					this.isMonthly = this.loanTerms?.paymentFrequency === paymentFrequencyEnum.monthly;
					this.isSemiMonthly = this.loanTerms?.paymentFrequency === paymentFrequencyEnum.semiMonthly;
					this.isBiWeekly = this.loanTerms?.paymentFrequency === paymentFrequencyEnum.biWeekly;
					this.useTermTableWithPrincipalAmount = combinedResponse?.termsTableConfig?.value || false;
					this.checkDebitAutoPayEnabled = combinedResponse?.checkDebitAutoPayEnabled?.value || false;
					this.setEsignUser(this.loanAppService.getLoanApp(), combinedResponse.bankAccount);

					if (this.disbursement.isRachEligible()) {
						this.mobileService
							.getVerifyBankAccount(combinedResponse.bankAccount.id, this.loanAppService.loanApplicationId)
							.subscribe({
								next: (rsp) => {
									this.isBankValid = rsp.status === 'ACTIVE';
								}
							});
					}
					if (this.disbursement.isDebitCard()) {
						this.mobileService
							.validatedebitCard(this.loanAppService.loanApplicationId, 0)
							.pipe(
								switchMap((response: IDebitCard) => {
									if (response.cardValid) {
										return this.mobileService.getDebitCard(this.loanAppService.loanApplicationId);
									} else {
										const disbursementParameters: IDisbursementOptions = {
											disbursementChannel: DisbursementChannelEnum.online,
											disbursementType: DisbursementType.cash
										};
										return this.mobileService.updateDisbursementChannel(
											disbursementParameters,
											this.loanAppService.loanApplicationId
										);
									}
								})
							)
							.subscribe({
								next: (debitCard: IDebitCardDetails) => {
									this.fundsAvailability = debitCard.fundsAvailability;
									this.cardType = debitCard.cardType;
									this.institutionName = debitCard.institutionName;
									this.last4Debit = debitCard.last4;
									this.fundingInfo = 'ESIGN_CONFIRM_TERM.' + this.fundsAvailability;
								},
								error: (error) => {
									console.error(error);
								}
							});
					}

					if (
						combinedResponse.bankAccountList.length &&
						(this.disbursement.disbursementType === DisbursementType.debit ||
							this.disbursement.disbursementType === DisbursementType.check)
					) {
						this.bankAccountIds = Array.from(
							combinedResponse.bankAccountList.map((accountIds) => {
								return accountIds.id;
							})
						);
						return this.autoPayBankAccountVerificationService
							.verifyBankAccounts(this.bankAccountIds, this.loanAppService.loanApplicationId)
							.subscribe((accounts) => {
								this.autoPayEligible(accounts.find((account) => account.status === 'ACTIVE'));
							});
					}
				}
			});
		this.subscription.add(sub);
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	forkJoinMobile(productCategory: string): Observable<any> {
		const bankAccount$ = this.mobileService.getAchBankAccount(this.loanAppService.loanApplicationId);
		const bankAccountList$ = this.bankAccountService.refreshBankAccounts(this.loanAppService.loanApplicationId);
		const loanTerms$ = this.mobileService.getLoanTerms(productCategory, this.loanAppService.loanApplicationId);
		const onlineNotifyEnabled$ = this.configApiService.configOnlineNotificationEnabled();
		const termsTableConfig$ = this.configApiService.configTermsTable();
		const onlineNotifyNewAppEnabled$ = this.configApiService.configOnlineNotificationNewApplicantEnabled();
		const checkDebitAutoPayEnabled$ = this.configApiService.configPushToDebitCheckAutoPay();
		return forkJoin({
			onlineNotifyEnabled: onlineNotifyEnabled$,
			termsTableConfig: termsTableConfig$,
			onlineNotifyNewAppEnabled: onlineNotifyNewAppEnabled$,
			bankAccount: bankAccount$,
			bankAccountList: bankAccountList$,
			loanTerms: loanTerms$,
			checkDebitAutoPayEnabled: checkDebitAutoPayEnabled$
		});
	}

	getDayOfWeekText(): string {
		const dayMap = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'];
		let dayOfWeek = 'ERROR';
		let loanTerms = this.loanTerms || this.loanAppService.getLoanApp().loanTerms;
		let paymentDay = loanTerms?.paymentDay || '';

		for (let k in dayMap) {
			if (paymentDay === dayMap[k]) {
				dayOfWeek = this.weekdays[k].text;
				break;
			}
		}
		return dayOfWeek;
	}

	getPartnerIfCashAmountExceeded(loanApp: ILoanApplication): Observable<ILoanApplication> {
		const fundingInfo = FundingInfoUtils.fromLoanApp(loanApp);
		this.shouldShowUpdateDisbursement = this.disbursement.isPartnerCash() && fundingInfo.isPartnerCashAmountExceeded();
		if (this.shouldShowUpdateDisbursement) {
			return this.originationPartnerService.getPartner(loanApp.originationChannelOrganization).pipe(
				tap((partner) => (this.partner = partner)),
				map(() => loanApp)
			);
		}
		return of(loanApp);
	}

	// TODO: split up.
	setEsignUser = function (loanApp: ILoanApplication, bankData: any): void {
		const currentApplicant = this.loanAppService.getCurrentApplicant();
		const paymentDayMonthly = loanApp.loanTerms?.paymentDate1;
		const paymentDaySemiMonthlyValue1 = this.loanTerms.paymentDate1;
		const paymentDaySemiMonthlyValue2 = this.loanTerms.paymentDate2;

		let numberOfPayments;
		if (this.loanTerms.numberOfPayments === null) {
			numberOfPayments = 'ERROR';
			this.isPaymentsError = true;
		} else {
			numberOfPayments = this.loanTerms.numberOfPayments;
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			this.isPaymentsError = false;
		}

		const accountNumber = `X-${bankData?.accountNumber || ''}`;
		this.esignUser = {
			loanApplicationId: loanApp.id,
			loanAmount: this.loanTerms.loanAmount,
			paymentDay: this.getDayOfWeekText(),
			paymentDayMonthly: paymentDayMonthly,
			paymentDaySemiMonthlyValue1: paymentDaySemiMonthlyValue1,
			paymentDaySemiMonthlyValue2: paymentDaySemiMonthlyValue2,
			paymentTerm: this.loanTerms.paymentTerm,
			numberOfPayments: numberOfPayments,
			apr: ((this.loanTerms.realApr * 10000) / 100).toFixed(2) + '%',
			originationFee: this.loanTerms.originationFee,
			originationFeePercent: this.loanTerms.originationFeePct,
			stampTax: this.loanTerms.feeAmount1,
			paymentAmount: this.loanTerms.paymentAmount,
			accountType: bankData?.accountType,
			accountNumber: accountNumber,
			depositedAmount: this.loanTerms.disbursementAmount,
			existingBalance: this.loanTerms.payoffAmount,
			signers: [
				{
					clientId: currentApplicant.clientId,
					firstName: currentApplicant.firstName,
					lastName: currentApplicant.lastName,
					emailAddress: currentApplicant.emailAddress,
					phoneNumber: null,
					authenticationMethod: null
				}
			]
		};

		if (this.loanAppService.hasCoApplicant()) {
			const coApplicant = this.loanAppService.getCoApplicant();
			this.esignUser.signers.push({
				clientId: coApplicant.clientId,
				firstName: coApplicant.firstName,
				lastName: coApplicant.lastName,
				emailAddress: coApplicant.emailAddress,
				phoneNumber: null
			});
		}
		if (this.useTermTableWithPrincipalAmount) {
			this.esignUser.principalAmount = this.loanTerms.loanAmount + this.loanTerms.originationFee;
		}
	};

	private checkProductSelection(): Observable<any> {
		if (this.hasMultipleValidProductOffers || this.hasIneligibleVehicle) {
			const selection = {
				productCategory: this.selectedProductCategory,
				confirmedProductSelection: true
			};
			return this.mobileService.setProductSelection(selection, this.loanAppService.loanApplicationId);
		} else {
			return of({});
		}
	}

	private checkConfirmLoanTerms(): Observable<any> {
		return this.loanAppService.isApplicationStatusApproved()
			? this.mobileService.updateConfirmLoanTerms(this.loanAppService.loanApplicationId)
			: of({});
	}

	onSubmit(): void {
		this.mobileService
			.updateNotificationComplete(this.loanAppService.loanApplicationId)
			.pipe(
				switchMap(() => this.checkProductSelection()),
				switchMap(() => this.checkConfirmLoanTerms()),
				switchMap(() => (this.isSPL ? this.loanAppService.updateLoanApplication() : of(null))),
				switchMap(() => this.mobileService.getRachEnrollmentEligibility(this.loanAppService.loanApplicationId)),
				map((eligibility) => Boolean(eligibility?.eligible))
			)
			.subscribe({
				next: (rachEnrollmentEligibility: boolean) => {
					this.disbursement = DisbursementUtils.fromLoanApp(this.loanAppService.getLoanApp());
					if (
						(this.disbursement.hasBankAccountAndAutoPayNotComplete() && this.isBankValid) ||
						this.loanAppService.isRachWithoutAchOptInEligible() ||
						this.autoPayEligibility ||
						(rachEnrollmentEligibility && (this.disbursement.isOnlineCheck() || this.disbursement.isDebitCard()))
					) {
						this.showMessageBeforeRedirect(RoutingPathsEnum.esignAutoPay);
					} else if (
						this.disbursement.onlineNotificationEnabled &&
						this.disbursement.isChannelAnyStore() &&
						!this.routingService.isSplStorelessEligibleWithStatus(ProductSubStatusesEnum.complete)
					) {
						this.showMessageBeforeRedirect(RoutingPathsEnum.termsConfirmed);
					} else {
						this.routingService.route(RoutingPathsEnum.esignSignDocument);
					}
				},
				error: (err) => {
					const EXISTING_LOAN_ERR_ARR = [EXISTING_LOAN_MISSING, EXISTING_LOAN_PAIDOFF, EXISTING_LOAN_MISMATCH];
					if (err.error[0]?.id === LOAN_TERMS_MISMATCH_ERROR) {
						this.openLoanTermsMismatchErrorMessage();
					} else if (EXISTING_LOAN_ERR_ARR.includes(err.error[0]?.id)) {
						this.existingLoanMissingErrorMessage();
					} else {
						this.dialogService.openErrorDialog(err).subscribe({
							next: () => {
								if (err.status === 400) {
									this.routingService.route(RoutingPathsEnum.adjustTerms);
								}
							}
						});
					}
				}
			});
	}

	existingLoanMissingErrorMessage(): void {
		this.dialogService
			.openErrorDialogWithTitle(
				this.languageService.translate('DIALOG_MESSAGE.genericTitle'),
				this.languageService.translate('ERROR_MESSAGE.genericMessage')
			)
			.subscribe();
	}

	openLoanTermsMismatchErrorMessage(): void {
		this.dialogService
			.openErrorDialogWithTitle(
				this.languageService.translate('LOAN_TERMS_MISMATCH_ERROR_MODAL.title'),
				this.languageService.translate('LOAN_TERMS_MISMATCH_ERROR_MODAL.message')
			)
			.subscribe({
				next: () => {
					this.routingService.route(RoutingPathsEnum.adjustTerms);
				}
			});
	}

	onAdjustTerms(): void {
		this.routingService.route(RoutingPathsEnum.adjustTerms);
	}

	showMessageBeforeRedirect(nextPage: RoutingPathsEnum): void {
		if (this.shouldShowUpdateDisbursement) {
			this.dialogService
				.openMessageDialog(
					{
						icon: 'op-alert',
						title: this.languageService.translate('DIALOG_MESSAGE.message'),
						message: this.languageService.translate('ESIGN_CONFIRM_TERM.updateCashDisbursementToACH', {
							cashLimit: formatCurrency(this.partner.maxCashDisbursementAmount, 'en', '$', 'USD', '0.0'),
							partner: this.partner.brand
						})
					},
					() => of({}),
					() => of({})
				)
				.subscribe({
					next: () => {
						this.routingService.route(nextPage);
					}
				});
		} else {
			this.routingService.route(nextPage);
		}
	}

	getProductCategorySelection(
		loanApp: ILoanApplication,
		hasMultipleProductsOffered: boolean,
		hasIneligibleVehicle: boolean
	): string {
		const loanTermsProductCategory = loanApp.productCategory;
		if (
			ProductCategoriesEnum.personalLoan === loanTermsProductCategory &&
			(hasMultipleProductsOffered || hasIneligibleVehicle)
		) {
			return this.sessionStorageService.get('productCategorySelection');
		}
		return loanTermsProductCategory;
	}

	getVehicleInformation(loanApplicationId: number): Observable<any> {
		return this.vehicleService.getVehicle(loanApplicationId).pipe(
			filter(Boolean),
			take(1),
			tap((vehicle) => {
				if (vehicle?.makeStr && vehicle?.modelStr) {
					this.vehicleInformation = `${vehicle?.year} ${vehicle?.makeStr} ${vehicle?.modelStr}`;
				}
			})
		);
	}

	autoPayEligible(eligibility) {
		this.autoPayEligibility = !isEmpty(eligibility);
	}
}
