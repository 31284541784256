import { cloneDeep, isEmpty } from 'lodash';
import {
	FileUploadStatusEnum,
	FileUploadType,
	IFileUpload
} from 'src/app/core/services/file-upload/file-upload.service';
import { DocumentStatusCriteriaEnum, FileStatusEnum } from 'src/app/core/services/mobile-api';

export const frontBackList = ['DRIVERS_LICENSE', 'STATE_ID', 'MATRICULA_CONSULAR'];
export const singleFileList = ['PASSPORT'];
export const incomeBankStatementCriteria = [
	DocumentStatusCriteriaEnum.zeroToThirtyDays,
	DocumentStatusCriteriaEnum.thirtyOneToSixtyFiveDays
];

export default class AutoVerificationUtils {
	static sortOcrFiles(files: IFileUpload[]): IFileUpload[] {
		const filesClone = cloneDeep(files);
		const sorted = filesClone?.sort((a, b) => (a.createdDate < b.createdDate ? 1 : -1)) || [];
		return sorted;
	}

	static lastOcrFile(files: IFileUpload[]): IFileUpload {
		const sorted = this.sortOcrFiles(files);
		return sorted[0];
	}

	/**
	 * A file is considered in verification if criteriaMet is empty and documentStatus is complete
	 *
	 * @static
	 * @param {IFileUpload[]} files
	 * @return {*}  {boolean}
	 * @memberof AutoVerificationUtils
	 */
	static isLastOcrFileVerification(files: IFileUpload[]): boolean {
		if (files?.every((file) => !file.createdDate)) {
			return false;
		}
		const lastFile = this.lastOcrFile(files);
		return lastFile?.ocrInfo?.documentStatus === FileStatusEnum.complete && isEmpty(lastFile?.ocrInfo?.criteriaMet);
	}

	static hasIncomeBankStatement(files: IFileUpload[]): boolean {
		return files.some((file) => file.type === FileUploadType.income && file?.classification === 'BANK_STATEMENT');
	}

	static filterIncomeBankStatementFiles(files: IFileUpload[]): IFileUpload[] {
		return files.filter((file) => file.type === FileUploadType.income && file?.classification === 'BANK_STATEMENT');
	}

	/**
	 * each incomeBankStatementCriteria must be included someplace in files criteriaMet
	 *
	 * @static
	 * @param {IFileUpload[]} files
	 * @return {*}  {boolean}
	 * @memberof AutoVerificationUtils
	 */
	static isIncomeBankStatementCriteriaMet(files: IFileUpload[]): boolean {
		const incomeBankStatementFiles = this.filterIncomeBankStatementFiles(files);
		const criteriaMet = incomeBankStatementCriteria.every((criteria) =>
			incomeBankStatementFiles?.some((file) => file?.ocrInfo?.criteriaMet?.includes(criteria))
		);
		return criteriaMet;
	}

	/**
	 * one incomeBankStatementCriteria must be included someplace in files criteriaMet
	 *
	 * @static
	 * @param {IFileUpload[]} files
	 * @return {*}  {boolean}
	 * @memberof AutoVerificationUtils
	 */
	static isIncomeBankStatementCriteriaPartiallyMet(files: IFileUpload[]): boolean {
		const incomeBankStatementFiles = this.filterIncomeBankStatementFiles(files);
		const criteriaMet = incomeBankStatementCriteria.some((criteria) =>
			incomeBankStatementFiles?.some((file) => file?.ocrInfo?.criteriaMet?.includes(criteria))
		);
		return criteriaMet;
	}

	/**
	 * only look at the last files ocr review status
	 *
	 * @static
	 * @param {IFileUpload[]} files
	 * @return {*}  {IFileUpload[]}
	 * @memberof AutoVerificationUtils
	 */
	static modifyOCRAttention(files: IFileUpload[]): IFileUpload[] {
		const attentionId: [string, string] = [null, null];

		const filesClone = cloneDeep(files);
		if (filesClone?.every((file) => !file.createdDate)) {
			return filesClone;
		}

		const enumOrder = Object.values(FileStatusEnum);
		filesClone?.sort(
			(a, b) => enumOrder.indexOf(a?.ocrInfo?.documentStatus) - enumOrder.indexOf(b?.ocrInfo?.documentStatus)
		);
		const sorted = filesClone?.sort((a, b) => (a.createdDate < b.createdDate ? 1 : -1)) || [];
		const [first, second] = sorted;
		if (frontBackList.includes(String(first?.classification))) {
			if (first?.ocrInfo?.documentStatus === FileStatusEnum.review) {
				attentionId[0] = first.id;
			}
			if (second?.ocrInfo?.documentStatus === FileStatusEnum.review) {
				attentionId[1] = second.id;
			}
		} else {
			if (first?.ocrInfo?.documentStatus === FileStatusEnum.review) {
				attentionId[0] = first.id;
				attentionId[1] = first.id;
			}
		}
		return sorted.map((file) => {
			if (
				file.id !== attentionId[0] &&
				file.id !== attentionId[1] &&
				file.ocrInfo?.documentStatus === FileStatusEnum.review
			) {
				file.ocrInfo.documentStatus = FileStatusEnum.complete;
			}
			return file;
		});
	}
}
