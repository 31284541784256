<div class="income-verification" *transloco="let t">
	<div *ngIf="!incomePartiallyVerified">
		<op-masthead
			*ngIf="!incomeVerified"
			[title]="t('INCOME_VERIFICATION.notVerified', { name: firstName })"
		></op-masthead>
		<op-masthead *ngIf="incomeVerified" [title]="t('INCOME_VERIFICATION.verified', { name: firstName })"></op-masthead>
		<op-content-container class="content">
			<div class="plaid-border">
				<op-plaid-bank-transaction
					(next)="onNext($event)"
					(skip)="skipPlaid($event)"
					[sectionType]="'income'"
					[bankOnly]="false"
					[accountListFilter]="incomeFilter"
				></op-plaid-bank-transaction>
			</div>
			<op-website-terms class="op-mt-10"></op-website-terms>
		</op-content-container>
	</div>
	<div *ngIf="incomePartiallyVerified">
		<op-masthead [title]="t('INCOME_VERIFICATION.partiallyVerified', { name: firstName })"></op-masthead>
		<div>{{ t('INCOME_VERIFICATION.INCOME_SELECTION.details') }}</div>
		<op-content-container class="content">
			<op-income-selection
				[verifiedMonthlyIncomeAmount]="verifiedMonthlyIncomeAmount"
				[statedMonthlyIncomeAmount]="statedMonthlyIncomeAmount"
				(next)="onNext($event)"
			></op-income-selection>
		</op-content-container>
	</div>
</div>
