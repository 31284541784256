import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { enUS, es } from 'date-fns/locale';

import { formatDate, getDifferenceInDays, parseISODate, parseISODateTime } from './date-utils';

/**
 * Utility service to wrap the localization of date formatting.
 * https://date-fns.org/v2.21.1/docs/I18n-Contribution-Guide
 *
 * @export
 * @class DateUtilsService
 */
@Injectable({
	providedIn: 'root'
})
export class DateUtilsService {
	private language: string;
	private locale: any;
	private dateLocales = { en: enUS, es: es };

	constructor(private translocoService: TranslocoService) {
		this.translocoService.langChanges$.subscribe({
			next: (lang) => {
				this.language = lang;
				this.locale = this.dateLocales[lang];
			}
		});
	}

	/**
	 * Parse a ISO formatted string
	 * Removes the time stamp before parsing
	 *
	 * @param {string} date
	 * @return {*}  {Date}
	 * @memberof DateUtilsService
	 */
	parseISODate(date: string): Date {
		return parseISODate(date);
	}

	/**
	 * Parse a ISO formatted string, including time
	 *
	 * @param {string} date
	 * @return {*}  {Date}
	 * @memberof DateUtilsService
	 */
	parseISODateTime(date: string): Date {
		return parseISODateTime(date);
	}
	/**
	 * Format a Date.
	 * String should be a ISO formatted string.
	 *
	 * @param {(Date | string)} date
	 * @param {string} formatStr
	 * @return {*}  {string}
	 * @memberof DateUtilsService
	 */
	format(date: Date | string, formatStr: string, lang: string = null): string {
		const locale = Boolean(lang) ? this.dateLocales[lang] : this.locale;
		return formatDate(date, formatStr, locale);
	}

	/**
	 * get a list of week day names localized.
	 *
	 * @param {string} [lang=null]
	 * @param {string} [width='abbreviated'] 'narrow', 'abbreviated' or 'wide'.
	 * @return {*}  {string[]}
	 * @memberof DateUtilsService
	 */
	getWeekDays(lang: string = null, width: string = 'abbreviated'): string[] {
		const locale = Boolean(lang) ? this.dateLocales[lang] : this.locale;
		return [...Array(7).keys()].map((i) => locale.localize.day(i, { width }));
	}

	/**
	 * Get a list of Month names localized
	 *
	 * @param {string} [lang=null]
	 * @param {string} [width='abbreviated'] 'narrow', 'abbreviated' or 'wide'.
	 * @return {*}  {string[]}
	 * @memberof DateUtilsService
	 */
	getMonthNames(lang: string = null, width: string = 'abbreviated'): string[] {
		const locale = Boolean(lang) ? this.dateLocales[lang] : this.locale;
		return [...Array(12).keys()].map((i) => locale.localize.month(i, { width }));
	}

	/**
	 * Get a number of days between dates
	 *
	 * @param {(Date | string)} dateLeft
	 * @param {(Date | string)} dateRight
	 * @return {*}  {number}
	 * @memberof DateUtilsService
	 */
	getDaysBetweenDates(dateLeft: Date | string, dateRight: Date | string): number {
		return getDifferenceInDays(dateLeft, dateRight);
	}

	getCurrentLocale(): Locale {
		return this.locale;
	}
}
