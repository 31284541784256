<div class="document-submit-manual" *transloco="let t">
	<op-masthead [title]="t('DOCUMENT_SUBMIT.title')">
		<div class="op-mt-12">{{ t('DOCUMENT_SUBMIT.pleaseProvide') }}</div>
	</op-masthead>
	<op-content-container class="content">
		<div *ngIf="showSection?.count" class="bounce-section">
			<op-bounced-attention [showSection]="showSection"></op-bounced-attention>
		</div>
		<div *ngIf="showBouncedResubmitSection" class="bounce-section">
			<div class="bounced-attention">
				<h4>{{ t('DOCUMENT_SUBMIT.noAddlDocsRequired') }}</h4>
				<ul>
					<li class="attention">{{ t('DOCUMENT_SUBMIT.submitAgainToContinue') }}</li>
				</ul>
			</div>
		</div>
		<op-section-bank
			*ngIf="showBank"
			[fileType]="fileUploadType.bank"
			[expanded]="sectionCount === 1 || expandBankSection"
			[fileList]="fileInfo[fileUploadType.bank]"
			[isVerified]="isBankConnected"
			[isManual]="addBankQueryParam"
			[autoVerifyScenario]="autoVerifyScenario"
			(opened)="panelEvent('opened', $event)"
			(closed)="panelEvent('closed', $event)"
			(toggleDisbursement)="toggleAchDisbursement()"
			(checkVerificationStatus)="checkVerificationStatus($event)"
		>
		</op-section-bank>

		<op-section-income
			*ngIf="showIncome"
			[fileType]="fileUploadType.income"
			[expanded]="sectionCount === 1 || expandIncomeSection"
			[fileList]="fileInfo[fileUploadType.income]"
			[isVerified]="isIncomeVerified"
			(opened)="panelEvent('opened', $event)"
			(closed)="panelEvent('closed', $event)"
		>
		</op-section-income>
		<op-section-address
			*ngIf="showAddress"
			[fileType]="fileUploadType.addresses"
			[expanded]="sectionCount === 1 || expandAddressesSection"
			[fileList]="fileInfo[fileUploadType.addresses]"
			(opened)="panelEvent('opened', $event)"
			(closed)="panelEvent('closed', $event)"
		>
		</op-section-address>
		<op-section-identification
			*ngIf="showIdentification"
			[fileType]="fileUploadType.identification"
			[expanded]="sectionCount === 1 || expandIdentificationSection"
			[fileList]="fileInfo[fileUploadType.identification]"
			[idType]="identificationType"
			(opened)="panelEvent('opened', $event)"
			(closed)="panelEvent('closed', $event)"
		>
		</op-section-identification>

		<div *ngIf="showSPLSection">
			<div class="splInfoText">
				<div class="op-left op-body-1-bold-font">{{ t('DOCUMENT_SUBMIT.needAdditionalDocsForSPL') }}</div>
			</div>
			<op-spl-product-offer-info-card
				[skipOfferButtonText]="skipOfferButtonText"
				[showSkipSPLOfferButton]="showSkipSPLOfferButton"
				[splMaxLoanAmount]="splMaxLoanAmount"
				[showSplDocSection]="showSplDocSection"
				(continue)="toggleSPLDocVisibility($event)"
			>
			</op-spl-product-offer-info-card>
			<div *ngIf="showSplDocSection">
				<op-section-vehicle-registration
					*ngIf="showVehicleRegistration"
					[fileType]="fileUploadType.vehicleRegistration"
					[expanded]="sectionCount === 1 || expandVehicleRegistrationSection"
					[fileList]="fileInfo[fileUploadType.vehicleRegistration]"
					(opened)="panelEvent('opened', $event)"
					(closed)="panelEvent('closed', $event)"
				>
				</op-section-vehicle-registration>
				<op-section-vehicle-photos
					*ngIf="showVehiclePhotos"
					[fileType]="fileUploadType.vehiclePhotos"
					[expanded]="sectionCount === 1 || expandVehiclePhotosSection"
					[fileList]="fileInfo[fileUploadType.vehiclePhotos]"
					(opened)="panelEvent('opened', $event)"
					(closed)="panelEvent('closed', $event)"
				>
				</op-section-vehicle-photos>
				<op-section-vehicle-drivers-license
					*ngIf="showVehicleDriversLicense"
					[fileType]="fileUploadType.vehicleDriversLicense"
					[expanded]="sectionCount === 1 || expandVehicleDriversLicenseSection"
					[fileList]="fileInfo[fileUploadType.vehicleDriversLicense]"
					(opened)="panelEvent('opened', $event)"
					(closed)="panelEvent('closed', $event)"
				>
				</op-section-vehicle-drivers-license>
				<op-section-dmv-req-id-proof
					*ngIf="showDMVReqIdProof"
					[fileType]="fileUploadType.dmvRequiredId"
					[expanded]="sectionCount === 1 || expandDmvRequiredIdSection"
					[fileList]="fileInfo[fileUploadType.dmvRequiredId]"
					[dmvAcceptedIdList]="dmvAcceptedIdList"
					(opened)="panelEvent('opened', $event)"
					(closed)="panelEvent('closed', $event)"
				>
				</op-section-dmv-req-id-proof>
			</div>
		</div>

		<div>
			<!-- TAKE SELFIE PANEL -->
			<op-section-selfie-panel
				*ngIf="showPhoto$ | async"
				[fileType]="fileUploadType.selfie"
				[expanded]="sectionCount === 1 || expandSelfieSection"
				[fileList]="fileInfo[fileUploadType.selfie]"
				(opened)="panelEvent('opened', $event)"
				(closed)="panelEvent('closed', $event)"
			>
			</op-section-selfie-panel>
		</div>
		<div *ngIf="showDeleteDocumentButton" class="op-mb-20">
			<div class="disclaimer-warning op-mb-20">
				<div class="disclaimer-warning-text op-icon-align-body-2">
					<mat-icon class="op-orange" svgIcon="op-alert"></mat-icon>
					<span class="op-mt-4">{{ t('DOCUMENT_SUBMIT.removeDocumentUploads.disclaimer') }}</span>
				</div>
			</div>
			<a href="" mat-flat-button color="link-md" id="removeDocument" onclick="return false" (click)="removeDocument()">
				{{ t('DOCUMENT_SUBMIT.removeDocumentUploads.removeAllUploadsLink') }}</a
			>
		</div>

		<div class="op-mt-20">
			<button
				*ngIf="!showContinueToUPLButton"
				mat-flat-button
				color="primary"
				class="submit-button op-full-width"
				name="submitBtn"
				id="submitBtn"
				[disabled]="disableSubmit"
				(click)="onSubmit()"
				[opTagClick]="'Submit for final review'"
			>
				{{ t('DOCUMENT_SUBMIT.submitFinalReview') }}
			</button>
			<button
				*ngIf="showContinueToUPLButton"
				mat-flat-button
				color="primary"
				class="submit-button op-full-width"
				name="submitBtn"
				id="continueUplBtn"
				[disabled]="disableSubmit"
				(click)="onContinueToUPL()"
			>
				{{ t('DOCUMENT_SUBMIT.continueWithUPL') }}
			</button>
		</div>
		<div class="op-mt-20">
			<button
				*ngIf="showReturnToOffers"
				mat-flat-button
				color="secondary"
				class="submit-button op-full-width"
				name="returnToOffersButton"
				id="returnToOfferBtn"
				[disabled]="false"
				(click)="returnToOffers()"
			>
				{{ t('DOCUMENT_SUBMIT.returnToOffers') }}
			</button>
		</div>
		<div class="op-mt-20">
			<a
				href=""
				mat-flat-button
				color="link-md"
				class="ach-link"
				onclick="return false"
				id="chooseAchLink"
				*ngIf="!showChangeAchDisbursement"
				(click)="toggleAchDisbursement()"
			>
				{{ t('DOCUMENT_SUBMIT.proofOfBankAccount.chooseAchDisbursementOption') }}
			</a>
			<a
				href=""
				mat-flat-button
				color="link-md"
				class="ach-link"
				onclick="return false"
				id="chooseNonAchLink"
				*ngIf="showRejectDisbursementLink"
				(click)="confirmAndRejectDisbursement()"
			>
				{{ t('DOCUMENT_SUBMIT.proofOfBankAccount.chooseNonAchDisbursementOption') }}
			</a>
		</div>
		<div class="op-pt-40"></div>
	</op-content-container>
</div>
