import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { TranslocoService } from '@ngneat/transloco';

import { mergeMap, Subscription, throwError } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

import { LoanApplicationService } from 'src/app/core/services/loan-application/loan-application.service';

import { RoutingPathsEnum, RoutingService } from 'src/app/core/services/routing/routing.service';
import { DialogService } from 'src/app/core/services/dialog/dialog.service';
import { TagDataService } from 'src/app/core/services/tag-data/tag-data.service';
import { IConfigResult, MobileApiService } from 'src/app/core/services/mobile-api';
import { ConfigApiService } from 'src/app/core/services/mobile-api/config-api/config-api.service';
import { ILoanApplication } from 'src/app/core/services/loan-application/loan-application.model';
import { DisbursementType } from 'src/app/core/services/loan-application/disbursement/disbursement.model';
import { IApplicant } from 'src/app/core/services/loan-application/applicant/applicant.model';
import { FundingMethodConfirmComponent } from 'src/app/shared/components/dialogs/funding-method-confirm/funding-method-confirm.component';

@Component({
	selector: 'op-add-debit-card',
	templateUrl: './add-debit-card.component.html',
	styleUrls: ['./add-debit-card.component.scss']
})
export class AddDebitCardComponent implements OnInit, OnDestroy {
	private subscription: Subscription = new Subscription();

	private _tabapaySourceUrl: string | null = null;

	tabaPayLink: SafeResourceUrl = null;

	applicant: IApplicant;

	loanApp: ILoanApplication;

	showCardError: string | null = null;

	constructor(
		private sanitizer: DomSanitizer,
		private translocoService: TranslocoService,
		private tagDataService: TagDataService,
		private routingService: RoutingService,
		private configService: ConfigApiService,
		private dialogService: DialogService,
		private loanAppService: LoanApplicationService,
		private mobileApiService: MobileApiService
	) {}

	ngOnInit() {
		const loanAppSub = this.loanAppService.loanApplication$.pipe(filter(Boolean), take(1)).subscribe({
			next: (loanApp) => {
				this.loanApp = loanApp;
				this.applicant = this.loanAppService.getCurrentApplicant();
			}
		});
		this.subscription.add(loanAppSub);

		const configSub = this.configService.configTabaPaySourceURL().subscribe({
			next: (configResult: IConfigResult) => {
				this._tabapaySourceUrl = String(configResult?.value);
				this.setTabapayLink();
			}
		});
		this.subscription.add(configSub);

		const langSub = this.translocoService.langChanges$.subscribe({
			next: (lang) => {
				this.setTabapayLink(lang);
			}
		});
		this.subscription.add(langSub);
	}

	@HostListener('window:message', ['$event'])
	processMessage(message: MessageEvent) {
		if (!message.data || typeof message.data !== 'string') {
			//this.routingService.route(RoutingPathsEnum.fundingOptions, { queryParams: { error: 'debitCard' } });
		} else if (message?.data && message.data !== 'Close') {
			if (message.data.slice(0, 7) === 'Error: ') {
				this.routingService.route(RoutingPathsEnum.fundingOptions, { queryParams: { error: 'debitCard' } });
			} else {
				this.tagDataService.link(
					{},
					{
						tealium_event: `debit card form event`,
						event_target: `${this._tabapaySourceUrl}`
					}
				);
				const messageData = message.data.split('|');
				if (messageData.length === 3) {
					this.mobileApiService
						.checkDebitCardStatus(this.loanApp.id, messageData[2])
						.pipe(
							mergeMap((resp) => {
								if (resp.cardEnabled) {
									return this.mobileApiService
										.updateDisbursementChannel(
											{
												disbursementChannel: 'ONLINE',
												disbursementType: 'PUSH_TO_DEBIT'
											},
											this.loanAppService.loanApplicationId
										)
										.pipe(
											map((result) => {
												return resp;
											})
										);
								} else {
									return throwError(() => resp);
								}
							}),
							mergeMap((resp) => {
								return this.dialogService.openSimpleDialog(FundingMethodConfirmComponent, {
									data: {
										fundingOptions: DisbursementType.debit,
										card: {
											last4: resp.cardLast4,
											fundsAvailability: resp.fundsAvailability
										}
									}
								});
							})
						)
						.subscribe({
							next: (result) => {
								this.routingService.route(RoutingPathsEnum.documentSubmit);
							},
							error: (error) => {
								if (error.cardEnabled === false) {
									if (error.cardType == null || error.cardType === 'Debit') {
										this.routingService.route(RoutingPathsEnum.fundingOptions, { queryParams: { error: 'debitCard' } });
									} else {
										this.setTabapayLink();
										this.showCardError = error.cardType;
									}
								} else {
									this.routingService.route(RoutingPathsEnum.fundingOptions, { queryParams: { error: 'debitCard' } });
								}
							}
						});
				} else {
					this.routingService.route(RoutingPathsEnum.fundingOptions, { queryParams: { error: 'debitCard' } });
				}
			}
		}
	}

	get cardOwner() {
		return `${this.applicant.firstName} ${this.applicant.lastName}`;
	}

	setTabapayLink(lang: string = null) {
		const language = lang || this.translocoService.getActiveLang();
		if (this._tabapaySourceUrl) {
			this.tabaPayLink = this.sanitizer.bypassSecurityTrustResourceUrl(`${this._tabapaySourceUrl}?lang=${language}`);
			this.tagDataService.link(
				{},
				{
					tealium_event: `load debit card form`,
					event_target: `${this._tabapaySourceUrl}`
				}
			);
		}
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}
}
