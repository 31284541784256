<div class="proof-of-identification" *transloco="let t">
	<op-back-link class="op-mt-20" [green]="false" id="{{ getPageId() }}Back" (back)="onBack()">{{
		t('ACCESSIBILITY.back')
	}}</op-back-link>
	<op-masthead [title]="t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.proofOfIdentification.identificationTitle')"> </op-masthead>
	<op-content-container class="content" *ngIf="documentClassificationList$ | async as classificationList">
		<div *ngIf="proofState === proofStatusEnum.classificationSelection">
			<op-document-classification-original
				id="identificationClassification"
				[documentClassificationList]="classificationList"
				(documentClassification)="saveDocumentClassification($event)"
				(cancel)="onCancel($event)"
			>
				<hr />
				<div
					class="op-mb-20 op-dark-grey"
					[innerHTML]="t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.proofOfIdentification.privacyPolicy')"
				></div>
			</op-document-classification-original>
		</div>

		<div *ngIf="proofState === proofStatusEnum.upload">
			<div>
				<div
					class="op-header-2-bold-font op-mb-8"
					*ngIf="stateStorage?.selectedDocumentClassification?.key !== 'PASSPORT'"
				>
					{{ stateStorage?.selectedDocumentClassification?.text }}
				</div>
				<div
					class="op-header-2-bold-font op-mb-8"
					*ngIf="stateStorage?.selectedDocumentClassification?.key === 'PASSPORT'"
				>
					{{ t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.proofOfIdentification.validPassport') }}
				</div>
				<div class="op-dark-grey">
					<ul>
						<li
							*ngFor="
								let value of t(
									'DOCUMENT_SUBMIT.AUTOMATIC_PROOF.proofOfIdentification.classificationInstructions.' +
										stateStorage?.selectedDocumentClassification?.key
								)
							"
							[innerHTML]="value"
						></li>
					</ul>
				</div>
			</div>

			<div class="op-mb-10" *ngIf="stateStorage?.selectedDocumentClassification?.key | opIssuingCountry">
				<form class="personal-content" [formGroup]="formGroup">
					<op-info-tip id="issuingCountryTip" [key]="t('IDENTIFICATION.issuingCountryTip')">
						{{ t('IDENTIFICATION.issuingCountry') | opRequired }}
					</op-info-tip>
					<p></p>
					<mat-form-field class="form-element" appearance="outline">
						<mat-select
							formControlName="issuingCountry"
							id="issuingCountry"
							name="issuingCountry"
							aria-label="issuingCountry"
						>
							<mat-option
								*ngFor="let country of countryList$ | async; let i = index"
								id="{{ 'issuingCountry_' + country.key }}"
								[value]="country.key"
								>{{ country.text }}</mat-option
							>
						</mat-select>
					</mat-form-field>
				</form>
			</div>

			<div *ngIf="classificationFiles?.length" class="op-body-1-bold-font op-mtb-20">
				{{ t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.uploadedDocuments') }}
			</div>

			<div *ngIf="attention" class="op-mb-20">
				<op-document-notification-original id="identificationNeedsAttention" class="op-mb-10">
					<div class="op-mb-10">
						<ul id="{{ 'messages' }}" class="messages">
							<div *ngFor="let file of reviewFiles">
								<li *ngFor="let msg of file?.ocrInfo?.messages; let isLast = last">
									{{ msg }}
								</li>
							</div>
						</ul>
					</div>
				</op-document-notification-original>
			</div>

			<op-document-notification-original
				*ngIf="bounceReasons?.length"
				id="identificationBounceReasons"
				class="op-mtb-20"
			>
				<ul>
					<li *ngFor="let bounce of bounceReasons">{{ bounce }}</li>
				</ul>
			</op-document-notification-original>

			<op-document-upload-original
				id="identificationUploadFront"
				[categoryType]="categoryType"
				[documentClassification]="stateStorage?.selectedDocumentClassification"
				[selectedClassificationFiles]="selectedClassificationFiles"
				[showSingleFile]="showFrontBack ? true : false"
				[fileSide]="'front'"
				[delayUpload]="
					(stateStorage?.selectedDocumentClassification?.key | opIssuingCountry) || showFrontBack ? true : false
				"
				[country]="formGroup?.value?.issuingCountry"
				#docUpload
			>
				<div *ngIf="showFrontBack" class="op-dark-grey op-mb-5">
					{{ t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.proofOfIdentification.front') }}
				</div>
				<div uploadDocument>
					<div class="op-icon-align-body-1 upload-document-wrapper">
						<div></div>
						<mat-icon class="op-dark-green" svgIcon="op-cloudUpload"></mat-icon>
						<div class="op-body-1-bold-font op-dark-green upload-document">
							{{ t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.uploadDocuments') }}
						</div>
					</div>
					<div class="op-dark-grey op-mt-8" [innerHTML]="t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.fileInstructions')"></div>
				</div>
				<div singleUploadDocument>
					<div class="op-icon-align-body-1 upload-document-wrapper">
						<div></div>
						<mat-icon class="op-dark-green" svgIcon="op-cloudUpload"></mat-icon>
						<div class="op-body-1-bold-font op-dark-green upload-document">
							{{ t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.uploadDocuments') }}
						</div>
					</div>
					<div class="op-dark-grey op-mt-8" [innerHTML]="t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.fileInstructions')"></div>
				</div>
			</op-document-upload-original>

			<hr *ngIf="showFrontBack" />

			<div class="op-mt-20" *ngIf="showFrontBack">
				<op-document-upload-original
					id="identificationUpload"
					[categoryType]="categoryType"
					[documentClassification]="stateStorage?.selectedDocumentClassification"
					[selectedClassificationFiles]="selectedClassificationFiles"
					[showSingleFile]="showFrontBack ? true : false"
					[fileSide]="'back'"
					[delayUpload]="true"
					[country]="formGroup?.value?.issuingCountry"
				>
					<div class="op-dark-grey op-mb-5">{{ t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.proofOfIdentification.back') }}</div>
					<div uploadDocument>
						<div class="op-icon-align-body-1 upload-document-wrapper">
							<div></div>
							<mat-icon class="op-dark-green" svgIcon="op-cloudUpload"></mat-icon>
							<div class="op-body-1-bold-font op-dark-green upload-document">
								{{ t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.uploadDocuments') }}
							</div>
						</div>
						<div class="op-dark-grey op-mt-8" [innerHTML]="t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.fileInstructions')"></div>
					</div>
					<div singleUploadDocument>
						<div class="op-icon-align-body-1 upload-document-wrapper">
							<div></div>
							<mat-icon class="op-dark-green" svgIcon="op-cloudUpload"></mat-icon>
							<div class="op-body-1-bold-font op-dark-green upload-document">
								{{ t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.uploadDocuments') }}
							</div>
						</div>
						<div class="op-dark-grey op-mt-8" [innerHTML]="t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.fileInstructions')"></div>
					</div>
				</op-document-upload-original>
			</div>

			<div class="op-mt-30">
				<button
					mat-flat-button
					color="primary"
					name="identificationContinueBtn"
					id="identificationContinueBtn"
					[disabled]="submitDisabled"
					type="submit"
					(click)="onContinue()"
					[opTagClick]="'continue identification'"
				>
					{{ t('GLOBAL.continue') }}
				</button>
			</div>

			<div class="op-mt-20" *ngIf="canCancel">
				<button
					mat-flat-button
					color="secondary"
					name="identificationCancelBtn"
					id="identificationCancelBtn"
					(click)="onCancel()"
					[opTagClick]="'cancel identification'"
				>
					{{ t('GLOBAL.cancel') }}
				</button>
			</div>
		</div>

		<div *ngIf="proofState === proofStatusEnum.status">
			<op-document-status-original
				id="identificationStatus"
				[categoryType]="categoryType"
				[categoryFiles]="finishedFiles"
				(done)="onDocStatus($event)"
				[documentClassificationList]="classificationList"
			>
				<div [innerHTML]="t('DOCUMENT_SUBMIT.AUTOMATIC_PROOF.additionDocInfo')"></div>

				<op-document-notification-original
					*ngIf="bounceReasons?.length"
					id="identificationBounceReasons"
					class="op-mtb-20"
				>
					<ul>
						<li *ngFor="let bounce of bounceReasons">{{ bounce }}</li>
					</ul>
				</op-document-notification-original>
			</op-document-status-original>

			<div class="op-mt-30">
				<button
					mat-flat-button
					color="primary"
					name="doneBtn"
					id="identificationStatusBtn"
					(click)="onCancel()"
					[opTagClick]="getPageId() + 'Continue'"
				>
					{{ t('GLOBAL.continue') }}
				</button>
			</div>
		</div>
	</op-content-container>
</div>
